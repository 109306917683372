/**
 * Data object representing the customers list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class CustomersListItemDataObject {
	/**
	 * @param {string} id - Customer's DB ID.
	 * @param {string} crmId - Customer's external CRM ID.
	 * @param {string} firstName - Customer's first name.
	 * @param {string} lastName - Customer's last name.
	 * @param {string} phone - Customer's mobile phone number.
	 * @param {string} email - Customer's email address.
	 * @param {Date} dateOfBirth - Customer's date of birth.
	 */
	constructor(id, crmId, firstName, lastName, phone, email, dateOfBirth) {
		this.id = id;
		this.crmId = crmId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.phone = phone;
		this.email = email;
		this.dateOfBirth = dateOfBirth;
	}
}

/**
 * Data object representing the customers item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class CustomersItemDataObject {
	/**
	 * @param {?string} [id=null] - Customer's DB ID.
	 * @param {?string} [clientId=null] - Client DB ID.
	 * @param {string} [crmId=''] - Customer's external CRM ID.
	 * @param {string} [firstName=''] - Customer's first name.
	 * @param {string} [lastName=''] - Customer's last name.
	 * @param {string} [phone=''] - Customer's mobile phone number.
	 * @param {string} [email=''] - Customer's email address.
	 * @param {Date} [dateOfBirth=null] - Customer's date of birth.
	 * @param {CustomerCustomFieldDataObject[]} [customFields=[]] - Customer custom fields.
	 * @param {string[]} [projectIds=[]] - List of project IDs this customer is or will be assigned to.
	 */
	constructor(
		id = null, clientId = null, crmId = '', firstName = '', lastName = '', phone = '', email = '', dateOfBirth = null,
		customFields = [], projectIds = [],
	) {
		this.id = id;
		this.crmId = crmId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.phone = phone;
		this.email = email;
		this.dateOfBirth = dateOfBirth;
		this.customFields = customFields;
		this.projectIds = projectIds;
	}
}

export class CustomerCustomFieldDataObject {
	/**
	 * @param {?string} [id=null] - Customer custom field DB ID.
	 * @param {string} [name=''] - Customer custom field name.
	 * @param {string} [value=''] - Customer custom field value.
	 * @param {?boolean} [identifier=null] - Customer custom field identifier flag. // TODO: what is this?
	 */
	constructor(id = null, name = '', value = '', identifier = null) {
		this.value = value;
		
		// Read-only properties
		this.id = id;
		this.name = name;
		this.identifier = identifier;
	}
}