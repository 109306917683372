import {cloneDeep, find, forOwn} from 'lodash';
import {getArray, getBoolean, getNumber, getString, isset} from 'Core/helpers/data';
import {
	FormDataObject,
	FormHtmlTextDataObject,
	FormPageDataObject, 
	FormQuestionAnswerDataObject,
	FormQuestionDataObject
} from '../dataObjects';
import {FORM_ELEMENT_TYPE, FORM_QUESTION_INPUT_TYPE} from 'Pages/site/form/const';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {FormDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new FormDataObject(
		getString(result, 'id'),
		getString(result, 'title'),
		getString(result, 'description'),
		getArray(result, 'pages').map(page => new FormPageDataObject(
			getString(page, 'id'),
			getNumber(page, 'ordinal'),
			getArray(page, 'elements').map(element =>
				getString(element, 'type') === FORM_ELEMENT_TYPE.QUESTION ?
					new FormQuestionDataObject(
						getString(element, 'id'),
						getString(element, 'type'),
						getNumber(element, 'ordinal'),
						getString(element, 'title'),
						getString(element, 'description'),
						getString(element, 'inputType'),
						getBoolean(element, 'mandatory'),
						getArray(element, 'answers').map(answer => new FormQuestionAnswerDataObject(
							getString(answer, 'id'),
							getNumber(answer, 'ordinal'),
							getString(answer, 'content'),
							getString(answer, 'value'),
						))
					) 
				: getString(element, 'type') === FORM_ELEMENT_TYPE.HTML_TEXT ?
					new FormHtmlTextDataObject(
						getString(element, 'id'),
						getString(element, 'type'),
						getNumber(element, 'ordinal'),
						getBoolean(element, 'html'),
						getString(element, 'content'),
						getString(element, 'elementId'),
						getString(element, 'clientId'),
					)
				: null
			)
		))
	);

	return result;
}

/**
 * Get output data from input data
 * @param {Object<string, any>} inputData - Input data where key is a DB ID of question and value is the user answer.
 * @param {FormQuestionDataObject[]} questions - Input form questions specification.
 * @return {Object}
 */
export const output = (inputData, questions) => {
	let result = [];
	forOwn(inputData, (answer, questionId) => {
		/** @type {FormQuestionDataObject|undefined} */
		const question = find(questions, {id: questionId})
		
		if (isset(question)) {
			result.push({
				questionId,
				answers: (Array.isArray(answer) ? answer : [answer])
					.map(a => ({
						id: null,
						answerId: (
							// Set 'answerId' for radio, checkbox and select input types since they use label/value objects as 
							// values where value is the DB ID of the selected answer
							[
								FORM_QUESTION_INPUT_TYPE.RADIOBUTTON,
								FORM_QUESTION_INPUT_TYPE.CHECKBOX,
								FORM_QUESTION_INPUT_TYPE.SELECT,
							].includes(question.inputType) ? a : null
						),
						answerText: (
							// Set 'answerText' for normal input types that use a plain string values
							![
								FORM_QUESTION_INPUT_TYPE.RADIOBUTTON,
								FORM_QUESTION_INPUT_TYPE.CHECKBOX,
								FORM_QUESTION_INPUT_TYPE.SELECT,
							].includes(question.inputType) ? a : null
						),
					})),
			})
		}
	});
	
	return result;
}