import styles from "./index.module.css";
import "./default.style.css";
import "./compact.style.css";
import "./key.value.list.css";

import React from "react";
import DataComponent, {executeComponentCallback, executeComponentCallbackPromise} from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE, SIMPLE_STATIC_SEARCH_DISPLAY_TYPES,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SIMPLE_STATIC_SEARCH_LAYOUTS
} from "./const";
import {translate} from "Core/i18n";
import {getArray, getBoolean, getObject, getString, isset} from "Core/helpers/data";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import DateInput from "Core/components/input/DateInput";
import ToggleInput from "Core/components/input/ToggleInput";
import SelectInput from "Core/components/input/SelectInput";
import DateRangeInput from "Core/components/input/DateRangeInput";
import NumberInput from "Core/components/input/NumberInput";
import TimeInput from "Core/components/input/TimeInput";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import DatetimeInput from "Core/components/input/DatetimeInput";
import {Tooltip} from "Core/components/global/Tooltip";
import Label from "Core/components/display/Label";
import SelectAsyncInput from "Core/components/input/SelectAsyncInput";
import {cloneDeep, find, forOwn, get, isEqual, omit, set} from "lodash";
import {DynamicPropFunction} from "./helper";

/**
 * Simple static search component
 * @description This component will render search options received through props, and it will trigger an onChange event
 * every type some option value changes. It does not store options and values internally so parent component should 
 * handle them.
 * 
 * "simple" means that it works based on field/value pairs unaware of comparison operators and underlying search logic.
 * "static" means that it has a fixed number options defined through 'options' prop.
 */
class SimpleStaticSearch extends DataComponent {
	constructor(props) {
		super(props, {
			data: {},
			collapsed: getBoolean(props, 'defaultCollapse'),
		}, {
			translationPath: 'SimpleStaticSearchComponent',
			domPrefix: 'simple-static-search-component',
			dataPropAlias: 'value',
			enableLoadOnDataPropChange: true
		});

		// Data methods
		this.handleFieldChange = this.handleFieldChange.bind(this);
		
		// Action methods
		this.clearFields = this.clearFields.bind(this);
		this.clearField = this.clearField.bind(this);
		this.reset = this.reset.bind(this);
		this.remove = this.remove.bind(this);

		// Render methods
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.toggleContent = this.toggleContent.bind(this);
		this.getSearchFieldComponentProp = this.getSearchFieldComponentProp.bind(this);
		this.renderCustomComponent = this.renderCustomComponent.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {Object} Local component's state compatible data or null if data could not be loaded.
	 */
	getDataToLoad(rawData) {
		const dataToLoad = super.getDataToLoad(rawData);
		// Make sure that local data is an object and not null because if it is null, 'setValue' method won't work
		return (dataToLoad ? dataToLoad : {});
	}

	
	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Handle change event of any field
	 * @note Change event can be triggered even if the value did not actually change.
	 * 
	 * @param {string} fieldName - Field name that triggered the change event.
	 * @param {any} value - New value of the field.
	 * @param {any} prevValue - Old value of the field.
	 */
	handleFieldChange(fieldName, value, prevValue) {
		// Get the list of fields that need to be cleared if this field changes
		let fieldsToClear = [];
		getArray(this.props, 'options').forEach(o => {
			const clearOnChange = (
				Array.isArray(get(o, 'clearOnChange')) ?
					o.clearOnChange :
					!!get(o, 'clearOnChange') ? [get(o, 'clearOnChange')] : []
			);
			
			if (clearOnChange.includes(fieldName) && value !== prevValue) fieldsToClear.push(o.field);
		});
		
		// Trigger 'onFieldChange' event
		// @note Use 'value' and 'prevValue' to determine if field value actually changed.
		executeComponentCallback(this.props.onFieldChange, fieldName, value, prevValue, this);

		// Clear all fields that have this field in 'clearOnChange' property
		fieldsToClear.forEach(field => this.clearField(field));
	}
	
	/**
	 * Handle input component changes
	 *
	 * @param {Event} event - DOM element's event object. Component's main data item or main data item field name (if
	 * component's main data item is an object) and new value will be extracted from the event object. By convention DOM
	 * element should have a 'name' attribute that corresponds to a single component's main data item field if
	 * component's main data item is an object. If 'name' attribute is not specified component's main data item will be
	 * updated with the new value.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleInputChange(event) {
		// Persist event in order for it to work asynchronously (in promise then for example)
		event.persist();

		const target = event.target;
		// IMPORTANT: By convention DOM element should have a 'name' attribute that corresponds to a single component's
		// main data field.
		const fieldName = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const prevValue = this.getValue(fieldName);
		
		// Update component's main data field if field name is specified
		if(fieldName) {
			return this.setValue(fieldName, value)
				.then(() => this.handleFieldChange(fieldName, value, prevValue))
				.then(() => executeComponentCallback(this.props.onInput, this.getDataToReturn()))
				.then(() => this.state);
		}
		// Update component's main data if field name is not specified
		else {
			return this.setData(value)
				.then(() => executeComponentCallback(this.props.onInput, this.getDataToReturn()))
				.then(() => this.state);
		}
	}

	/**
	 * Handle item child field changes
	 *
	 * @param {string} path - Path to the component's main data field that will be updated.
	 * @param {any} value - Value that will be used to update component's main data field.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleValueChange(path, value) {
		// Update component's main data field if field path is specified
		if(path) {
			const prevValue = this.getValue(path);
			return this.setValue(path, value)
				.then(() => this.handleFieldChange(path, value, prevValue))
				.then(() => executeComponentCallback(this.props.onInput, this.getDataToReturn()))
				.then(() => this.state);
		} else {
			return Promise.resolve(this.state)
				.then(() => executeComponentCallback(this.props.onInput, this.getDataToReturn()))
				.then(() => this.state);
		}
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Clear search fields
	 * @param {boolean} [useClearValue=false] - Flag that specifies if clear value of the search options will be used.
	 * @return {Promise<Object>}
	 */
	clearFields(useClearValue = false) {
		const {options} = this.props;
		const currentData = this.getData();
		let initialData = getObject(this.getInitialData());
		
		// Set clear values as initial for fields that have clear values
		if (useClearValue) {
			getArray(options).forEach(o => {
				if (o.hasOwnProperty('clearValue')) set(initialData, o.field, o.clearValue);
			});
		}

		// Clear data if it is not already cleared
		if (!isEqual(currentData, initialData)) {
			let fieldsChanged = [];
			getArray(options).forEach(o => {
				const currentValue = this.getValue(o.field);
				fieldsChanged.push({
					field: o.field, 
					value: get(initialData, 'field', o.clearValue), 
					prevValue: currentValue
				});
			});
			
			return this.setState({data: cloneDeep(initialData)})
				.then(() => fieldsChanged.forEach(f => this.handleFieldChange(f.field, f.value, f.prevValue)))
				.then(() => executeComponentCallback(this.props.onInput, this.getDataToReturn()))
				.then(() => this.state);
		}
		// Do not clear data if it is already cleared
		return Promise.resolve(this.state);
	}

	/**
	 * Clear a specific search field
	 * @param {string} field - Field name to clear.
	 * @param {any} [clearValue] - Clear value. If not defined field's clear value will be used if specified.
	 * @return {Promise<Object>}
	 */
	clearField(field, clearValue) {
		const options = getArray(this.props, 'options');
		const currentValue = this.getValue(field);
		const clearValueToUse = (isset(clearValue) ? clearValue : get(find(options, {field}), 'clearValue', clearValue));
		
		return this.setValue(field, clearValueToUse)
			.then(() => this.handleFieldChange(field, clearValueToUse, currentValue))
			.then(() => executeComponentCallback(this.props.onInput, this.getDataToReturn()))
			.then(() => this.state);
	}
	
	/**
	 * Reset filter by clearing fields, using clear values if specified, and calling the search actions
	 * @return {Promise<Object>}
	 */
	reset() {
		const {useClearValuesForReset} = this.props;
		
		this.clearFields(useClearValuesForReset).then(() => this.update()); 
	}

	/**
	 * Remove filter by clearing fields, using clear values if specified, and triggering 'onRemove' event (from props)
	 * @description This method is used to clearing the search without calling the search action afterward. Since data 
	 * is not known, actual functionality should be handled by the parent component.
	 * @return {Promise<Object>}
	 */
	remove() {
		const {useClearValuesForRemove} = this.props;
		
		this.clearFields(useClearValuesForRemove).then(() => executeComponentCallbackPromise(this.props.onRemove)); 
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Show content section
	 * @return {Promise<Object>}
	 */
	open() { return this.setState({collapsed: false}); }

	/**
	 * Hide content section
	 * @return {Promise<Object>}
	 */
	close() { return this.setState({collapsed: true}); }
	
	/**
	 * Toggle (show/hide) content section
	 * @return {Promise<Object>}
	 */
	toggleContent() {
		const {collapsable} = this.props;
		if (collapsable) {
			return this.setState(prevState => ({...prevState, collapsed: !prevState.collapsed}))
				// Trigger 'onToggle' event
				.then(() => executeComponentCallbackPromise(this.props.onToggle, this.state.collapsed))
				.then(() => this.state);
		} else {
			return Promise.resolve(this.state);
		}
	}

	/**
	 * Get search field component prop with support for dynamic prop function
	 * @note Dynamic prop function is used to get prop values that depend on currently selected search values.
	 * 
	 * @param {Object} rawProps - Raw search field component prop where prop value can be an instance of dynamic prop 
	 * function (DynamicPropFunction).
	 * @return {Object}
	 */
	getSearchFieldComponentProp(rawProps) {
		let result = {};
		const currentSearchData = this.getData();

		forOwn(rawProps, (value, key) => {
			if (value instanceof DynamicPropFunction) {
				try { set(result, key, value.func(currentSearchData)); }
				catch (e) { omit(result, [key]); }
			} else {
				set(result, key, value);
			}
		});
		
		return result;
	}

	/**
	 * Render custom option component
	 * @param {SimpleStaticSearchOptionObject} option
	 * @return {JSX.Element}
	 */
	renderCustomComponent(option) {
		const {submitOnEnter} = this.props;
		const CustomComponent = option.customComponent;
		
		return (
			<CustomComponent
				// @note Both value and data is set because some components use 'value' and some use 'data' prop.
				value={this.getValue(option.field, option.clearValue)}
				data={this.getValue(option.field, option.clearValue)}
				onChange={(v, event) => {
					return this.handleValueChange(option.field, v)
						.then(res => {
							executeComponentCallback(this.props.onInput, this.getDataToReturn());
							if (!!submitOnEnter && event && event.type === 'keypress' && event.key === 'Enter') this.update();
							return res;
						});
				}}
				onEnterKey={e => { 
					if (!!submitOnEnter) this.update();
					executeComponentCallback(option.displayOptions.onEnterKey, e);
				}}
				{...this.getSearchFieldComponentProp(omit(option.displayOptions, 'onEnterKey'))}
			/>
		);
	}
	
	render() {
		const {
			styleName, className, showTitle, title, layout, options, buttonProps, applied, appliedClassName, enableToolbar, 
			toolbarTitle, enableClearButton, clearButtonProps, enableResetButton, resetButtonProps, enableRemoveButton, 
			removeButtonProps, collapsable, titleToolbar, enableSearchButton, submitOnEnter, showAdditionalToolbar,
			additionalToolbarContent, additionalToolbarClassName,
		} = this.props;
		const {collapsed} = this.state;
		
		const labelLayout = (
			layout === SIMPLE_STATIC_SEARCH_LAYOUT.STACKED ? 
				FORM_FIELD_LABEL_POSITION.ALIGNED :
				(layout === SIMPLE_STATIC_SEARCH_LAYOUT.ALIGNED ? FORM_FIELD_LABEL_POSITION.STACKED : layout)
		);
		
		const hideActions = (
			!enableSearchButton &&
			!(!enableToolbar && enableClearButton) &&
			!(!enableToolbar && enableResetButton && applied) &&
			!(!enableToolbar && this.props.onRemove && enableRemoveButton && applied)
		);
		
		return (
			<div 
				id={this.getDomId()} 
				className={
					`${this.getOption('domPrefix')} ${styles['wrapper']} ${styleName}-style ${className} ` +
					`${collapsed ? `collapsed ${styles['collapsed']}` : `expanded ${styles['expanded']}`} ` +
					`${collapsable ? `collapsable ${styles['collapsable']}` : ''} ` +
					`${applied ? `applied ${appliedClassName}` : ''}`
				}
			>
				{!showTitle ? null :
					<div 
						className={`title ${styles['title']} ${!!titleToolbar ? `with-toolbar` : ''}`} 
						onClick={this.toggleContent}
					>
						{!!titleToolbar ? <div className="title-content">{title}</div> : title}
						{
							title && applied ?
								<Label
									icon="filter"
									iconClassName={`sup ${styles['applied']}`}
									tooltip={this.t('applied')}
								/>
								: null
						}
						{!!titleToolbar ? <div className="title-toolbar">{titleToolbar}</div> : null}
					</div>	
				}

				<div className={`content-wrapper ${styles['contentWrapper']}`}>
					<div className={`options ${styles['options']} ${styles[layout]}`}>
						<FormWrapper className={`${styles['form']} ${hideActions ? 'hide-actions' : ''}`}>
							{
								enableToolbar ?
									<div className={`toolbar right standard`}>
										{
											toolbarTitle ? 
												(typeof toolbarTitle === 'string' ? 
													<span className="toolbar-title">{toolbarTitle}</span> :
													toolbarTitle
												) 
												: null
										}
										{
											enableClearButton ?
												<Tooltip
													tag="span"
													title={this.t('clear_btn')}
													size="small"
													position="top-center"
													arrow={true}
													interactive={false}
												>
													<Button
														icon="eraser"
														iconProps={{symbolPrefix: 'icofont-'}}
														displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
														displayStyle={BUTTON_STYLE.SUBTLE}
														{...clearButtonProps}
														onClick={() => this.clearFields(true)}
													/>
												</Tooltip>
												: null
										}
										
										{
											enableResetButton && applied ?
												<Tooltip
													tag="span"
													title={this.t('reset_btn')}
													size="small"
													position="top-center"
													arrow={true}
													interactive={false}
												>
													<Button
														icon="refresh"
														iconProps={{symbolPrefix: 'icofont-'}}
														displayType={BUTTON_DISPLAY_TYPE.NONE}
														displayStyle={BUTTON_STYLE.SUBTLE}
														{...resetButtonProps}
														onClick={this.reset}
													/>
												</Tooltip>
												: null
										}

										{
											this.props.onRemove && enableRemoveButton && applied ?
												<Tooltip
													tag="span"
													title={this.t('remove_btn')}
													size="small"
													position="top-center"
													arrow={true}
													interactive={false}
												>
													<Button
														icon="close"
														iconProps={{symbolPrefix: 'icofont-'}}
														displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
														displayStyle={BUTTON_STYLE.SUBTLE}
														{...removeButtonProps}
														onClick={this.remove}
													/>
												</Tooltip>
												: null
										}
									</div>
									: null
							}
							
							<div className="options-form-inner">
								{options.map((option, index) =>
									<FormField 
										key={index}
										label={option.fieldLabel}
										labelPosition={labelLayout}
										className={
											styles['field'] + ' ' +
											getString(this.getSearchFieldComponentProp(option.formFieldOptions), 'className')
										}
										labelClassName={
											styles['fieldLabel'] + ' ' +
											getString(this.getSearchFieldComponentProp(option.formFieldOptions), 'labelClassName')
										}
										inputClassName={
											styles['fieldInput'] + ' ' +
											getString(this.getSearchFieldComponentProp(option.formFieldOptions), 'inputClassName')
										}
										{...omit(
											this.getSearchFieldComponentProp(option.formFieldOptions), 
											['className', 'labelClassName', 'inputClassName']
										)}
									>
										{
											option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.TEXT ?
												<TextInput
													name={option.field}
													value={this.getValue(option.field)}
													onChange={this.handleInputChange}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.NUMBER || 
											  option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.FLOAT ?
												<NumberInput
													name={option.field}
													value={this.getValue(option.field)}
													onChange={v => this.handleValueChange(option.field, v)}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													intOnly={false}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.INT ?
												<NumberInput
													name={option.field}
													value={this.getValue(option.field)}
													onChange={v => this.handleValueChange(option.field, v)}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													intOnly={true}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE ?
												<DateInput
													value={this.getValue(option.field)}
													onChange={v => this.handleValueChange(option.field, v)}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE_RANGE ?
												<DateRangeInput
													value={this.getValue(option.field)}
													onChange={v => this.handleValueChange(option.field, v)}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.TIME ?
												<TimeInput
													name={option.field}
													value={this.getValue(option.field)}
													onChange={this.handleInputChange}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATETIME ?
												<DatetimeInput
													value={this.getValue(option.field)}
													onChange={v => this.handleValueChange(option.field, v)}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.BOOL ?
												<ToggleInput
													name={option.field}
													checked={this.getValue(option.field, false)}
													onChange={this.handleInputChange}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT ?
												<SelectInput
													simpleValue={true}
													value={this.getValue(option.field, null)}
													onChange={v => this.handleValueChange(option.field, v)}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT_ASYNC ?
												<SelectAsyncInput
													value={this.getValue(option.field, null)}
													onChange={v => this.handleValueChange(option.field, v)}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
											: option.displayType === SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM ?
												this.renderCustomComponent(option)
											:
												<TextInput
													name={option.field}
													value={this.getValue(option.field, '')}
													onChange={this.handleInputChange}
													onEnterKey={() => { if (!!submitOnEnter) this.update(); }}
													{...this.getSearchFieldComponentProp(option.displayOptions)}
												/>
										}
									</FormField>
								)}
							</div>

							{showAdditionalToolbar ?
								<div className={`additionalToolbarWrapper ${getString(additionalToolbarClassName)}`}>
									{typeof additionalToolbarContent === 'function' ? additionalToolbarContent(this) : null}
								</div>
								: null
							}
						</FormWrapper>

						{hideActions ? null :
							<div className={`actions`}>
								{enableSearchButton ?
									<Button
										icon="search"
										className={`search-button`}
										displayStyle={BUTTON_STYLE.ACTION}
										displayType={BUTTON_DISPLAY_TYPE.SOLID}
										{...buttonProps}
										onClick={() => this.update()}
									/>
									: null
								}

								{
									!enableToolbar && enableClearButton ?
										<Button
											tooltip={this.t('clear_btn')}
											icon="eraser"
											iconProps={{symbolPrefix: 'icofont-'}}
											displayType={BUTTON_DISPLAY_TYPE.NONE}
											displayStyle={BUTTON_STYLE.SUBTLE}
											{...clearButtonProps}
											onClick={() => this.clearFields(true)}
										/>
										: null
								}

								{
									!enableToolbar && enableResetButton && applied ?
										<Button
											tooltip={this.t('reset_btn')}
											icon="refresh"
											iconProps={{symbolPrefix: 'icofont-'}}
											displayType={BUTTON_DISPLAY_TYPE.NONE}
											displayStyle={BUTTON_STYLE.SUBTLE}
											{...resetButtonProps}
											onClick={this.reset}
										/>
										: null
								}

								{
									!enableToolbar && this.props.onRemove && enableRemoveButton && applied ?
										<Button
											tooltip={this.t('remove_btn')}
											icon="close"
											iconProps={{symbolPrefix: 'icofont-'}}
											displayType={BUTTON_DISPLAY_TYPE.NONE}
											displayStyle={BUTTON_STYLE.SUBTLE}
											{...removeButtonProps}
											onClick={this.remove}
										/>
										: null
								}
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
SimpleStaticSearch.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Component's wrapper element id attribute
	id: PropTypes.string,
	// Component's wrapper element class attribute
	className: PropTypes.string,
	// Component's wrapper element class added if filer is applied
	appliedClassName: PropTypes.string,
	// Search button props
	// @see Button component props.
	buttonProps: PropTypes.object,

	// Flag that specifies if component's title will be rendered
	showTitle: PropTypes.bool,
	// Component's title
	// @note If undefined, title won't be rendered.
	title: PropTypes.any,
	// Toolbar rendered inside the title
	titleToolbar: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that determines if component's content can be collapsed
	collapsable: PropTypes.bool,
	// Flag that determines if component's content will be collapsed by default (when component mounts) or not
	// @note This component will handle collapse state internally.
	defaultCollapse: PropTypes.bool,
	// Layout of the search options
	layout: PropTypes.oneOf(SIMPLE_STATIC_SEARCH_LAYOUTS),
	// Search options
	// @note This component won't use local state to store search options. Parent component should handle them and pass 
	// them to this component through this prop.
	options: PropTypes.arrayOf(PropTypes.shape({
		field: PropTypes.string.isRequired,
		fieldLabel: PropTypes.string,
		displayType: PropTypes.oneOf(SIMPLE_STATIC_SEARCH_DISPLAY_TYPES).isRequired,
		displayOptions: PropTypes.object,
		formFieldOptions: PropTypes.object,
		customComponent: PropTypes.elementType
	})),
	// Current value
	value: PropTypes.object,
	// Flag that specifies if search form will be submitted on Enter key press in any of its fields
	// @note Custom fields are not guaranteed to have this functionality since they are responsible for implementing it 
	// by passing a 'keypress' event as the second parameter of the 'onChange' event.
	submitOnEnter: PropTypes.bool,
	
	// Flag that specifies if search is applied
	// @note This is used to display the applied symbol in the title and determine if reset and remove buttons should be
	// rendered.
	applied: PropTypes.bool,
	// Flag that determines if toolbar can be rendered
	enableToolbar: PropTypes.bool,
	// Toolbar title
	toolbarTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if clear fields button can be used
	enableClearButton: PropTypes.bool,
	// Clear button props
	// @see Button component props.
	clearButtonProps: PropTypes.object,
	// Flag that specifies if reset button can be used
	// @note Reset button will only be shown if 'applied' is true.
	enableResetButton: PropTypes.bool,
	// Reset button props
	// @see Button component props.
	resetButtonProps: PropTypes.object,
	// Flag that specifies if clear value of the search options will be used when resetting an option
	useClearValuesForReset: PropTypes.bool,
	// Flag that specifies if remove button can be used
	// @note Remove button will only be shown if 'applied' is true and there is 'onRemove' specified.
	enableRemoveButton: PropTypes.bool,
	// Remove button props
	// @see Button component props.
	removeButtonProps: PropTypes.object,
	// Flag that specifies if clear value of the search options will be used when removing the search filter
	useClearValuesForRemove: PropTypes.bool,
	// Flag that specifies if main search button will be rendered
	enableSearchButton: PropTypes.bool,
	// Flag that specifies if additional toolbar at the bottom of the component will be rendered
	showAdditionalToolbar: PropTypes.bool,
	// Class name of the additional toolbar wrapper 
	additionalToolbarClassName: PropTypes.string,
	// Function that must return content that can be rendered in the additional toolbar
	// @type {function(this): element|string|number}
	additionalToolbarContent: PropTypes.func,
	
	// Events
	onInput: PropTypes.func, // Arguments: {Object} Current filter object
	onChange: PropTypes.func, // Arguments: {Object} Current filter object
	onFieldChange: PropTypes.func, // Arguments: {field: string, value: any, prevValue: any, thisRef: any}
	onRemove: PropTypes.func, // No arguments
	onToggle: PropTypes.func, // Arguments: {boolean} Flag that shows if search content is visible or not.
};

/**
 * Define component default values for own props
 */
SimpleStaticSearch.defaultProps = {
	styleName: 'default',
	id: '',
	className: '',
	appliedClassName: '',
	showTitle: true,
	title: translate('title', 'SimpleStaticSearchComponent'),
	titleToolbar: null,
	collapsable: true,
	defaultCollapse: false,
	layout: SIMPLE_STATIC_SEARCH_LAYOUT.STACKED,
	options: [],
	value: {},
	submitOnEnter: true,
	applied: false,
	enableToolbar: false,
	enableClearButton: true,
	enableResetButton: true,
	useClearValuesForReset: false,
	enableRemoveButton: true,
	useClearValuesForRemove: false,
	enableSearchButton: true,
	showAdditionalToolbar: false,
	additionalToolbarClassName: '',
	
};

// Data objects
export class SimpleStaticSearchOptionObject {
	/**
	 * Constructor
	 * @param {string} field - Search field name.
	 * @param {string} fieldLabel - Search field label.
	 * @param {string} [displayType] - Search value input display type (one of SIMPLE_STATIC_SEARCH_DISPLAY_TYPE). If not
	 * specified, text input will be used.
	 * @param {Object} [displayOptions={}] - Search value input display options (depends on 'displayType').
	 * @param {any} [customComponent=null] - Custom component element type.
	 * @param {Object} [formFieldOptions={}] - Form field component options.
	 * @param {any} [clearValue=null] - Value that will be set when search is cleared.
	 * @param {string|string[]} [clearOnChange=''] - Field name or the list of filed names. If any of those fields 
	 * change, this field will be cleared automatically.
	 */
	constructor(
		field,
		fieldLabel,
		displayType, 
		displayOptions = {},
		customComponent = null,
		formFieldOptions = {},
		clearValue = null,
		clearOnChange = ''
	) {
		this.field = field;
		this.fieldLabel = fieldLabel;
		this.displayType = (displayType ? displayType : SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.TEXT);
		this.displayOptions = displayOptions;
		this.customComponent = customComponent;
		this.formFieldOptions = formFieldOptions;
		this.clearValue = clearValue;
		this.clearOnChange = clearOnChange;
	}
}

export * from "./const";
export * from "./helper";
export default SimpleStaticSearch;