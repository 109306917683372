import {getString} from "Core/helpers/data";
import {matchPath} from "Core/router";
import * as pageConfig from "./pages/ItemPage/config";
import * as campaignPageConfig from "./config";

/**
 * Get the current campaign ID from the URL
 *
 * @param {Object} routerLocation - React router location object.
 * @return {string}
 */
export const getCampaignIdFromUrl = routerLocation => getString(
	matchPath(getString(routerLocation, 'pathname'), {path: pageConfig.routerPath}),
	'params.campaignId'
);

/**
 * Get current campaign router 'to' value
 * @note Router 'to' values is a router path with dynamic values replaced with actual values.
 * 
 * @param {ProjectListItemDataObject} currentProject - Current project.
 * @return {string}
 */
export const getCampaignItemTo = currentProject => campaignPageConfig.routerPath
	.replace(':projectId', getString(currentProject, 'id'));