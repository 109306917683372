import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getGlobalActions} from 'Core/helpers/redux';
import * as  actions from './actions';
import {cloneDeep, get} from 'lodash';
import {getArray, getNumber, isset} from 'Core/helpers/data';
import Icon from 'Core/components/display/Icon';
import {icon_font_error_symbol} from 'Config/app';
import Spinner from 'Core/components/display/Spinner';
import Label from 'Core/components/display/Label';
import NumberLabel from 'Core/components/display/NumberLabel';
import {CUSTOMER_MESSAGE_STATUS_CLASS_MAP} from 'Pages/apps/default/const';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions(actions);

class CampaignCustomerMessageCount extends BaseComponent {
	initialState = {
		/**
		 * Campaign customer messages count
		 * @type {?number|undefined}
		 */
		campaignCustomerMessagesCount: null,
	};
	
	constructor(props) {
		super(props, {
			optimizedUpdate: true,
			// @note This component should be updated only when 'onReloadMessageCounts' event is triggered (@see registered
			// event listener below).
			optimizedUpdateIgnoreProps: ['campaignId', ...Object.keys(allActions)],
		});
		
		// Initialize local state
		this.state = cloneDeep(this.initialState);
		
		// Actions methods
		this.loadCampaignCustomerMessagesCount = this.loadCampaignCustomerMessagesCount.bind(this);
		
		// Register event listener to listen for a custom event triggered when message count should be reloaded
		// @important This is the only way to trigger the loading of message counts.
		this.registerEventListener('onReloadMessageCounts', () => this.loadCampaignCustomerMessagesCount());
	}
	
	/**
	 * Fetch and load campaign customer messages count to local state
	 * @return {Promise<Object>}
	 */
	loadCampaignCustomerMessagesCount() {
		const {campaignId, fetchCampaignCustomerMessagesCountAction} = this.props

		// Fetch campaign customer messages count
		return this.executeAbortableAction(fetchCampaignCustomerMessagesCountAction, campaignId)
			// Load campaign customer messages count to local state
			.then(response => this.setState({campaignCustomerMessagesCount: get(response, 'data')}));
	}

	render() {
		const {campaignCustomerMessagesCount} = this.state;
		
		return (
			<div className="campaign-customer-message-count-content">
				{
					campaignCustomerMessagesCount === null ?
						<div className="text-center"><Spinner /></div>
					: !isset(campaignCustomerMessagesCount) ?
						<div className="text-center"><Icon symbol={icon_font_error_symbol} className="error-color" /></div> 
					: 
						getArray(campaignCustomerMessagesCount).map(i =>
							<span key={i.status} className={`no-wrap tag ${CUSTOMER_MESSAGE_STATUS_CLASS_MAP[i.status]}`}>
								<Label content={this.t(i.status, 'constants.customerMessageStatus')} />:&nbsp;
								<NumberLabel number={getNumber(i, 'messageCount')} />
							</span>
						)
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CampaignCustomerMessageCount.propTypes = {
	campaignId: PropTypes.string,
};

export default connect(null, allActions)(CampaignCustomerMessageCount);