import "./index.css";

import * as campaignPageConfig from "Pages/apps/default/projectPages/campaign/config";
import * as cappingPageConfig from "Pages/apps/default/projectPages/capping/config";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import Label from 'Core/components/display/Label';
import Icon from 'Core/components/display/Icon';
import {
	CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS,
	CAMPAIGN_MESSAGE_CONTENT_TYPES
} from 'Pages/apps/default/projectPages/campaign/const';
import CampaignWizardDialog from 'Components/dialogs/CampaignWizardDialog';
import {getArray, getObject, getString, isset, trimArray} from 'Core/helpers/data';
import {
	createCampaignItemAction,
	createMessageDefinitionItemAction, 
	updateMessageDefinitionItemAction
} from 'Pages/apps/default/projectPages/campaign/actions';
import {rtrimChar} from 'Core/helpers/string';
import {waitingFunction} from 'Core/helpers/function';
import {getCurrentProjectFromSession} from 'Helpers/project';
import {project_navigation_mode} from 'Config/app';
import {NavLink} from "Core/router";
import {getRouterPathUrl} from 'Core/helpers/url';
import {find} from 'lodash';
import ProjectsACL from 'Acl/projects';
import Acl from 'Acl/projects';

// Imported subpages
const subPages = [
	campaignPageConfig,
	cappingPageConfig
];

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	projectList: selectors.projectSelector.getProjectList(state),
	singleProject: selectors.projectSelector.getSingleProject(state),
});

class DefaultAppHomePage extends PageComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'default-app-home-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
		}, 'page_title');
		
		// Dialog methods
		this.openCampaignWizardDialog = this.openCampaignWizardDialog.bind(this);
		
		// Action methods
		this.selectProject = this.selectProject.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }
	
	
	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open a dialog for creating new campaigns using a simplified wizard interface
	 * @type {CampaignMessageContentType} messageType - Type of message to create campaign for.
	 */
	openCampaignWizardDialog(messageType) {
		const {
			openDialogAction, createCampaignItemAction, createMessageDefinitionItemAction,
			updateMessageDefinitionItemAction, addSuccessMessageAction, closeDialogAction, showErrorMessageAction, 
		} = this.props;

		const dialogGUIID = openDialogAction('', CampaignWizardDialog, {
			messageType,
			/** @param {CampaignWizardDataObject} data */
			onSave: data => {
				const project = find(this.getProp('projectList'), {id: data.projectId});
				const defaultTimezone = getString(project, 'defaultTimezone');
				
				// Check if default timezone is set for the project for which a new message definition should be created and
				// do not create it if it doesn't
				if (!defaultTimezone) {
					showErrorMessageAction(
						this.t('no_default_timezone_desc', 'errors.campaign'), 
						undefined, undefined, undefined, undefined, undefined,
						true, undefined, {title: this.t('no_default_timezone_title', 'errors.campaign')}
					);

					// Do not create a new message definition if the project does not have a default timezone
					return;
				}
				
				// Create campaign
				this.executeAbortableAction(createCampaignItemAction, data, project)
					.then(
						/**
						 * @param {CampaignItemDataObject} createdCampaign
						 * @return {Promise<void>}
						 */
						async createdCampaign => {
						if (!isset(createdCampaign)) return;
						
						// Create a message definition for the newly created campaign
						/** @type {MessageDefinitionDataObject} */
						const createdMessageDefinition = await this.executeAbortableAction(
							createMessageDefinitionItemAction, createdCampaign.id, project, true
						);
						if (!isset(createdMessageDefinition)) return;
						
						// Update message definition with basic data and add channels
						/** @type {MessageDefinitionDataObject} */
						const updatedMessageDefinition = await this.executeAbortableAction(
							updateMessageDefinitionItemAction, {
								...createdMessageDefinition,
								startDate: data.startDate,
								channels: data.channels,
							}
						);
						if (!isset(updatedMessageDefinition)) return;

						closeDialogAction(dialogGUIID);
						
						// Redirect to the newly created campaign
						this.redirectTo(
							`${rtrimChar(appConfig.routerPath, '/')}/project/${data.projectId}/campaign/${createdCampaign.id}`+
							`/#wizard`
						);
						
						// Open edit popup for the newly created messaged definition in wizard mode and open recipients tab
						waitingFunction(() => {
							const mdElem = document.getElementById(`md-${updatedMessageDefinition.id}`);
							if (!!mdElem) {
								mdElem.click();
								return true;
							}
						}, 1, 60000)
							.then(() => waitingFunction(() => {
								const tabButtonElem = document.querySelector(
									'#message-definition-popup .popup-tab.RecipientsTab'
								);
								if (!!tabButtonElem) {
									tabButtonElem.click();
									return true;
								}
							}, 1, 60000));
							
						addSuccessMessageAction(this.t(
							'create_success_msg', 'AppsSection.DefaultApp.projectPages.CampaignPage'
						));
					});
			},
		}, {
			id: 'campaign-wizard-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 650
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}


	// Actions methods --------------------------------------------------------------------------------------------------
	/**
	 * Select a project to work with
	 * @param {ProjectListItemDataObject} [project] - Project to select.
	 * updated.
	 */
	selectProject(project) {
		const {history} = this.props;
		
		// Redirect to the project's dashboard page
		history.push(`${rtrimChar(appConfig.routerPath, '/')}/project/${project.id}`);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {projectList, singleProject, mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		const currentProjectId = getString(getCurrentProjectFromSession(projectList), 'id');
		const allowedSubPages = (
			!!currentProjectId ? subPages.filter(page => Acl.check(Acl, currentProjectId, page.access)) : []
		);
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true}/>
						</div>
						: null
				}

				{getArray(projectList).length > 0 ?
					<>
						{projectList.some(p => 
							ProjectsACL.checkPermission(ProjectsACL, p.id, ['CAMPAIGN_WRITE', 'CAMPAIGN_PUBLISH'])
						) ?
							<div className="quick-campaign-wrapper card style-highlight-gradient">
								<div className="card-content">
									<h2 className="page-subtitle"><Label content={this.t('create_campaign_title')}/></h2>
									<div className="simple-subtitle-description">
										<Label content={this.t('create_campaign_subtitle')}/>
									</div>
									<div className="card-grid per-row-5">
										{CAMPAIGN_MESSAGE_CONTENT_TYPES.map(p =>
											<div key={p} className="card action-card-small no-select">
												<div className="card-content" onClick={() => this.openCampaignWizardDialog(p)}>
													<Icon {...CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS[p]} />
													<p className="no-margin">
														<Label content={this.t(p, 'constants.messageType')}/>
													</p>
												</div>
											</div>
										)}
	
										<div className="card action-card-small no-select soon">
											<div className="card-ribbon"><Label content={this.t('comingSoon', 'general')} /></div>
											<div className="card-content">
												<Icon symbol="whatsapp" />
												<p className="no-margin">
													<Label content={this.t('WHATSAPP', 'constants.messageType')}/>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							: null
						}

						{
							!isset(singleProject) ?
								<div className="all-projects-wrapper">
									<h2 className="page-subtitle">
										<Label content={this.t('choose_project', 'AppsSection.DefaultApp.ProjectsPage')}/>
									</h2>
									<div className="simple-subtitle-description">
										<Label content={this.t('choose_project_desc', 'AppsSection.DefaultApp.ProjectsPage')}/>
									</div>
									<div className="card-grid per-row-5">
										{getArray(projectList).map(p =>
											<div
												key={p.id}
												className={
													`card action-card no-select ` +
													(currentProjectId === p.id && project_navigation_mode === 'current_project' ? 
														' active' : ''
													)
												}
											>
												<div className="card-content" onClick={() => this.selectProject(p)}>
													<Icon symbol={p.icon}/>
													<Label element="p" content={p.name}/>
													<div className="description"><Label content={p.description}/></div>
												</div>
											</div>
										)}
									</div>
								</div>
								:
								<div className="all-pages-wrapper">
									<h2 className="page-subtitle"><Label content={this.t('sub_pages_title')}/></h2>
									<div className="simple-subtitle-description">
										<Label content={this.t('sub_pages_desc')}/>
									</div>
									<div className="card-grid per-row-5">
										{allowedSubPages.map(subPage =>
											<NavLink
												key={subPage.routerPath}
												to={
													// Replace router path :projectId with the value form session storage (cookie)
													getRouterPathUrl(
														getRouterPathUrl(subPage.routerPath, getObject(this.props, 'match')),
														{params: {projectId: getString(singleProject, 'id')}}
													)
												}
												onClick={() => {
													const {openMainSidebarGroupAction} = this.props;

													// Open parent group in the main sidebar menu
													const projectGroupRootElem = document.querySelector(
														`.group-menu-item > [data-project-id="${getString(singleProject, 'id')}"]`
													);
													if (!!projectGroupRootElem) openMainSidebarGroupAction(projectGroupRootElem.id);
												}}
												className="card action-card no-select"
											>
												<div className="card-content">
													{subPage.iconElement}
													<Label element="p" content={this.t('page_link_label', subPage.translationPath)}/>
												</div>
											</NavLink>
										)}
									</div>
								</div>
						}
					</>
					:
					<Label 
						element="div" 
						elementProps={{className: 'notice'}}
						icon="sad"
						iconSymbolPrefix="icofont-"
						iconClassName="notice-icon"
						content={this.t('no_projects')} supportHtml={true} 
					/>
				}
			</div>
		), (!singleProject ? 'no-project-selector' : undefined), undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction
		});
	}
}

export * from './config';
export default connect(mapStateToProps, getPageActions({
	createCampaignItemAction, createMessageDefinitionItemAction, updateMessageDefinitionItemAction
}))(DefaultAppHomePage);