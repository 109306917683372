import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink, withRouter} from "Core/router";
import {getObject, getString, isset} from 'Core/helpers/data';
import {getCurrentUrl, getRouterPathUrl} from 'Core/helpers/url';
import Label from 'Core/components/display/Label';
import {getPageActions} from 'Core/helpers/redux';
import {selectors} from 'Core/store/reducers';
import Icon from 'Core/components/display/Icon';
import {project_navigation_mode} from 'Config/app';
import {getCurrentProjectIdFromSession} from 'Helpers/project';
import ProjectsACL from 'Acl/projects';
import {get, omit} from 'lodash';
import {AclCheckDataObject} from 'Core/acl';
import {
	MainSidebarCustomComponentLinkDataObject,
	MainSidebarExternalLinkDataObject,
	MainSidebarGroupLinkDataObject
} from 'Layout/elements/MainSidebar/dataObjects';
import MenuItemGroupToggleButton from 'Layout/elements/MainSidebar/components/MenuItemGroupToggleButton';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
});

class ProjectSubMenuSidebarItem extends BaseComponent {
	constructor(props) {
		super(props, {});

		// Action methods
		this.hide = this.hide.bind(this);

		// Menu methods
		this.isGroupChildActive = this.isGroupChildActive.bind(this);
		
		// Render methods
		this.renderGroupToggleButton = this.renderGroupToggleButton.bind(this);
		this.renderItem = this.renderItem.bind(this);
	}

	/**
	 * Close/hide sidebar
	 */
	hide() {
		const {isMobileBreakpoint, hideMainSidebarAction} = this.props;
		// Main sidebar should only hide on mobile screen sizes.
		if (isMobileBreakpoint) hideMainSidebarAction();
	}

	/**
	 * Check if menu group has an active child item
	 *
	 * @param {string} id - Group item ID.
	 * @return {boolean}
	 */
	isGroupChildActive(id) {
		const elem = document.getElementById(id);
		if (!elem) return false;

		const elemGroup = elem.closest('.group-menu-item');
		if (!elemGroup) return false;

		const groupChildren = elemGroup.querySelector('.group-menu-item-children');
		if (!groupChildren) return false;

		return !!groupChildren.querySelector('.active');
	}

	/**
	 * Render a toggle button of a group menu item
	 * @return {JSX.Element}
	 */
	renderGroupToggleButton() {
		return (
			<MenuItemGroupToggleButton menuItemId={this.getProp('id')} />
		);
	}

	/**
	 * Render sidebar item
	 *
	 * @param {
	 * 	Object|
	 * 	MainSidebarExternalLinkDataObject|
	 * 	MainSidebarCustomComponentLinkDataObject|
	 * 	MainSidebarGroupLinkDataObject
	 * } item - Menu item to render.
	 * @param {string|number} key - Item's unique key.
	 * @param {boolean} [groupItem=false] - Flag that specifies if group item should be rendered.
	 * @return {JSX.Element}
	 */
	renderItem(item, key, groupItem = false) {
		const {id} = this.props;
		/** @type {ProjectListItemDataObject} */
		const project = this.getProp('project');
		const currentProjectId = getCurrentProjectIdFromSession();
		const routeOptions = (
			isset(item?.menuRouterOptions) ?
				getObject(item, 'menuRouterOptions') :
				getObject(item, 'routerOptions')
		);

		return (
			ProjectsACL.check(ProjectsACL, project.id, get(item, 'access', new AclCheckDataObject())) ?
				(
					item instanceof MainSidebarExternalLinkDataObject ?
						<a
							key={key}
							id={id}
							href={item.link}
							title={this.translatePath(item.labelTranslationPath)}
							className={
								`${getCurrentUrl()===item.link ? 'active' : ''} ${getString(item, 'linkProps.className')}`
							}
							{...omit(getObject(item, 'linkProps'), ['className'])}
						>
							{item.icon ?
								<Icon symbol={item.icon} symbolPrefix={item.iconSymbolPrefix} className={item.iconClassName} />
								: null
							}
							<Label
								content={this.t('page_link_label', getString(item, 'labelTranslationPath'))}
								element="span"
							/>
							{groupItem ? this.renderGroupToggleButton(key) : null}
						</a>
					: item instanceof MainSidebarCustomComponentLinkDataObject ?
						item.component ? 
							<item.component key={key} id={id} groupItem={groupItem} {...omit(item.props, ['key'])} /> 
							: null
					: item instanceof MainSidebarGroupLinkDataObject ?
						<div 
							key={key} 
							id={`${id}-group`}
							className={
								`group-menu-item ${this.isGroupOpened(key) ? 'opened' : ''} ` +
								`${this.isGroupChildActive(id) ? 'active-child' : ''}`
							}
						>
							{this.renderItem(item.rootItem, key, true)}
							<div className="group-menu-item-children">
								<div className="indent-menu-item">
									{item.children.map((child, idx) => this.renderItem(child, `${key}-${idx}`))}
								</div>
							</div>
						</div>
					:
						<NavLink
							key={key}
							id={id}
							title={this.t('page_title', getString(item, 'translationPath'))}
							to={
								project_navigation_mode === 'all_projects' ?
									// Replace router path :projectId with the value from the specified project
									getRouterPathUrl(
										item.routerPath,
										{params: {projectId: getString(project, 'id')}}
									)
								: project_navigation_mode === 'current_project' ?
									// Replace router path :projectId with the value form session storage (cookie)
									getRouterPathUrl(
										getRouterPathUrl(item.routerPath, getObject(this.props, 'match')),
										{params: {projectId: getString(currentProjectId)}}
									)
								: ''
							}
							onClick={this.hide}
							{...routeOptions}
						>
							{item.hasOwnProperty('iconElement') ? item.iconElement : null}
							<Label
								content={this.t('page_link_label', getString(item, 'translationPath'))}
								element="span"
							/>
							{groupItem ? this.renderGroupToggleButton() : null}
						</NavLink>
				)
				: null
		);
	}
	
	render() {
		const {id, item, groupItem} = this.props;
		return this.renderItem(item, id, groupItem);
	}
}


/**
 * Define component's own props that can be passed to it by parent components
 */
ProjectSubMenuSidebarItem.propTypes = {
	id: PropTypes.string,
	groupItem: PropTypes.bool,
	project: PropTypes.object,
	item: PropTypes.any,
};

export default withRouter(connect(mapStateToProps, getPageActions())(ProjectSubMenuSidebarItem));