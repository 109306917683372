import {Route} from "Core/router";
import AclErrorPage from 'Core/pages/error/acl';
import {AclCheckDataObject} from 'Core/acl';
import PropTypes from 'prop-types';
import ProjectsACL from 'Acl/projects';
import {get} from 'lodash';
import {getCurrentProjectIdFromUrl} from 'Helpers/project';

/**
 * Page route component with support for project pages
 * @description Use this component as a helper to render standard app pages in main 'App.js' file. It handles ACL and
 * can handle other core functionality in the future, so you don't have to.
 *
 * @param {string} key - React key prop.
 * @param {Object} page - Page import object containing all exports from the page file.
 * @param {boolean} [ignorePermissions=false] - If true, permissions check will be skipped and page will be rendered
 * regardless of ACL settings.
 * @param {PageComponent|PageDataComponent} [errorPage=null] - Custom ACL error page component. If null or not specified
 * default AclErrorPage component will be used.
 * @param {boolean} [exact=false] - When true, will only match if the path matches the location.pathname exactly. This
 * is a prop from react-router-dom Route component.
 * @param {boolean} [strict=false] - When true, a path that has a trailing slash will only match a location.pathname
 * with a trailing slash. This has no effect when there are additional URL segments in the location.pathname. This is a
 * prop from react-router-dom Route component.
 * @param {boolean} [sensitive=false] - When true, will match if the path is case-sensitive. This is a prop from
 * react-router-dom Route component.
 * @param {any} [routeProps] - Any other react-router-dom Route component prop.
 * @return {JSX.Element}
 * @constructor
 */
export function AppPageRouteWithProjects({
	key,
	page,
	ignorePermissions = false,
	errorPage = null,
	exact = false,
	strict = false,
	sensitive = false,
	...routeProps
}) {
	const ErrorPage = (errorPage ? errorPage : AclErrorPage);
	const projectId = getCurrentProjectIdFromUrl();
	
	if (!projectId) return null;
	return (
		<Route
			key={key}
			path={page.routerPath}
			component={(
				ignorePermissions ?
					page.default
					:
					(ProjectsACL.check(ProjectsACL, projectId, get(page, 'access', new AclCheckDataObject())) ?
							page.default
							:
							ErrorPage
					)
			)}
			exact={exact}
			strict={strict}
			sensitive={sensitive}
			{...routeProps}
		/>
	);
}
/**
 * Define component's own props that can be passed to it by parent components
 */
AppPageRouteWithProjects.propTypes = {
	page: PropTypes.object.isRequired,
	ignorePermissions: PropTypes.bool,
	errorPage: PropTypes.elementType,
	exact: PropTypes.bool,
	strict: PropTypes.bool,
	sensitive: PropTypes.bool
};

export default AppPageRouteWithProjects;