import styles from "./index.module.css";

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {has} from "lodash";
import {getArray, getBoolean, getString} from "Core/helpers/data";
import {Link} from "Core/router";
import {getTranslation, i18nEffect, translate} from "Core/i18n";
import {app_home_page_router_path} from "Config/app";
import Label from "Core/components/display/Label";

/**
 * Basic breadcrumbs component
 * @description This component will only render breadcrumbs specified in 'items' prop. All breadcrumb items must be 
 * defined before they are sent to this component because it does not do any dynamic breadcrumbs rendering.
 * 
 * @param {Object} props - Component props (see Breadcrumbs.propTypes below).
 * @return {JSX.Element}
 * @constructor
 */
const Breadcrumbs = ({
	className = '', items = undefined, includeHome = true, homeTo = app_home_page_router_path, separator = '/'
}) => {
	// Use 'i18nEffect' function in React 'useEffect' function to update (re-render) component when translation changes
	const i18nEffectHook = i18nEffect.bind(null, useState(getTranslation())[1]);
	useEffect(() => { i18nEffectHook(); }, [i18nEffectHook]);
	
	const itemsArray = getArray(items);
	return (
		<div className={`breadcrumbs ${getString(className)} ${styles['wrapper']}`}>
			{
				getBoolean(includeHome) ?
					<Link to={getString(homeTo)} className={`breadcrumb home ${styles['item']}`}>
						<Label icon="home" content={translate('page_title', 'HomePage')} />
					</Link>
					: null
			}
			
			{getBoolean(includeHome) && itemsArray.length ? 
				<span className={`breadcrumb-separator ${styles['separator']}`}>
					{getString(separator)}
				</span> 
			: null}

			{itemsArray.map((item, index) =>
				<React.Fragment key={index}>
					{
						has(item, 'to') ?
							<Link to={item.to} className={`breadcrumb ${styles['item']}`}>
								{item.label}
							</Link>
						: has(item, 'href') ?
							<a href={getString(item.href)} className={`breadcrumb ${styles['item']}`}>
								{item.label}
							</a>
						:
							<span className={`breadcrumb ${styles['item']}`}>{item.label}</span>
					}
					{index < itemsArray.length - 1 ? <span className={`breadcrumb-separator ${styles['separator']}`}>
						{getString(separator)}
					</span> : null}
				</React.Fragment>
			)}
		</div>
	)
};

/**
 * Define component's own props that can be passed to it by parent components
 */
Breadcrumbs.propTypes = {
	// Breadcrumbs element class name
	className: PropTypes.string,
	// Breadcrumb items to render
	items: PropTypes.arrayOf(PropTypes.shape({
		to: PropTypes.string,
		href: PropTypes.string,
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
	})),
	// Flag that determines if home page breadcrumb item will be added at the beginning
	// @note Home breadcrumb item will be automatically generated based on the 'homeTo' prop.
	includeHome: PropTypes.bool,
	// Home page link 'to' property
	// @note If 'includeHome' prop is true this will be used as the link 'to' property for the home page.
	homeTo: PropTypes.string,
	// Separator to be used between items
	separator: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Breadcrumbs;