/**
 * Abstract page component used to create app pages
 * NOTE: Components created using this abstract component will have a 'data' field in the local state which will store
 * main component's data. This is a convention chosen by design to separate main component's data (data need by the
 * component to work properly) and other local state values used for GUI or other less significant or more specific
 * purposes.
 */

import React from "react";
import DataComponent from "Core/components/DataComponent";
import {cloneDeep, get} from "lodash";
import {
	app_authorized_home_page_router_path,
	app_home_page_router_path,
	app_login_page_router_path,
	app_title,
	browser_title_prefix,
	browser_title_prefix_separator,
	default_layout, main_scroll_element, page_url_item_id_type
} from "Config/app";
import CoreLayout from "Core/layout";
import {getArray, getBool, getInteger, getNumber, getObject, getString, isset} from "Core/helpers/data";
import {cssStyleStringToObject, decodeURLParams, ltrimString, rtrimChar} from 'Core/helpers/string';
import {matchPath} from "Core/router";
import {scrollToSelector, scrollToTop} from "Core/helpers/dom";
import {hideLoading, showPageLoading} from "Core/helpers/loading";
import {appHasLogin} from "Core/helpers/login";
import auth from "../../auth";
// eslint-disable-next-line
import {RouteProps} from "Core/router";
import {getRouterPathUrl, redirectToPath} from "Core/helpers/url";
import {AsyncMountError} from "Core/errors";

class PageDataComponent extends DataComponent {
	/**
	 * GUI ID of the component used to handle page url or sub-url if such component exists
	 * @type {string}
	 */
	urlComponentGUIID = '';

	/**
	 * Observer used to add 'stuck' class to page title when sticky position is reached
	 * @type {IntersectionObserver}
	 */
	stickyTitleObserver;
	
	/**
	 * Page data component constructor
	 *
	 * @param {object} props - Component props.
	 * @param {object} [initialState={}] - Initial state from child class that will override the default initial state.
	 * @param {PageDataComponentOptions} [options={}] - Component options from child class that will override the default 
	 * options.
	 * @param {string|null} [title] - Page title translation path. This value affects both browser page (tab) title and
	 * page title rendered by the layout.
	 * 	* If defined as string (not an empty string) title layout element will be rendered with the translated value
	 * 	defined by this path and browser page title will be generated using that value and app title config values.
	 * 	* If empty string, title layout element won't be rendered and browser page title will be set to default browser
	 * 	title (like when 'title' HTML element is not specified).
	 * 	* If null, title layout element won't be rendered and browser page title will be generated using app title
	 * 	config values.
	 * 	* If not defined (undefined), title layout element won't be rendered and no browser title will be set.
	 * @param {string} [titlePathPrefix] - If set this will be used as translation path for the title instead of
	 * component's internal 'translationPath' option.
	 */
	constructor(props, initialState = {}, options = {}, title, titlePathPrefix = undefined) {
		/**
		 * Set component options by combining default options overridden by any options from 'options' argument
		 * @type {PageDataComponentOptions}
		 * @private
		 */
		const _options = {
			/**
			 * Page layout
			 * @note If not specified, default layout from config will be used (see '/src/config/app.js' file).
			 * @type {string} Directory name of the layout (in /src/layout/ directory).
			 */
			layout: default_layout,

			/**
			 * Flag that determines if page title layout element should be rendered even if page title is empty, null or
			 * not defined (undefined).
			 */
			forceRenderTitle: false,

			/**
			 * Flag that determines if page title should be rendered if title is is defined
			 */
			renderTitle: true,

			/**
			 * Page router path
			 * @type {string}
			 */
			routerPath: '',

			/**
			 * Specifies custom sub-urls that can be used on the page using router methods
			 * @type {RouteProps[]}
			 */
			customSubUrls: [],

			/**
			 * Scroll to the top of the page on page mount
			 * @note If 'scrollToSelectorOnMount' options is set this option will be ignored.
			 * @type {boolean}
			 */
			scrollToTopOnMount: true,

			/**
			 * Scroll to the DOM element defined by this CSS selector
			 * @note If this options is set 'scrollToTopOnMount' option will be ignored.
			 * @type {string}
			 */
			scrollToSelectorOnMount: '',

			/**
			 * Flag that specifies if login is required to access this page
			 * @note This option is considered only if app uses login. Page layout will not be rendered while checking for
			 * login to stop any other potential authorized IO requests from the components on the page.
			 * @type {boolean}
			 */
			checkLogin: false,
			/**
			 * Function called when checking for login that should create a loading overlay to replace the default content
			 * loading overlay used. It should return a loading overlay ID or null.
			 * @type {Function}
			 */
			checkLoginLoadingFunction: null,

			...cloneDeep(options)
		}

		// Initialize initial state
		const _initialState = {
			/**
			 * Layout component used to render the page
			 * @description Layout component will be dynamically imported based on 'layout' options value.
			 */
			layout: undefined,

			/**
			 * Page title translation path
			 * @note Translation path is used instead of translated value to make the title change when locale changes.
			 * @type string|null
			 */
			title,

			/**
			 * Flag showing if login check is in progress
			 * @note The initial value for this flag is set here, in the constructor, because the page should not render
			 * the layout while checking for login. This will stop any page child component from rendering and potentially
			 * making authorized IO requests. Login check is initiated by default if 'checkLogin' option is set to true.
			 */
			checkingLogin: getBool(options, 'checkLogin', _options.checkLogin),

			...(initialState ? cloneDeep(initialState) : {}),
		}
		
		super(props, _initialState, _options);

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// Set translation path used to translate the title
		// @note If empty or not defined component's 'translationPath' option value will be used.
		this.title = title;
		this.titlePathPrefix = titlePathPrefix ? titlePathPrefix : this.getOption('translationPath');
		if (isset(this.title)) this.setBrowserTitle(this.title);

		// ACL methods
		this.checkLogin = this.checkLogin.bind(this);

		// DOM methods
		this.handleTitleStuck = this.handleTitleStuck.bind(this);
		
		// Data methods
		this.loadPageData = this.loadPageData.bind(this);

		// Router methods
		this.getRouterPathUrl = this.getRouterPathUrl.bind(this);
		this.getUrlParams = this.getUrlParams.bind(this);
		this.getUrlParam = this.getUrlParam.bind(this);
		this.getCurrentRouterPathUrl = this.getCurrentRouterPathUrl.bind(this);
		this.getUrlComponentGUIID = this.getUrlComponentGUIID.bind(this);
		this.clearUrlComponentGUIID = this.clearUrlComponentGUIID.bind(this);
		this.isBaseUrl = this.isBaseUrl.bind(this);
		this.isCreateUrl = this.isCreateUrl.bind(this);
		this.isItemWithoutIdUrl = this.isItemWithoutIdUrl.bind(this);
		this.isItemUrl = this.isItemUrl.bind(this);
		this.isCustomUrl = this.isCustomUrl.bind(this);
		this.getItemUrlId = this.getItemUrlId.bind(this);
		this.getCustomUrl = this.getCustomUrl.bind(this);
		this.getHomeRedirectTo = this.getHomeRedirectTo.bind(this);
		this.getAuthorizedHomeRedirectTo = this.getAuthorizedHomeRedirectTo.bind(this);
		this.getBaseRedirectTo = this.getBaseRedirectTo.bind(this);
		this.getCreateRedirectTo = this.getCreateRedirectTo.bind(this);
		this.getItemRedirectTo = this.getItemRedirectTo.bind(this);
		this.redirectTo = this.redirectTo.bind(this);
		this.redirectToHome = this.redirectToHome.bind(this);
		this.redirectToAuthorizedHome = this.redirectToAuthorizedHome.bind(this);
		this.redirectToBase = this.redirectToBase.bind(this);
		this.redirectToCreate = this.redirectToCreate.bind(this);
		this.redirectToItem = this.redirectToItem.bind(this);
		this.redirectToSubUrl = this.redirectToSubUrl.bind(this);
		this.handleSubUrl = this.handleSubUrl.bind(this);
		this.handleCreateUrl = this.handleCreateUrl.bind(this);
		this.handleInvalidItemUrl = this.handleInvalidItemUrl.bind(this);
		this.handleItemUrl = this.handleItemUrl.bind(this);
		this.handleBaseUrl = this.handleBaseUrl.bind(this);
		this.handleUnknownUrl = this.handleUnknownUrl.bind(this);
		this.handleCustomUrl = this.handleCustomUrl.bind(this);
		this.handleUrlChange = this.handleUrlChange.bind(this);
		this.closeUrlComponent = this.closeUrlComponent.bind(this);

		// Title methods
		this.setBrowserTitle = this.setBrowserTitle.bind(this);
		this.setPageTitle = this.setPageTitle.bind(this);
		this.setTitle = this.setTitle.bind(this);

		// Render methods
		this.renderPageTitle = this.renderPageTitle.bind(this);
		this.renderContentBeforeLogin = this.renderContentBeforeLogin.bind(this);
		this.renderLayout = this.renderLayout.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|void>} Promise that will resolve with the updated mount count that will be set in the 
	 * 'componentDidMount' method or undefined for default functionality where 'componentDidMount' will just reset the 
	 * mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		try {
			// Call the parent component's 'asyncComponentDidMount' method that handles core functionality but don't perform 
			// the data load because data loading should be done after login check (if applicable) since IO requests can be 
			// made while loading the data in the 'defaultLoad' method.
			await super.asyncComponentDidMount(true);

			// IMPORTANT: This is required in order for dynamic import to load properly when app is built for production. 
			// Dynamic import cannot resolve imported value 'default_layout' so there needs top be a local const getting the 
			// value before it can be used in the dynamic import.
			let defaultLayout;
			defaultLayout = default_layout;

			// Dynamically import required layout
			await import(`../../layout/layouts/${this.getOption('layout', defaultLayout)}`)
				// Set layout if it hasn't been set yet
				.then(({default: layout}) => this.setState({layout}))

			// Check login
			const loginCheckResult = await this.checkLogin();
			// Stop here if login check fails
			// @note Check login method will do the redirect to the login page so there is nothing else we need to do here.
			if (loginCheckResult === 'LOGIN_FAILED') return;

			// Load initial props data into local state if this method was not overridden in the child component
			if (!override) {
				const dataPropName = this.getDataPropName();
				const data = (dataPropName ? this.props[this.getDataPropName()] : this.props);
				await this.load(data);
			}

			// Handle page URLs
			await this.handleSubUrl(
				ltrimString(this.getCurrentRouterPathUrl(), this.getRouterPathUrl()),
				get(this.props, 'location')
			);
			if (this.isCreateUrl()) this.urlComponentGUIID = await this.handleCreateUrl();
			else if (this.isItemWithoutIdUrl()) this.urlComponentGUIID = await this.handleInvalidItemUrl();
			else if (this.isItemUrl()) this.urlComponentGUIID = await this.handleItemUrl(this.getItemUrlId());
			else if (this.isBaseUrl()) this.urlComponentGUIID = await this.handleBaseUrl();
			else if (this.isCustomUrl()) this.urlComponentGUIID = await this.handleCustomUrl(this.getCustomUrl());
			else this.urlComponentGUIID = await this.handleUnknownUrl();

			// Load page data
			await this.loadPageData();

			// Scroll to ...
			const scrollToSelectorOnMount = this.getOption('scrollToSelectorOnMount');
			if (scrollToSelectorOnMount) scrollToSelector(scrollToSelectorOnMount);
			else if (this.getOption('scrollToTopOnMount')) scrollToTop();

			// Add 'stuck' class to page title when sticky position is reached
			this.handleTitleStuck();
		} catch (e) {
			// Scroll to ...
			const scrollToSelectorOnMount = this.getOption('scrollToSelectorOnMount');
			if (scrollToSelectorOnMount) scrollToSelector(scrollToSelectorOnMount);
			else if (this.getOption('scrollToTopOnMount')) scrollToTop();

			// Add 'stuck' class to page title when sticky position is reached
			this.handleTitleStuck();
			
			throw e;
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			.then(async () => {
				// If page URL changes
				if (prevProps.location.pathname !== this.getProp('location').pathname) {
					// Handle page URL changes
					await this.handleUrlChange(
						this.getItemUrlId(undefined, prevProps),
						ltrimString(this.getCurrentRouterPathUrl(prevProps), this.getRouterPathUrl()),
						prevProps.location
					);

					// Handle page URLs
					await this.handleSubUrl(
						ltrimString(this.getCurrentRouterPathUrl(), this.getRouterPathUrl()),
						get(this.props, 'location')
					);
					if (this.isCreateUrl()) this.urlComponentGUIID = await this.handleCreateUrl(prevProps.location);
					else if (this.isItemUrl()) this.urlComponentGUIID = await this.handleItemUrl(this.getItemUrlId(), prevProps.location);
					else if (this.isBaseUrl()) this.urlComponentGUIID = await this.handleBaseUrl(prevProps.location);
					else if (this.isCustomUrl()) this.urlComponentGUIID = await this.handleCustomUrl(this.getCustomUrl(), prevProps.location);
					else this.urlComponentGUIID = await this.handleUnknownUrl(prevProps.location);
				}
			})
			.then(() => this.state);
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		
		if (this.stickyTitleObserver) this.stickyTitleObserver.disconnect();
		this.closeUrlComponent();
	}


	// I18n -------------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic translations
	 * @description Dynamic translations are translations that are not called in component's 'render' or
	 * 'componentDidUpdate' methods. Since automatic translation works by updating the component when locale changes,
	 * only values translated in 'render' and 'componentDidUpdate' methods will be automatically translated. All other
	 * translations need to be handled manually using this method.
	 * @note This method will be called after locale change has been handled.
	 *
	 * @param {object} translation - Currently loaded translation object.
	 */
	updateDynamicTranslations(translation) {
		// Update browser title translation
		if (isset(this.state.title)) this.setBrowserTitle(this.state.title);
	}


	// ACL methods ------------------------------------------------------------------------------------------------------
	/**
	 * Check if user is logged in
	 * @note This method will log out the user and redirect to the login page if check fails. This check should be done
	 * on each individual section app page because page components (child components) are mounted before app and section
	 * components (parent components) and we need to display the loading overlay as soon as possible. Checking will be
	 * performed only if 'checkLogin' component option flag is set to true.
	 *
	 * @return {Promise<any|'LOGIN_FAILED'>}
	 * @throws {AsyncMountError}
	 */
	checkLogin() {
		if (this.getOption('checkLogin') === true) {
			if (appHasLogin()) {
				// If there are no auth tokens present, login will automatically fail
				if (!auth.hasTokens()) {
					// Logout
					return auth.logout().then(() => {
						// Redirect to login page
						redirectToPath(app_login_page_router_path);
						// Returning the special value in order to stop other methods being called after checking the login in
						// 'componentDidMount' method
						return 'LOGIN_FAILED';
					});
				}

				const loading = (
					this.getOption('checkLoginLoadingFunction') ?
						this.getOption('checkLoginLoadingFunction')() :
						showPageLoading(true, true)
				);

				// Check the login by using the authorized ping request
				return this.executeAbortableAction(auth.authorizedPing, true, undefined, undefined, auth)
					// If authorized ping was successful just hide the loading overlay
					.then(() => { if (loading) hideLoading(loading); })
					// Reset 'checkingLogin' flag so that the page can render the layout content
					// @note This flag is initialized in the constructors which means that it has already been set to true 
					// if needed.
					.then(() => this.setState({checkingLogin: false}))
					// If authorized ping was not successful, hide the loading overlay and logout
					.catch(error => {
						if (loading) hideLoading(loading);
						if (error.name !== 'AbortError') {
							// Logout
							return auth.logout().then(() => {
								// Redirect to login page
								redirectToPath(app_login_page_router_path);
								// Returning the special value in order to stop other methods being called  after checking the 
								// login in 'componentDidMount' method.
								return 'LOGIN_FAILED';
							});
						}
						throw new AsyncMountError('Login check aborted!');
					});
			} else {
				// Reset 'checkingLogin' flag so that the page can render the layout content
				// @note This flag is initialized in the constructors and in this case (when 'appHasLogin' returns false) it
				// should not have been set to true. Resetting the flag is just a precaution.
				return this.setState({checkingLogin: false});
			}
		}
		return Promise.resolve();
	}


	// DOM methods ------------------------------------------------------------------------------------------------------
	/**
	 * Add 'stuck' class to page title when sticky position is reached
	 */
	handleTitleStuck() {
		// Add 'stuck' class to page title when sticky position is reached
		const pageTitleElement = document.querySelector('.page-title');
		if (pageTitleElement) {
			let stuckInterval;
			this.stickyTitleObserver = new IntersectionObserver(
				([e]) => {
					const isStuck = e.target.classList.contains('stuck');
					if (!isStuck) {
						if (e.intersectionRatio < 1) {
							e.target.classList.add("stuck");
							stuckInterval = setInterval(() => {
								const mainScrollElement = document.querySelector(main_scroll_element);
								if (!!mainScrollElement && getNumber(mainScrollElement.scrollTop) === 0) {
									e.target.classList.remove("stuck");
									clearInterval(stuckInterval);
								}
							}, 1);
						}
					}
				},
				{threshold: [1]}
			);
			this.stickyTitleObserver.observe(pageTitleElement);
		}
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {any|void}
	 * @throws {AsyncMountError} Throw this error or reject a promise with it in order to try running another mount
	 * method if multiple mount calls were made for this page.
	 */
	loadPageData() {
		// Implement this method in the component that extends this abstract component
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	// @note By default, pages have two predefined sub-urls: 'create' and 'item'. These can be used in any way. The
	// benefit of using this is that there are a few helper methods like 'redirectToCreate' and 'redirectToItem' built
	// around them to make creating standardized pages easier.
	/**
	 * Get router path of the page
	 * @note This method will replace any route param definitions (like :id) with the actual param values from the match
	 * object (current URL). Use this method instead of 'routerPath' option to ensure that dynamic routes work properly.
	 * @return {string} Router path where dynamic values are replaced with actual values from the URL and without the 
	 * trailing slash.
	 */
	getRouterPathUrl() {
		return getRouterPathUrl(this.getOption('routerPath'), getObject(this.props, 'match'));
	}
	
	/**
	 * Get page URL params parsed as key-value object
	 * @note URL params are everything after '?' character in the URL.
	 * @return {Object}
	 */
	getUrlParams() { return decodeURLParams(getString(this.props, 'location.search')); }

	/**
	 * Get a single URL param value
	 * @note URL params are everything after '?' character in the URL.
	 *
	 * @param {string} param - Param name to get the value for.
	 * @param {string} [defaultValue=''] - Default value if param does not exist.
	 * @return {string}
	 */
	getUrlParam(param, defaultValue = '') { return getString(this.getUrlParams(), param, defaultValue); }
	
	/**
	 * Get current router path name
	 * @note Pages must be connected to the router so that they will receive location data.
	 * @param {Object} [props] - Props to use for getting location.
	 * @return {string}
	 */
	getCurrentRouterPathUrl(props) { return getString(props ? props : this.props, 'location.pathname'); }

	/**
	 * Get GUI ID of the component used to handle an url or sub-url if such component exists.
	 * @return {string}
	 */
	getUrlComponentGUIID() { return this.urlComponentGUIID; }

	/**
	 * Clear GUI ID of the component used to handle an url or sub-url.
	 */
	clearUrlComponentGUIID() { this.urlComponentGUIID = ''; }

	/**
	 * Check if current URL is this pages base URL
	 * @see options.routerPath
	 * @return {boolean}
	 */
	isBaseUrl() { return (rtrimChar(this.getCurrentRouterPathUrl(), '/') === this.getRouterPathUrl()); }

	/**
	 * Check if current URL is this pages 'create' sub-url
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 * @return {boolean}
	 */
	isCreateUrl() { return (rtrimChar(this.getCurrentRouterPathUrl(), '/') === `${this.getRouterPathUrl()}/new`); }

	/**
	 * Check if current URL is the pages 'item' sub-url without the item ID in it
	 * @note This is an invalid URL.
	 * @return {boolean}
	 */
	isItemWithoutIdUrl() {
		return (!!matchPath(
			rtrimChar(this.getCurrentRouterPathUrl(), '/'), 
			{path: `${this.getOption('routerPath')}/item`, exact: true}
		));	
	}

	/**
	 * Check if current URL is this pages 'item' sub-url
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 * @return {boolean}
	 */
	isItemUrl() {
		return !!matchPath(
			rtrimChar(this.getCurrentRouterPathUrl(), '/'), 
			{path: `${this.getOption('routerPath')}/item/:id`, exact: false}
		);
	}

	/**
	 * Check if current URL matches any custom pages sub-url
	 * @note This method will return true for the first custom sub-url from the 'customSubUrls' option.
	 * @type {boolean}
	 */
	isCustomUrl() {
		for (let i = 0; i < getArray(this.getOption('customSubUrls')).length; i++) {
			let customSubUrl = cloneDeep(this.getOption('customSubUrls')[i]);
			customSubUrl.path = `${this.getOption('routerPath')}/${customSubUrl.path}`
			if (!!matchPath(this.getCurrentRouterPathUrl(), customSubUrl)) return true;
		}
		return false;
	}

	/**
	 * Get item ID from URL
	 * @param {'integer'|'string'} resultDataType - Data type of the detected URL ID.
	 * @param {Object} [props] - Props to use for getting location.
	 * @return {number|string}
	 */
	getItemUrlId(resultDataType = page_url_item_id_type, props) {
		if (resultDataType === 'integer') {
			return getInteger(
				matchPath(
					this.getCurrentRouterPathUrl(props),
					{path: `${this.getOption('routerPath')}/item/:id`, exact: false}),
				'params.id',
				undefined
			);
		} else {
			return getString(
				matchPath(this.getCurrentRouterPathUrl(props),
					{path: `${this.getOption('routerPath')}/item/:id`, exact: false}),
				'params.id',
				undefined
			);
		}
	}

	/**
	 * Get custom sub-url match
	 * @note This method will return the first custom sub-url match from the 'customSubUrls' option.
	 * @return {{isExact: boolean, params: Object, path: string, url: string} | null} Returns custom url match object or 
	 * null if there is no match.
	 */
	getCustomUrl() {
		for (let i = 0; i < getArray(this.getOption('customSubUrls')).length; i++) {
			let customSubUrl = cloneDeep(this.getOption('customSubUrls')[i]);
			customSubUrl.path = `${this.getOption('routerPath')}/${customSubUrl.path}`
			const match = matchPath(this.getCurrentRouterPathUrl(), customSubUrl);
			if (match !== null) {
				return {...match, path: ltrimString(match.path, `${this.getRouterPathUrl()}/`)};
			}
		}
		return null;
	}

	/**
	 * Get router 'to' value of the app's home page
	 * @return {string}
	 */
	getHomeRedirectTo() { return app_home_page_router_path; }

	/**
	 * Get router 'to' value of the app's home page when user is logged in
	 * @return {string}
	 */
	getAuthorizedHomeRedirectTo() { return app_authorized_home_page_router_path; }
	
	/**
	 * Get router 'to' value of the pages base URL
	 * @return {string}
	 */
	getBaseRedirectTo() { return this.getRouterPathUrl(); }

	/**
	 * Get router 'to' value of the pages 'create' sub-url
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 * @return {string}
	 */
	getCreateRedirectTo() { return `${this.getRouterPathUrl()}/new`; }

	/**
	 * Get router 'to' value of the pages 'item' sub-url
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string} id - Item ID.
	 * @return {string}
	 */
	getItemRedirectTo(id) { return `${this.getRouterPathUrl()}/item/${id}`; }

	/**
	 * Redirect to any router path
	 * @param {string} to - Router path (like react router Link component 'to' prop).
	 */
	redirectTo(to) { this.getProp('history').push(to); }

	/**
	 * Redirect to app's home page
	 * @see app config 'app_home_page_router_path'.
	 */
	redirectToHome() { this.getProp('history').push(this.getHomeRedirectTo()); }

	/**
	 * Redirect to app's home page when user is logged in
	 * @see app config 'app_authorized_home_page_router_path'.
	 */
	redirectToAuthorizedHome() { this.getProp('history').push(this.getAuthorizedHomeRedirectTo()); }

	/**
	 * Redirect to pages base url
	 * @param {boolean} [replace=false] - Flag that specifies if URL will be replaced in history instead of pushed.
	 * @see options.routerPath
	 */
	redirectToBase(replace = false) { 
		if (replace) this.getProp('history').replace(this.getBaseRedirectTo()); 
		else this.getProp('history').push(this.getBaseRedirectTo()); 
	}

	/**
	 * Redirect to pages 'create' sub-url
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 */
	redirectToCreate() { this.getProp('history').push(this.getCreateRedirectTo()); }

	/**
	 * Redirect to pages 'create' sub-url
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 * @param {string} id - Item ID.
	 */
	redirectToItem(id) { this.getProp('history').push(this.getItemRedirectTo(id)); }

	/**
	 * Redirect to pages sub-url
	 * @see options.routerPath
	 * @param {string} [subPath=''] - Pages sub-path to redirect to.
	 */
	redirectToSubUrl(subPath = '') {
		this.getProp('history').push(this.getBaseRedirectTo() + (subPath ? `/${subPath}` : ''));
	}

	/**
	 * Method that will be called for each page sub-url
	 *
	 * @param {string} subUrlPath - Current page sub-url path or empty string if on page base path.
	 * @param {Object} location - Current router location object.
	 */
	handleSubUrl(subUrlPath, location) {
		// Implement this method in the component that extends this abstract component
	}

	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl(prevLocation) {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called if current URL matches an invalid 'item' sub-url of the page
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleInvalidItemUrl(prevLocation) {
		// Override this method in the component that extends this abstract component if needed
		this.redirectToBase();
		return '';
	}
	
	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string|number} id - Item ID.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id, prevLocation) {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its 
	 * base URL if such component exists.
	 */
	handleBaseUrl(prevLocation) {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called if current URL is an unknown page URL
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page URL is 
	 * unknown if such component exists.
	 */
	handleUnknownUrl(prevLocation) {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called if current URL is one of the custom sub-urls defined in 'customSubUrls' option.
	 * 
	 * @param {{isExact: boolean, params: Object, path: string, url: string}} customUrlMatch - Custom page sub-url match 
	 * object.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page URL is
	 * a custom sub-url if such component exists.
	 */
	handleCustomUrl(customUrlMatch, prevLocation) {
		// Implement this method in the component that extends this abstract component
		return '';
	}

	/**
	 * Method that will be called when page URL changes
	 * @param {string|number} prevItemUrlId - Previous item URL ID if any.
	 * @param {string} prevSubUrlPath - Previous page sub-url path.
	 * @param {Object} prevLocation - Previous router location object.
	 */
	handleUrlChange(prevItemUrlId, prevSubUrlPath, prevLocation) {
		// Implement this method in the component that extends this abstract component
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		// Implement this method in the component that extends this abstract component
	}


	// Title methods ----------------------------------------------------------------------------------------------------
	/**
	 * Set page browser title only
	 * @note Page browser title is calculated based on specified title and app config.
	 *
	 * @param {string|null} [title] - Page browser title to set.
	 */
	setBrowserTitle(title) {
		document.title = (
			title === '' ? '' :
				!title ? (browser_title_prefix ? this.translatePath(browser_title_prefix) : this.translatePath(app_title)):
					this.translatePath(browser_title_prefix)
					+ browser_title_prefix_separator
					+ this.translate(title, this.titlePathPrefix)
		);
	}

	/**
	 * Set page layout title only
	 *
	 * @param {string|null} [title] - Page layout title to set.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	setPageTitle(title) { return this.setState({title}); }

	/**
	 * Set page title
	 * @note This method will also set the browser title.
	 *
	 * @param {string|null} [title] - Page title to set.
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	setTitle(title) {
		return this.setPageTitle(title).then(state => {
			if (isset(state.title)) this.setBrowserTitle(state.title);
			return state;
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;
		
		return (
			<h1 className="page-title">
				{title ? this.translate(title, this.titlePathPrefix) : ''}
			</h1>
		);
	}
	
	/**
	 * Content to render inside the page layout before and while checking for login
	 * @note This is only relevant if 'checkLogin' component option is set to true.
	 *
	 * @return {any} Anything that can be rendered.
	 */
	renderContentBeforeLogin() {
		// Implement this method in the component that extends this abstract component
		return null;
	}
	
	/**
	 * Render page with appropriate layout
	 * @note This method should be used in actual page component's 'render' method to properly render the page.
	 *
	 * @param {any} content - Page content
	 * @param {string} [className=''] - Additional layout element CSS class name.
	 * @param {Object|string} [style={}] - Layout element inline CSS style.
	 * @param {Object} [otherLayoutProps={}] - Other props that will be sent to the layout component.
	 * @return {JSX.Element|null} Rendered page with appropriate layout.
	 */
	renderLayout(content, className = '', style = {}, otherLayoutProps = {}) {
		if (!this.state.layout) return null;
		const {title} = this.state;
		const styleObject = (typeof style === 'string' ? cssStyleStringToObject(style) : style);

		// Set body 'data-layout' attribute to the layout name
		document.body.dataset.layout = this.getOption('layout');
		
		const PageLayout = this.state.layout;
		return (
			<CoreLayout>
				<PageLayout
					pageTitle={title ? this.translate(title, this.titlePathPrefix) : ''}
					className={className}
					style={styleObject}
					{...otherLayoutProps}
				>
					{
						this.getOption('forceRenderTitle') || (this.getOption('renderTitle') && title) ?
							this.renderPageTitle() 
							: null
					}
					<div className={`page-content`}>
						{this.getOption('checkLogin') === true && this.state.checkingLogin ? 
							this.renderContentBeforeLogin() 
						: null}
						{this.state.checkingLogin !== true ? content : null}
					</div>
				</PageLayout>
			</CoreLayout>
		);
	}
}

// Type definitions
/**
 * @typedef {Object} PageDataComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined.
 * @property {string} [domPrefix='base-component'] - Prefix used for component's main DOM element. This is used in
 * methods like 'getDomId'.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {string[]} [optimizedUpdateIgnoreProps] - List of prop names that will be ignored during optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all props.
 * @property {string[]} [optimizedUpdateIncludeState] - List of state values that will be included in optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all state fields.
 * @property {boolean} [updateOnSkinChange=false] - Flag that specifies if component will update when app skin has been
 * changes (for example from light to dark).
 * @property {string[]} [dialogsToCloseOnUnmount=[]] - List of dialog GUI IDs of the dialogs that should be closed when
 * page component unmounts.
 * @property {boolean} [forceFastLoad=false] - Flag that determines if load queue will use the fast mode. WARNING: Load
 * queue fast mode does not guarantee linear loads for non async load calls, for example if load method s called within
 * a for loop. Async calls should work properly.
 * @property {boolean} [disableLoad=false] - Flag that determines if load functionality is disabled. If true 'load'
 * method will not load data from props into local state.
 * @property {boolean} [enableLoadOnDataPropChange=false] - Flag that determines if data will be loaded from props to
 * local state every time data prop changes. This flag will be ignored if 'disableLoad' is true.
 * @property {string} [dataPropAlias=''] - Main data prop alisa. This is used by child components that need to have a
 * different prop field for main data, like input components that use 'value' instead of 'data'.
 * @property {string} [originalDataPropAlias=''] - Original data prop alisa. This is used by child components that need
 * to have a different prop field for original data, like input components that use 'originalValue' instead of
 * 'originalData'.
 * @property {boolean} [wholePropAsData=false] - Flag that determines if whole props will be used as main data on load
 * instead of 'data' prop or 'dataPropAlias' options.
 * @property {string} [layout] - Page layout. If not specified, default layout from config will be used (see
 * '/src/config/app.js' file 'default_layout' option).
 * @property {boolean} [forceRenderTitle=false] - Flag that determines if page title layout element should be rendered
 * even if page title is empty, null or not defined (undefined).
 * @property {boolean} [renderTitle=true] - Flag that determines if page title should be rendered if title is defined.
 * @property {string} [routerPath=''] - Page router path.
 * @property {RouteProps[]} [customSubUrls=[]] - Specifies custom sub-urls that can be used on the page using router 
 * methods.
 * @property {boolean} [scrollToTopOnMount=true] - Scroll to the top of the page on page mount.
 * @property {string} [scrollToSelectorOnMount=''] - Scroll to the DOM element defined by this CSS selector.
 * @property {boolean} [checkLogin=false] - Flag that specifies if login is required to access this page. This option is
 * considered only if app uses login. Page layout will not be rendered while checking for login to stop any other 
 * potential authorized IO requests from the components on the page.
 * @property {Function} [checkLoginLoadingFunction=null] - Function called when checking for login that should create a 
 * loading overlay to replace the default content loading overlay used. It should return a loading overlay ID or null.
 */

export default PageDataComponent;
export {executeComponentCallback, executeComponentCallbackPromise} from "./BaseComponent";