import React from "react";
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from "prop-types";
import {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {getArray} from 'Core/helpers/data';
import CheckboxListInput from 'Core/components/input/CheckboxListInput';

class FormCheckboxInput extends BaseComponent {
	constructor(props) {
		super(props, {
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onChange'],
		});
	}

	render() {
		const {
			/** @type {FormQuestionDataObject} */
			data, ...otherProps
		} = this.props;
		
		return (
			<CheckboxListInput
				isClearable={!data.mandatory}
				clearValue={null}
				checkboxProps={{
					buttonProps: {
						displayStyle: BUTTON_STYLE.DEFAULT,
						displayType: BUTTON_DISPLAY_TYPE.NONE,
					}
				}}
				options={getArray(data, 'answers').map(a => ({label: a.content, value: a.id}))}
				{...otherProps}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FormCheckboxInput.propTypes = {
	// Question form element data
	// @type {FormQuestionDataObject}
	data: PropTypes.object,
	// Selected value (usually by the user) that will be submitted
	value: PropTypes.arrayOf(PropTypes.string),
	// Event triggered when input component value is changed (usually by the user)
	// @type {function(array(string|number))}
	onChange: PropTypes.func,
};

/**
 * Define component default values for own props
 */
FormCheckboxInput.defaultProps = {

};

export default FormCheckboxInput;