/** @typedef {string} CheckboxListInputLayout */
export const CHECKBOX_LIST_INPUT_LAYOUT_ALIGNED = 'aligned';
export const CHECKBOX_LIST_INPUT_LAYOUT_STACKED = 'stacked';
/** @enum {CheckboxListInputLayout} */
export const CHECKBOX_LIST_INPUT_LAYOUT = {
	ALIGNED: CHECKBOX_LIST_INPUT_LAYOUT_ALIGNED,
	STACKED: CHECKBOX_LIST_INPUT_LAYOUT_STACKED,
}
/** @type {CheckboxListInputLayout[]} */
export const CHECKBOX_LIST_INPUT_LAYOUTS = [
	CHECKBOX_LIST_INPUT_LAYOUT_ALIGNED, 
	CHECKBOX_LIST_INPUT_LAYOUT_STACKED,
];