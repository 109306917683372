import {TagDataObject} from 'Tags/dataObjects';
import {getNumber, getString} from 'Core/helpers/data';
import {get} from 'lodash';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {TagDataObject}
 */
export const input = rawData => new TagDataObject(
	getString(rawData, 'tag'),
	get(rawData, 'tagTitle'),
	get(rawData, 'tagDescription'),
	get(rawData, 'tagDataType'),
	getNumber(rawData, 'length'),
	getString(rawData, 'lengthPrecision')
);