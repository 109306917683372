import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import PropTypes from "prop-types";
import {getString} from 'Core/helpers/data';
import TextInput from 'Core/components/input/TextInput';

class FormTextInput extends BaseComponent {
	constructor(props) {
		super(props, {
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onChange'],
		});
	}
	
	render() {
		const {
			/** @type {FormQuestionDataObject} */
			data, onChange, ...otherProps
		} = this.props;
		
		return (
			<TextInput 
				onChange={e => executeComponentCallback(onChange, getString(e, 'target.value'))}
				{...otherProps}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FormTextInput.propTypes = {
	// Question form element data
	// @type {FormQuestionDataObject}
	data: PropTypes.object,
	// Selected value (usually by the user) that will be submitted
	value: PropTypes.any,
	// Event triggered when input component value is changed (usually by the user)
	// @type {function(string)}
	onChange: PropTypes.func,
};

/**
 * Define component default values for own props
 */
FormTextInput.defaultProps = {
	
};

export default FormTextInput;