import "./index.css";

import React from "react";
import ProjectPageDataComponent from 'Pages/apps/default/projectPages/ProjectPageDataComponent';
import {Link} from "Core/router";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getArray, getNumber, getString, isset, trimArray} from 'Core/helpers/data';
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {getPopupInstance} from "Core/helpers/popup";
import ItemPopup from "./popups/ItemPopup";
import {Tooltip} from "Core/components/global/Tooltip";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {
	icon_font_create_symbol,
	icon_font_edit_symbol,
	icon_font_error_symbol, icon_font_info_circle_symbol
} from 'Config/app';
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {isSuccessful} from "Core/helpers/io";
import {AsyncMountError} from "Core/errors";
import {
	DataTableCellDateTypeOptionsDataObject,
	DataTableCellTextTypeOptionsDataObject
} from 'Core/components/advanced/DataTable/DataTableCell/dataObjects';
import ImportCustomersFromFileDialog from "Components/dialogs/ImportCustomersFromFileDialog";
import Spinner from "Core/components/display/Spinner";
import PhoneInput from "Core/components/input/PhoneInput";
import {fetchCustomerCustomFieldsAction} from 'Components/advanced/ImportCustomersFromList/actions';
import CustomerCustomFieldFilter from "./components/CustomerCustomFieldFilter";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {getAppLocaleDateFormat} from 'Core/helpers/locale';
import {LOCALE_DATE_FORMAT_NAME} from 'Core/const/locale';
import {STANDARD_DATE_TIME_FORMAT} from 'Core/const/datetime';
import AdvancedDropdown, {ADVANCED_DROPDOWN_POSITION} from 'Core/components/display/AdvancedDropdown';
import DownloadButton from 'Core/components/action/DownloadButton';
import {getIOUrl} from 'Core/io/helper';
import {getDateString} from 'Core/helpers/datetime';
import {showErrorMessage} from 'Core/helpers/message';
import {omit} from 'lodash';
import Acl from 'Acl/projects';
import {getCurrentProjectFromUrl, getCurrentProjectIdFromUrl} from 'Helpers/project';
import Breadcrumbs from 'Core/components/display/Breadcrumbs';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	projectList: selectors.projectSelector.getProjectList(state),
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainList: selectors[reducerStoreKey].getCustomersList(state),
	mainListPagination: selectors[reducerStoreKey].getCustomersListPagination(state),
	mainListSort: selectors[reducerStoreKey].getCustomersListSort(state),
	mainListFilter: selectors[reducerStoreKey].getCustomersListFilter(state),
	singleProject: selectors.projectSelector.getSingleProject(state),
});

class CustomersPage extends ProjectPageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true,

			/**
			 * Flag that shows if importing customers form file is in progress
			 */
			importFromFileInProgress: false,

			/**
			 * Customer custom fields
			 * @type {
			 * 	{id: number, name: string, code: string, description: string, messageType: CampaignMessageContentType}[]|
			 * 	null|undefined
			 * }
			 */
			customerCustomFields: null,
		}, {
			domPrefix: 'customers-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,

			// Custom component options
			/**
			 * Current interval to check if importing customers from file is in progress
			 * @note The value entered here is an initial value that will be used when component mounts, but it will be
			 * changed based on the state of the 'importFromFileInProgress' flag when
			 * 'checkImportFromFileIntervalSlow' or 'checkImportFromFileIntervalFast' will be used. This should
			 * probably be the same value as 'checkImportFromFileIntervalSlow' option.
			 * @type {number|null|undefined} If falsy, no interval well be started.
			 */
			checkImportFromFileInterval: 60000,
			/**
			 * Interval to check if importing customers from file is in progress if it is not in progress
			 * @type {number|null|undefined} If falsy, no interval well be started.
			 */
			checkImportFromFileIntervalSlow: 60000,
			/**
			 * Interval to check if importing customers from file is in progress while it is in progress
			 * @type {number|null|undefined} If falsy, no interval well be started.
			 */
			checkImportFromFileIntervalFast: 5000,
			/**
			 * Interval identifier for interval for checking if importing customers from file is in progress
			 */
			importFromFileInterval: undefined,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.startImportFromFileInterval = this.startImportFromFileInterval.bind(this);
		this.updateImportFromFileProgress = this.updateImportFromFileProgress.bind(this);
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);

		// Popup methods
		this.openMainListItemPopup = this.openMainListItemPopup.bind(this);
		this.closeMainListItemPopup = this.closeMainListItemPopup.bind(this);
		this.handleMainListItemPopupClose = this.handleMainListItemPopupClose.bind(this);
		
		// Dialog methods
		this.openImportFromFileDialog = this.openImportFromFileDialog.bind(this);

		// Action methods
		this.deleteMainListItem = this.deleteMainListItem.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 *
	 * @param {boolean} [override=false] - Flag that determines if this method should be executed in the 'override' mode.
	 * @note Override mode is reserved for calls by the child 'componentDidMount' methods that override this method to
	 * enable overriding the data loading functionality but still executing the base component's 'componentDidMount' that
	 * handles core functionality like adding registered event listeners.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount(override = false) {
		await super.asyncComponentDidMount(override);

		const {fetchCustomerCustomFieldsAction} = this.props;
		
		// Check if customers are being imported from a file
		await this.updateImportFromFileProgress(true);

		// Load customer custom fields for search
		await this.executeAbortableActionMount(fetchCustomerCustomFieldsAction)
			// Load customer custom field into local state
			.then(ccf => this.setState({customerCustomFields: ccf}));
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			.then(() => {
				const prevProjectId = getString(getCurrentProjectIdFromUrl(prevProps.location));
				const projectId = getString(getCurrentProjectIdFromUrl(this.props.location));

				if (prevProjectId !== projectId) {
					const {resetCustomersListAction} = this.props;

					// Clear all page data and load the page again
					resetCustomersListAction();
					this.clear().then(() => this.loadPageData().catch(() => {}));
				}
			});
	}

	componentDidMount() {
		super.componentDidMount();

		// Set the interval to periodically check if customers are being imported from a file
		this.startImportFromFileInterval();
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		// Clear interval that checks if customers are being imported from a file
		clearInterval(this.getOption('importFromFileInterval'));
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Start the interval to periodically check if customers are being imported from a file
	 */
	startImportFromFileInterval() {
		const checkImportFromFileInterval = this.getOption('checkImportFromFileInterval');
		const importFromFileInterval = this.getOption('importFromFileInterval');
		
		// Clear previous interval (if any)
		if (importFromFileInterval) clearInterval(importFromFileInterval);

		// Set the new interval
		if (checkImportFromFileInterval) {
			this.setOption(
				'importFromFileInterval',
				setInterval(() => this.updateImportFromFileProgress(false, true), checkImportFromFileInterval)
			);
		}
	}
	
	/**
	 * Update local state flag for importing customers from file progress
	 * 
	 * @param {boolean} [onMount=false] - Flag that specifies if this method is called as part of the mount cycle.
	 * @param {boolean} [ignoreErrors=false] - Flag that specifies if response errors will be ignored.
	 * @return {Promise<Object>}
	 */
	updateImportFromFileProgress(onMount = false, ignoreErrors = false) {
		const {areCustomersBeingImportedFromFileAction, addDefaultMessageAction} = this.props;
		const {importFromFileInProgress} = this.state;
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);
		
		return executeAbortableAction(areCustomersBeingImportedFromFileAction, ignoreErrors)
			.then(newImportFromFileInProgress => {
				// Extend the interval for checking import process status to a minute or more if checking failed
				if (!isset(newImportFromFileInProgress)) {
					this.setOption('checkImportFromFileInterval', this.getOption('checkImportFromFileIntervalSlow'));
					this.startImportFromFileInterval();
				}
				// Shorten the interval for checking import process status to a few seconds while customers are being 
				// imported to detect when they are done as soon as possible
				else if (newImportFromFileInProgress) {
					this.setOption('checkImportFromFileInterval', this.getOption('checkImportFromFileIntervalFast'));
					this.startImportFromFileInterval();
				}
				// Extend the interval for checking import process status to a minute or more while customers are being 
				// imported to reduce the number of API calls
				else {
					this.setOption('checkImportFromFileInterval', this.getOption('checkImportFromFileIntervalSlow'));
					this.startImportFromFileInterval();
				}
				
				return this.setState({importFromFileInProgress: newImportFromFileInProgress})
					// Handle done processing
					.then(() => {
						if (isset(newImportFromFileInProgress) && !newImportFromFileInProgress && importFromFileInProgress) {
							// Reload main list with current options but without the loading overlay because this should be a 
							// background process
							this.reloadMainList(false).then();
							// Add a done message to notify the user
							addDefaultMessageAction(this.t('import_file_done', 'ImportCustomersFromFile'));
						}
					});
			})
			.catch(e => {
				this.setState({importFromFileInProgress: undefined}).then();
				throw e;
			});
	}
	
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {location, mainList, loadCustomersListAction} = this.props;
		const projectId = getCurrentProjectIdFromUrl(location);

		// If main list was already loaded (user already opened the page before)
		if (isset(mainList)) {
			// Open filter if it is not empty
			if (!this.isMainListFilterEmpty() && this.mainListFilterRef) this.mainListFilterRef.open();
			// Reload main list with currently applied filter, sort and pagination
			return this.reloadMainList()
				.then(res => { if (!isset(res)) throw new AsyncMountError(); });
		}
		// Load main list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableActionMount(loadCustomersListAction, projectId))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 *
	 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay should be visible.
	 * @return {Promise<*>}
	 */
	reloadMainList(loadingOverlay = true) {
		const {location, loadCustomersListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const projectId = getCurrentProjectIdFromUrl(location);
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(
			loadCustomersListAction, projectId, oFilter, pageNo, perPage, sortBy, sortDir, loadingOverlay
		)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {location, loadCustomersListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const projectId = getCurrentProjectIdFromUrl(location);
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadCustomersListAction, projectId, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {location, loadCustomersListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const projectId = getCurrentProjectIdFromUrl(location);
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadCustomersListAction, projectId, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			location, loadCustomersListAction, setCustomersListFilterAction, mainListPagination, mainListSort
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const projectId = getCurrentProjectIdFromUrl(location);
		const oFilter = filterDataMap.output(filter);

		// Set filter so that the change in the filter will be detected after IO
		setCustomersListFilterAction(oFilter);
		
		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(
				loadCustomersListAction, projectId, oFilter, 1, perPage, sortBy, sortDir
			))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(oFilter, true, false)) {
					if (this.mainListFilterRef) {
						this.mainListFilterRef.close()
							.then(() => this.setState({limitToAvailableSpace: true}));
					}
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl(prevLocation) {
		const {location} = this.props;
		const projectId = getCurrentProjectIdFromUrl(location);
		const canEdit = Acl.checkPermission(Acl, projectId, ['CUSTOMER_WRITE']);
			
		if (canEdit) {
			// Open main list item popup if it is not already opened
			// @note This is done to avoid opening multiple dialogs when switching from item edit to new item URL. Dynamic URL
			// component data should be changes using the standard 'updateDynamics' method.
			if (!this.urlComponentGUIID) {
				return this.openMainListItemPopup(true);
			}
			// If URL component is opened, update its dynamic items (tabs, action buttons, ...)
			else {
				const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
				return mainListItemPopup.updateDynamics({isNew: true}).then(() => this.urlComponentGUIID);
			}
		} else {
			this.redirectToBase();
		}
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string|number} id - Item ID.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id, prevLocation) {
		const {loadCustomersItemAction} = this.props;

		// @note This is done to avoid opening multiple dialogs when switching from new item to edit item URL. Dynamic URL
		// component data should be changes using the standard 'updateDynamics' method.
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openMainListItemPopup();
		}
		// If URL component is opened, update its dynamic items (tabs, action buttons, ...)
		else {
			const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
			mainListItemPopup.updateDynamics({isNew: false}).then();
		}

		// Try to load main list item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish
			setTimeout(() => resolve(
				this.executeAbortableAction(loadCustomersItemAction, id)
					.then(mainListItem => {
						// If main list item is successfully loaded
						if (mainListItem) {
							return this.urlComponentGUIID;
						}
						// If main list item could not be loaded (usually if item with ID from URL does not exist)
						else {
							// Close main list item popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes main list item ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its
	 * base URL if such component exists.
	 */
	handleBaseUrl(prevLocation) {
		this.closeMainListItemPopup();
		return '';
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		this.closeMainListItemPopup();
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Open main list item popup
	 * @param {boolean} [isNew=false] - Flag that specifies if new main list item popup should be opened.
	 */
	openMainListItemPopup(isNew = false) {
		const {location, openPopupAction} = this.props;
		const projectId = getCurrentProjectIdFromUrl(location);
		
		return openPopupAction(ItemPopup, {
			projectId,
			isNew,
			onClose: this.handleMainListItemPopupClose,
			deleteItemAction: this.deleteMainListItem,
			redirectToItem: this.redirectToItem,
			redirectToBase: this.redirectToBase,
		});
	}

	/**
	 * Close main list item popup
	 */
	closeMainListItemPopup() {
		const {closePopupAction, clearCustomersItemAction} = this.props;

		// Close item popup
		const urlComponentGUIID = this.getUrlComponentGUIID();
		if (urlComponentGUIID) {
			closePopupAction(urlComponentGUIID);
			this.clearUrlComponentGUIID();
		}

		// Clear popup Redux data
		clearCustomersItemAction();
	}

	/**
	 * Handle main list item popup 'onClose' event
	 * @return {void}
	 */
	handleMainListItemPopupClose() {
		this.clearUrlComponentGUIID();
		this.redirectToBase();
		// Timeout is added to allow for the popup close animation to finish
		setTimeout(this.reloadMainList);
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open the dialog for importing customers form the file
	 */
	openImportFromFileDialog() {
		const {location, openDialogAction} = this.props;
		const projectId = getCurrentProjectIdFromUrl(location);
		
		const dialogGUIID = openDialogAction('', ImportCustomersFromFileDialog, {
			projectIds: [projectId],
			onSuccess: () => {
				this.setState({importFromFileInProgress: true})
					// Check if import is done to quickly update the generating flag if small number of customers are 
					// imported
					.then(() => setTimeout(this.updateImportFromFileProgress, 500))
			}
		}, {
			id: 'import-customers-from-file-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 600
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Delete main list item
	 * 
	 @param {CustomersListItemDataObject} item - Main list popup item.
	 * @return {Promise<void>}
	 */
	deleteMainListItem(item) {
		const {
			openDialogAction, closeDialogAction, deleteCustomersItemAction, addSuccessMessageAction
		} = this.props;
		
		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_delete', '', '', {name: `${item.firstName} ${item.lastName}`}),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteCustomersItemAction, item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.tt('delete_success_msg', 'ItemPopup'));
								// Reload main list data
								// @note This is done asynchronously on purpose because there is no reason to wait for this to 
								// finish before continuing.
								this.reloadMainList()
									// Go to the previous page if there are no table rows after one has been deleted
									.then(() => {
										const mainList = getArray(this.props, 'mainList');
										const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
										if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
									});
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve());
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'item-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 500
			});
			this.setOption(
				'dialogsToCloseOnUnmount', 
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {CustomersListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		const {location} = this.props;
		const projectId = getCurrentProjectIdFromUrl(location);
		const canEdit = Acl.checkPermission(Acl, projectId, ['CUSTOMER_WRITE']);
		
		return (
			<div className="actions standard-actions-row">
				{/*<Tooltip*/}
				{/*	tag="div"*/}
				{/*	title={this.t('delete_tooltip')}*/}
				{/*	size="small"*/}
				{/*	position="top-center"*/}
				{/*	arrow={true}*/}
				{/*	interactive={false}*/}
				{/*>*/}
				{/*	<Button*/}
				{/*		className="action-btn hover-as-action"*/}
				{/*		displayStyle={BUTTON_STYLE.DEFAULT}*/}
				{/*		displayType={BUTTON_DISPLAY_TYPE.NONE}*/}
				{/*		icon={icon_font_delete_symbol}*/}
				{/*		onClick={() => this.deleteMainListItem(row)}*/}
				{/*	/>*/}
				{/*</Tooltip>*/}

				<Tooltip
					tag="div"
					title={canEdit ? this.t('edit_tooltip') : this.t('view_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={canEdit ? icon_font_edit_symbol : icon_font_info_circle_symbol}
						onClick={() => this.redirectToItem(row.id)}
					/>
				</Tooltip>
			</div>
		);
	}

	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {location, projectList, singleProject} = this.props;
		const {title} = this.state;
		/** @type {ProjectListItemDataObject} */
		const currentProject = getCurrentProjectFromUrl(projectList, location);
		const canEdit = (!!currentProject ? Acl.checkPermission(Acl, currentProject.id, ['CUSTOMER_WRITE']) : false);

		return (
			<h1 className={`page-title with-actions ${!canEdit ? 'reload-only' : ''}`}>
				<div className="content">
					{singleProject ?
						<Label content={(title ? this.translate(title, this.titlePathPrefix) : '')} />
						:
						<Breadcrumbs
							includeHome={false}
							items={[
								{
									to: this.getProjectTo(),
									label: getString(currentProject, 'name')
								},
								{
									label: (title ? this.translate(title, this.titlePathPrefix) : '')
								}
							]}
						/>
					}
				</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={() => this.reloadMainList()}
							/>
						</Tooltip>
					</div>

					{canEdit ?
						<>
							<div className="action-button xs-flex-1">
								<Button
									big={true}
									icon="level-down"
									label={this.t('add_from_file')}
									displayType={BUTTON_DISPLAY_TYPE.SOLID}
									displayStyle={BUTTON_STYLE.ACTION}
									onClick={() => this.openImportFromFileDialog()}
								/>
							</div>

							<div className="action-button main-button">
								<Link to={this.getCreateRedirectTo()} className="button action solid big">
									<Label icon={icon_font_create_symbol} content={this.t('create_new')}/>
								</Link>
							</div>
						</>
						: null
					}
				</div>
			</h1>
		);
	}

	render() {
		const {
			mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank, toggleMainSidebarSizeAction,
			location, 
		} = this.props;
		const {limitToAvailableSpace, importFromFileInProgress, customerCustomFields} = this.state;
		const projectId = getCurrentProjectIdFromUrl(location);

		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				{
					!isset(importFromFileInProgress) ?
						<>
							<div className="notice warning">
								<Label icon={icon_font_error_symbol} content={this.t('import_error')} supportHtml={true} />
							</div>
							<br />
						</>
					: importFromFileInProgress ?
						<>
							<div className="notice">
								<Spinner color="var(--highlight-color)" size={12} weight={6} linear={true} slow={true} />
								&nbsp;&nbsp;
								<Label content={this.t('import_in_progress')} supportHtml={true} />
							</div>
							<br />
						</>
					: null
				}

				{/*<div className={`toolbar standard big for-table`}>*/}
				{/*	<Button*/}
				{/*		icon="user-plus"*/}
				{/*		label={this.t('add_from_file')}*/}
				{/*		displayType={BUTTON_DISPLAY_TYPE.NONE}*/}
				{/*		onClick={() => this.openImportFromFileDialog()}*/}
				{/*	/>*/}
				{/*</div>*/}

				<SimpleStaticSearch
					styleName="compact"
					className="main-search rows-4"
					defaultCollapse={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject(
							'externalCrmId',
							this.t('crmIdField')
						),
						new SimpleStaticSearchOptionObject(
							'firstName',
							this.t('firstNameField')
						),
						new SimpleStaticSearchOptionObject(
							'lastName',
							this.t('lastNameField')
						),
						new SimpleStaticSearchOptionObject(
							'dateOfBirthFrom',
							this.t('dateOfBirthFromField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateOfBirthTo',
							this.t('dateOfBirthToField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
							}
						),
						new SimpleStaticSearchOptionObject(
							'mobilePhoneNumber',
							this.t('phoneField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
							{disableDropdown: false},
							PhoneInput,
							undefined,
							''
						),
						new SimpleStaticSearchOptionObject(
							'email',
							this.t('emailField')
						),
						new SimpleStaticSearchOptionObject(
							'customerCustomFields',
							'',
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
							{options: getArray(customerCustomFields).map(i => ({label: i.name, value: i.name}))},
							CustomerCustomFieldFilter,
							{
								labelPosition: FORM_FIELD_LABEL_POSITION.NONE,
								inputClassName: 'customer-custom-field-item custom-field-item'
							}
						),
					]}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('Search', 'general')} />)}
					titleToolbar={
						<AdvancedDropdown
							defaultPosition={ADVANCED_DROPDOWN_POSITION.LEFT}
							boundRect={document.querySelector('body').getBoundingClientRect()}
							label={<Button
								big={true}
								icon="download"
								tooltip={this.t('downloadBtnTooltip')}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
							/>}
							stopPropagation={true}
						>
							<DownloadButton
								className="dropdown-item"
								icon="download"
								label={this.t('download_without_customer_custom_field')}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								displayStyle={BUTTON_STYLE.ACTION}
								url={getIOUrl(
									'defaultAuthorizedApi',
									`customer/export-customers`
								)}
								filename={this.t('download_filename_no_custom_filed', '', '', {
									date: getDateString(new Date(), 'yyyy-MM-dd-HH-mm-ss'),
									ext: '.csv',
								})}
								data={{
									fetchCustomFields: false,
									query: "",
									filter: {
										...filterDataMap.output(mainListFilter),
										projectIds: [projectId],
									},
									...omit(mainListPagination, ['isLastPage', 'totalRows']),
									...mainListSort,
								}}
								method="POST"
								onClick={() => this.setState({downloading: true})}
								onDownload={() => this.setState({downloading: false})}
								onDownloadError={() => {
									showErrorMessage(this.t('download_error'));
									return this.setState({downloading: false});
								}}
							/>
							<DownloadButton
								className="dropdown-item"
								icon="download"
								label={this.t('download_with_customer_custom_field')}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								displayStyle={BUTTON_STYLE.ACTION}
								url={getIOUrl(
									'defaultAuthorizedApi',
									`customer/export-customers`
								)}
								filename={this.t('download_filename_custom_filed', '', '', {
									date: getDateString(new Date(), 'yyyy-MM-dd-HH-mm-ss'),
									ext: '.csv',
								})}
								data={{
									projectIds: [projectId],
									fetchCustomFields: true,
									query: "",
									filter: {
										...filterDataMap.output(mainListFilter),
										projectIds: [projectId],
									},
									...omit(mainListPagination, ['isLastPage', 'totalRows']),
									...mainListSort,
								}}
								method="POST"
								onClick={() => this.setState({downloading: true})}
								onDownload={() => this.setState({downloading: false})}
								onDownloadError={() => {
									showErrorMessage(this.t('download_error'));
									return this.setState({downloading: false});
								}}
							/>
						</AdvancedDropdown>
					}
					applied={!this.isMainListFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					onToggle={visible => this.setState({limitToAvailableSpace: visible})}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				<DataTable
					id="main-page-table"
					className="standard sticky-last-column"
					responsive={true}
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={[
						{
							name: 'crmId',
							label: this.t('crmIdField'),
							dataTypeOptions: new DataTableCellTextTypeOptionsDataObject({
								whiteSpace: 'nowrap',
							}),
							defaultValue: '—',
							width: 1,
						},
						{
							name: 'firstName',
							sortName: 'firstName',
							label: this.t('firstNameField')
						},
						{
							name: 'lastName',
							sortName: 'lastName',
							label: this.t('lastNameField'),
						},
						{
							name: 'dateOfBirth',
							sortName: 'dateOfBirth',
							label: this.t('dateOfBirthField'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
								outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.SHORT),
								whiteSpace: 'nowrap',
							}),
							defaultValue: '—',
						},
						{
							name: 'phone',
							label: this.t('phoneField'),
							defaultValue: '—',
						},
						{
							name: 'email',
							label: this.t('emailField'),
							defaultValue: '—',
						},
						
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: {
								standardWrapper: false,
								content: this.renderActions,
							},
							stopPropagation: true,
							width: 1,
						}
					]}
					onRowClick={data => this.redirectToItem(data.id)}
					data={mainList}
					paginationType={PAGINATION_TYPE.DYNAMIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					{...mainListPagination}
					{...mainListSort}
				/>
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(
	mapStateToProps, getPageActions(actions, {fetchCustomerCustomFieldsAction})
)(CustomersPage);