import formStyles from '../../index.module.css';

import React from "react";
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from "prop-types";
import Label from 'Core/components/display/Label';
import {FORM_FIELD_LABEL_POSITION} from 'Core/components/advanced/FormWrapper/FormField';
import {FormField} from 'Core/components/advanced/FormWrapper';
import {FORM_QUESTION_INPUT_TYPE} from 'Pages/site/form/const';
import FormRadioInput from 'Pages/site/form/inputs/FormRadioInput';
import FormTextInput from 'Pages/site/form/inputs/FormTextInput';
import FormTextareaInput from 'Pages/site/form/inputs/FormTextareaInput';
import FormPasswordInput from 'Pages/site/form/inputs/FormPasswordInput';
import FormEmailInput from 'Pages/site/form/inputs/FormEmailInput';
import FormCheckboxInput from 'Pages/site/form/inputs/FormCheckboxInput';

class FormQuestionElement extends BaseComponent {
	constructor(props) {
		super(props, {
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onChange'],
		});
	}
	
	render() {
		const {value, errors, onChange} = this.props;
		/** @type {FormQuestionDataObject} */
		const data = this.getProp('data');
		
		// Get input component based on input type
		let InputComponent = null;
		switch (data.inputType) {
			case FORM_QUESTION_INPUT_TYPE.TEXT: InputComponent = FormTextInput; break;
			case FORM_QUESTION_INPUT_TYPE.TEXTAREA: InputComponent = FormTextareaInput; break;
			case FORM_QUESTION_INPUT_TYPE.PASSWORD: InputComponent = FormPasswordInput; break;
			case FORM_QUESTION_INPUT_TYPE.RADIOBUTTON: InputComponent = FormRadioInput; break;
			case FORM_QUESTION_INPUT_TYPE.CHECKBOX: InputComponent = FormCheckboxInput; break;
			case FORM_QUESTION_INPUT_TYPE.EMAIL: InputComponent = FormEmailInput; break;
			// no default
		}
		
		return (
			<FormField
				className={`${formStyles['element']}`}
				required={data.mandatory}
				label={data.title}
				labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
				errorMessages={errors}
			>
				{!!data.description ?
					<Label
						element="div"
						elementProps={{className: `${formStyles['elementDescription']}`}}
						content={data.description}
					/>
					: null
				}
				<InputComponent data={data} value={value} onChange={onChange} />
			</FormField>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FormQuestionElement.propTypes = {
	// Question form element data
	// @type {FormQuestionDataObject}
	data: PropTypes.object,
	// Selected value (usually by the user) that will be submitted
	value: PropTypes.any,
	// Validation errors
	// @type {string[]}
	errors: PropTypes.array,
	// Event triggered when input component value is changed (usually by the user)
	// @type {function(field: string|string[], value: any)}
	onChange: PropTypes.func,
};

/**
 * Define component default values for own props
 */
FormQuestionElement.defaultProps = {
	errors: [],
};

export default FormQuestionElement;