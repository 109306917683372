import {hideLoading, hideLoadingFunction, showContentLoading} from 'Core/helpers/loading';
import {ioJsonManualAction} from 'Core/store/actions/io';
import * as formDataMap from './dataMap/form';

/**
 * Fetch input (survey) form data using a specified token
 * @note Token should be passed with the URL param.
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} token - JWT token identifying a specific input form.
 * @return {function(*): Promise<any>}
 * @throws {StandardJsonResponseError|DOMException} DOMException is for AbortError.
 */
export const fetchFormAction = (abortCallback, token) => dispatch => {
	const loading = showContentLoading();
	return ioJsonManualAction(
		abortCallback,
		'defaultApi',
		`fetch-input-form?token=${token}`,
		null,
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => formDataMap.input(res.data))
		.catch(error => {
			hideLoading(loading);
			throw error;
		});
};

/**
 * Submit the input (survey) form
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} token - JWT token identifying a specific input form.
 * @param {Object<string, any>} data - Data to submit where key is a DB ID of question and value is the user answer.
 * @param {FormQuestionDataObject[]} questions - Input form questions specification.
 * @return {function(*): Promise<FormDataObject>}
 * @throws {StandardJsonResponseError|DOMException} DOMException is for AbortError.
 */
export const submitFormAction = (abortCallback, token, data, questions) => dispatch => {
	const loading = showContentLoading();
	return ioJsonManualAction(
		// @note abortCallback is set to undefined because submitting the input form should not be cancelable.
		undefined,
		'defaultApi',
		'create-completed-input-form',
		{token, formData: {id: null, inputFormId: null, customerId: null, questions: formDataMap.output(data,questions)}},
		hideLoadingFunction(loading),
	)(dispatch)
		.catch(error => {
			hideLoading(loading);
			throw error;
		});
};