/**
 * Data object representing the input form that will be shown to the user on a public page
 */
export class FormDataObject {
	/**
	 * @param {string} id - Input form DB ID.
	 * @param {string} title - Input form title.
	 * @param {string} description - Input form description.
	 * @param {FormPageDataObject[]} pages - Input form pages.
	 */
	constructor(id, title, description, pages) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.pages = pages;
	}
}

/**
 * Data object representing input form page
 * @description Input form consists of one or more pages.
 */
export class FormPageDataObject {
	/**
	 * @param {string} id - Input form page DB ID.
	 * @param {number} ordinal - Order of the page in the form.
	 * @param {FormElementDataObject[]} elements - Input form elements on the page.
	 */
	constructor(id, ordinal, elements) {
		this.id = id;
		this.ordinal = ordinal;
		this.elements = elements;
	}
}

/**
 * Abstract data object representing input form page element
 * @description Actual element can be one of the data object extending this data object. 
 * @note There should be as many data objects that extend this one as there are FormElementTypes.
 * @see FormQuestionDataObject
 * @see FormQuestionAnswerDataObject
 */
export class FormElementDataObject {
	/**
	 * @param {string} id - Input form element DB ID. 
	 * @param {FormElementType} type - Type of the input form element. 
	 * @param {number} ordinal - Order of the element on the page.
	 */
	constructor(id, type, ordinal) {
		this.id = id;
		this.type = type;
		this.ordinal = ordinal;
	}
}

/**
 * Data object for an input form element that is a question that a customer should answer
 * @extends FormElementDataObject
 */
export class FormQuestionDataObject extends FormElementDataObject {
	/**
	 * @param {string} id - Input form element DB ID.
	 * @param {FormElementType} type - Type of the input form element.
	 * @param {number} ordinal - Order of the element on the page.
	 * @param {string} title - Question title.
	 * @param {string} description - Question description.
	 * @param {FormQuestionInputType} inputType - Defines the input field that will be rendered for this question.
	 * @param {boolean} mandatory - Flag that specifies if question is required.
	 * @param {FormQuestionAnswerDataObject[]} answers - Possible answers to this question.
	 */
	constructor(id, type, ordinal, title, description, inputType, mandatory, answers) {
		super(id, type, ordinal);
		
		this.title = title;
		this.description = description;
		this.inputType = inputType;
		this.mandatory = mandatory;
		this.answers = answers;
	}
}

/**
 * Data object for a possible answer to the input form question that customer should answer 
 * @note This is used in case when question is not a free input.
 */
export class FormQuestionAnswerDataObject {
	/**
	 * @param {string} id - DB ID of the possible answer to a question. 
	 * @param {number} ordinal - Ordinal of this answer in the list of all possible answers to the same question.
	 * @param {string} content - text of the answer that will be displayed to the user.
	 * @param {string} value - Value of the answer that will be submitted.
	 */
	constructor(id, ordinal, content, value) {
		this.id = id;
		this.ordinal = ordinal;
		this.content = content;
		this.value = value;
	}
}

/**
 * Data object representing an HTML block, or a simple text, that should be displayed on the page of the input form
 * @extends FormElementDataObject
 */
export class FormHtmlTextDataObject extends FormElementDataObject {
	/**
	 * @param {string} id - Input form element DB ID.
	 * @param {FormElementType} type - Type of the input form element.
	 * @param {number} ordinal - Order of the element on the page.
	 * @param {boolean} html - Flag that specifies that the content is in HTML format.
	 * @param {string} content - Content of this html block (or text).
	 * @param {string} elementId - Input form element that is a sort of placeholder for this text and defines its 
	 * position on the input form page.
	 * @param {string} clientId - Client that this question belongs to.
	 */
	constructor(id, type, ordinal, html, content, elementId, clientId) {
		super(id, type, ordinal);
		
		this.html = html;
		this.content = content;
		this.elementId = elementId;
		this.clientId = clientId;
	}
}