import "./index.css";

import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getArray, getObject, getString, isset, trimArray} from 'Core/helpers/data';
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {Tooltip} from "Core/components/global/Tooltip";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	DynamicPropFunction,
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from 'Core/components/advanced/SimpleStaticSearch';
import DataTable, {
	DATA_TABLE_CELL_TYPE,
	DATA_TABLE_CELL_TYPE_BOOL, DATA_TABLE_CELL_TYPE_DATE,
	DATA_TABLE_CELL_TYPE_NUMBER
} from 'Core/components/advanced/DataTable';
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {AsyncMountError} from "Core/errors";
import {
	DataTableCellAnyTypeOptionsDataObject,
	DataTableCellBoolTypeOptionsDataObject,
	DataTableCellDateTypeOptionsDataObject,
	DataTableCellNumberTypeOptionsDataObject,
	DataTableCellTemplateTypeOptionsDataObject,
	DataTableCellTextTypeOptionsDataObject
} from 'Core/components/advanced/DataTable/DataTableCell/dataObjects';
import {
	CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS,
	CAMPAIGN_MESSAGE_CONTENT_TYPES
} from 'Pages/apps/default/projectPages/campaign/const';
import Icon from 'Core/components/display/Icon';
import {getAppLocaleDateFormat, getAppLocaleDatetimeFormat} from 'Core/helpers/locale';
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from 'Core/const/locale';
import ProjectSelectInput from 'Components/input/ProjectSelectInput';
import IconOptionComponent from 'Core/components/input/SelectInput/options/IconOption';
import IconSingleValueComponent from 'Core/components/input/SelectInput/singleValues/IconSingleValue';
import {SelectDynamicValueFunction} from 'Core/components/input/SelectInput';
import {STANDARD_DATE_TIME_FORMAT} from 'Core/const/datetime';
import {
	CUSTOMER_MESSAGE_STATUS_CLASS_MAP,
	CUSTOMER_MESSAGE_STATUSES, CUSTOMER_MESSAGE_SUB_STATUS_BY_MESSAGE_TYPE,
	CUSTOMER_MESSAGE_SUB_STATUSES
} from 'Pages/apps/default/const';
import ChannelSelectInput from 'Components/input/ChannelSelectInput';
import NotificationSourceDialog from 'Components/dialogs/NotificationSourceDialog';
import NotificationPreviewDialog from 'Components/dialogs/NotificationPreviewDialog';
import {pick} from 'lodash';
import {endOfDay, endOfWeek, startOfDay, startOfWeek} from 'date-fns';
import SenderSelectInput from 'Components/input/SenderSelectInput';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainList: selectors[reducerStoreKey].getMessageDeliveriesList(state),
	mainListPagination: selectors[reducerStoreKey].getMessageDeliveriesListPagination(state),
	mainListSort: selectors[reducerStoreKey].getMessageDeliveriesListSort(state),
	mainListFilter: selectors[reducerStoreKey].getMessageDeliveriesListFilter(state),
	singleProject: selectors['projectSelector'].getSingleProject(state),
});

class MessageDeliveriesPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: false
		}, {
			domPrefix: 'message-deliveries-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.getFilterOutputData = this.getFilterOutputData.bind(this);
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);

		// Dialog methods
		this.openContentPreviewDialog = this.openContentPreviewDialog.bind(this);
		this.openContentSourceDialog = this.openContentSourceDialog.bind(this);
		
		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {mainList, loadMessageDeliveriesListAction} = this.props;

		// If main list was already loaded (user already opened the page before)
		if (isset(mainList)) {
			// Open filter if it is not empty
			if (!this.isMainListFilterEmpty() && this.mainListFilterRef) this.mainListFilterRef.open();
			// Reload main list with currently applied filter, sort and pagination
			return this.reloadMainList()
				.then(res => { if (!isset(res)) throw new AsyncMountError(); })
				.then(() => setTimeout(() => scrollToSelector('#main-page-table', false, 80)));
		}
		// Load main list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableActionMount(loadMessageDeliveriesListAction, this.getFilterOutputData()))
				.then(() => this.setValue('loading', false))
				.then(() => setTimeout(() => scrollToSelector('#main-page-table', false, 80)));
		}
	}

	/**
	 * Get filter data mapped for output
	 * @note This method adds default filters.
	 *
	 * @param {?Object} [filter] - Filter to map for output.
	 * @return {*}
	 */
	getFilterOutputData(filter) {
		return filterDataMap.output(
			areAllObjectPropsEmpty(pick(filter, ['scheduledDateFrom', 'scheduledDateTo']), true, true, false) ?
				// Add default scheduled date filter values since we must always have at least one date related filter 
				// applied for performance reasons (API returns an error otherwise!)
				{...getObject(filter), scheduledDateFrom: startOfDay(new Date()), scheduledDateTo: endOfDay(new Date())} :
				filter
		);
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadMessageDeliveriesListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = this.getFilterOutputData(mainListFilter);

		return this.executeAbortableAction(loadMessageDeliveriesListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadMessageDeliveriesListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = this.getFilterOutputData(mainListFilter);

		return this.executeAbortableAction(loadMessageDeliveriesListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadMessageDeliveriesListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = this.getFilterOutputData(mainListFilter);

		return this.executeAbortableAction(loadMessageDeliveriesListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			loadMessageDeliveriesListAction, setMessageDeliveriesFilterAction, mainListPagination, mainListSort,
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = this.getFilterOutputData(filter);
		
		// Set filter so that the change will be detected after IO
		setMessageDeliveriesFilterAction(filter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadMessageDeliveriesListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(oFilter, true, false)) {
					if (this.mainListFilterRef) {
						this.mainListFilterRef.close()
							.then(() => this.setState({limitToAvailableSpace: true}));
					}
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open the notification content preview dialog with actual content that was sent to the recipient (personalized)
	 * @param {MessagesListItemDeliveryDataObject} row
	 */
	openContentPreviewDialog(row) {
		const {openDialogAction} = this.props;

		this.contentDialogGUIID = openDialogAction('', NotificationPreviewDialog, {
			data: row,
		}, {
			id: 'notification-preview-dialog',
			className: `notification-type-${row.messageType} bordered-title`,
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 'max-content',
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), this.contentDialogGUIID], 'left')
		);
	}

	/**
	 * Open the notification content source data (JSON) preview dialog
	 * @param {MessageContentDataObject} messageSourceData
	 */
	openContentSourceDialog(messageSourceData) {
		const {openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', NotificationSourceDialog, {
			data: messageSourceData,
		}, {
			id: 'notification-source-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 800,
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;

		return (
			<h1 className="page-title with-actions reload-only">
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					<div className="action-button main-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
							touchHold={true}
							delay={250}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadMainList}
							/>
						</Tooltip>
					</div>
				</div>
			</h1>
		);
	}

	/**
	 * Render data table actions cell
	 * @param {MessagesListItemDeliveryDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions standard-actions-row">
				<Tooltip
					tag="div"
					title={this.t('source_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon="code"
						onClick={() => this.openContentSourceDialog(row.messageSourceData)}
					/>
				</Tooltip>
				<Tooltip
					tag="div"
					title={this.t('content_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon="file-text-o"
						onClick={() => this.openContentPreviewDialog(row)}
					/>
				</Tooltip>
			</div>
		);
	}
	
	render() {
		const {
			isMobileBreakpoint, mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank, 
			toggleMainSidebarSizeAction, singleProject
		} = this.props;
		const {limitToAvailableSpace} = this.state;

		let filterOptions = [
			new SimpleStaticSearchOptionObject(
				'messageType',
				this.t('messageTypeField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
				{
					isClearable: true,
					options: CAMPAIGN_MESSAGE_CONTENT_TYPES
						.map(t => ({label: this.t(t, 'constants.messageType'), value: t})),
					components: {Option: IconOptionComponent, SingleValue: IconSingleValueComponent},
					labelProps: {
						symbolPrefix: new SelectDynamicValueFunction(data =>
							getString(CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS, [data.value, 'symbolPrefix'])
						),
						symbol: new SelectDynamicValueFunction(data =>
							getString(CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS, [data.value, 'symbol'])
						),
					},
					singleValueProps: {
						symbolPrefix: new SelectDynamicValueFunction(data =>
							getString(CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS, [data.value, 'symbolPrefix'])
						),
						symbol: new SelectDynamicValueFunction(data =>
							getString(CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS, [data.value, 'symbol'])
						),
					}
				}
			),
			new SimpleStaticSearchOptionObject(
				'deliveryChannelId',
				this.t('deliveryChannelIdField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
				{
					isClearable: true,
					messageType: new DynamicPropFunction(f => getString(f, 'messageType', null, true)),
					projectId: new DynamicPropFunction(f => getString(f, 'projectId', null, true)),
				},
				ChannelSelectInput,
				undefined,
				undefined,
				'messageType'
			),
			new SimpleStaticSearchOptionObject(
				'recipient',
				this.t('recipientField'),
			),
			new SimpleStaticSearchOptionObject(
				'channelSender',
				this.t('channelSenderField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
				{
					isClearable: true,
					projectId: new DynamicPropFunction(currentFilter => getString(currentFilter, 'projectId')),
					messageType: new DynamicPropFunction(currentFilter => getString(currentFilter, 'messageType')),
				},
				SenderSelectInput,
			),
			new SimpleStaticSearchOptionObject(
				'externalMessageId',
				this.t('externalIdField'),
			),
			new SimpleStaticSearchOptionObject(
				'messageStatus',
				this.t('messageStatusField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
				{
					isClearable: true,
					options: CUSTOMER_MESSAGE_STATUSES.map(i => ({
						label: this.t(i, 'constants.customerMessageStatus'),
						value: i
					})),
				}
			),
			new SimpleStaticSearchOptionObject(
				'messageSubStatus',
				this.t('messageSubStatusField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
				{
					isClearable: true,
					options: new DynamicPropFunction(currentFilter => 
						CUSTOMER_MESSAGE_SUB_STATUSES.map(i => ({
							label: (
								!currentFilter.messageType ?
									this.t(i, 'constants.customerMessageSubStatusWithType') :
									this.t(i, 'constants.customerMessageSubStatus')
							),
							value: i
						})).filter(i =>
							!currentFilter.messageType ||
							getArray(CUSTOMER_MESSAGE_SUB_STATUS_BY_MESSAGE_TYPE, currentFilter.messageType).includes(i.value)
						)
					),
				}, 
				undefined,
				undefined,
				null,
				'messageType'
			),
			new SimpleStaticSearchOptionObject(
				'scheduledDateFrom',
				this.t('scheduledDateFromField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATETIME,
				{
					valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
				},
			),
			new SimpleStaticSearchOptionObject(
				'scheduledDateTo',
				this.t('scheduledDateToField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATETIME,
				{
					valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
				},
			),
		];
		if (!singleProject) {
			filterOptions.unshift(new SimpleStaticSearchOptionObject(
				'projectId',
				this.t('projectIdField'),
				SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
				{
					isClearable: true,
					useGlobalProjectList: true,
					autoSelectSingleProject: true,
				},
				ProjectSelectInput,
			));
		}
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<SimpleStaticSearch
					styleName="compact"
					className={`main-search rows-5`}
					defaultCollapse={false}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={filterOptions}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('Search', 'general')} />)}
					applied={!this.isMainListFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					showAdditionalToolbar={true}
					additionalToolbarContent={_sss => (
						<div className="additional-search-buttons">
							<Button
								icon="calendar"
								label={this.t('scheduledTodaySearch')}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={() => {
									_sss.setState(prevState => ({
										data: {
											...prevState.data,
											scheduledDateFrom: startOfDay(new Date()),
											scheduledDateTo: endOfDay(new Date()),
										}
									}))
										.then(() => _sss.update());
								}}
							/>
							<Button
								icon="calendar"
								label={this.t('scheduledThisWeekSearch')}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={() => {
									_sss.setState(prevState => ({
										data: {
											...prevState.data,
											scheduledDateFrom: startOfWeek(new Date(), {weekStartsOn: 1}),
											scheduledDateTo: endOfWeek(new Date(), {weekStartsOn: 1}),
										}
									}))
										.then(() => _sss.update());
								}}
							/>
						</div>
					)}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					onToggle={visible => this.setState({limitToAvailableSpace: visible})}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				<DataTable
					id="main-page-table"
					className="standard sticky-last-column lh-1-2"
					responsive={true}
					responsiveBreakpointName="bp-m"
					limitToAvailableSpace={limitToAvailableSpace && !isMobileBreakpoint}
					highlightOnHover={true}
					columns={[
						{
							name: 'messageType',
							label: this.t('messageTypeColumn'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content: row => (
									<div className="text-center">
										{!!getString(CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS, row.messageType) ?
											<div style={{fontSize: '1.5em'}}>
												{!!CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS[row.messageType] ?
													<Icon {...CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS[row.messageType]} /> :
													<Icon symbol="bell" />
												}
											</div>
											:
											null
										}
										<Label 
											element="div"
											elementProps={{className: 'no-wrap'}}
											content={this.t(row.messageType, 'constants.messageTypeShort')} 
										/>
									</div>
								)
							}),
							defaultValue: '—',
							emptyAsDefault: true,
							width: 1,
						},
						{
							name: 'recipient',
							label: this.t('recipientColumn'),
							dataType: DATA_TABLE_CELL_TYPE.TEMPLATE,
							dataTypeOptions: new DataTableCellTemplateTypeOptionsDataObject({
								template: '<strong class="recipient-cell">{$recipient}</strong>',
								supportHtml: true,
							}),
							defaultValue: '—',
							emptyAsDefault: true,
							width: 1
						},
						{
							name: 'sender',
							label: this.t('senderColumn'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content: row => (
									!!row.sender ?
										<>{row.channelName} {row.sender} {!!row.senderName ? ` (${row.senderName})` : ''}</> :
										'—'
								),
							}),
						},
						{
							name: 'country',
							label: this.t('countryColumn'),
							defaultValue: '—',
							emptyAsDefault: true,
							widthLessThanLabel: true,
						},
						{
							name: 'externalId',
							label: this.t('externalIdColumn'),
							defaultValue: '—',
							emptyAsDefault: true,
							width: 1
						},
						{
							name: 'messagePartsCount',
							label: this.t('messagePartsCountColumn'),
							dataType: DATA_TABLE_CELL_TYPE_NUMBER,
							dataTypeOptions: new DataTableCellNumberTypeOptionsDataObject({
								alignContent: 'center',
							}),
							defaultValue: '—',
							emptyAsDefault: true,
							width: 1,
						},
						{
							name: 'fallbackUsed',
							label: this.t('fallbackUsedColumn'),
							dataType: DATA_TABLE_CELL_TYPE_BOOL,
							dataTypeOptions: new DataTableCellBoolTypeOptionsDataObject({
								alignContent: 'center',
								trueLabel: `<i class="fa fa-check"></i>`,
								falseLabel: `<i class="fa fa-times"></i>`,
								supportHtml: true,
							}),
							defaultValue: '—',
							width: 1,
							widthLessThanLabel: true,
						},
						{
							name: 'status',
							label: this.t('statusColumn'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content: row => (
									!!row.status ?
										<Label
											element="span"
											elementProps={{
												className: `status no-wrap tag ${CUSTOMER_MESSAGE_STATUS_CLASS_MAP[row.status]}`
											}}
											content={this.t(row.status, 'constants.customerMessageStatus')}
											help={this.t(row.status, 'constants.customerMessageStatusDescription')}
										/>
										:
										'—'
								),
							}),
						},
						{
							name: 'subStatus',
							label: this.t('subStatusColumn'),
							dataTypeOptions: new DataTableCellTextTypeOptionsDataObject({
								translatePath: 'constants.customerMessageSubStatus',
								whiteSpace: 'nowrap',
							}),
							defaultValue: '—',
							emptyAsDefault: true,
						},
						{
							name: 'creationDate',
							label: this.t('creationDateColumn'),
							dataType: DATA_TABLE_CELL_TYPE_DATE,
							dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
								outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT_NAME.SHORT),
								whiteSpace: 'nowrap'
							}),
							defaultValue: '—',
							emptyAsDefault: true,
							width: 1
						},
						{
							name: 'sentToProviderDate',
							label: this.t('sentToProviderDateColumn'),
							dataType: DATA_TABLE_CELL_TYPE_DATE,
							dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
								outputFormat: getAppLocaleDatetimeFormat(
									LOCALE_DATE_FORMAT_NAME.SHORT,
									LOCALE_TIME_FORMAT_NAME.STANDARD
								),
							}),
							defaultValue: '—',
							emptyAsDefault: true,
							width: 1,
							widthLessThanLabel: true,
						},
						{
							name: 'scheduledDate',
							label: this.t('scheduledDateColumn'),
							dataType: DATA_TABLE_CELL_TYPE_DATE,
							dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
								outputFormat: getAppLocaleDatetimeFormat(
									LOCALE_DATE_FORMAT_NAME.SHORT,
									LOCALE_TIME_FORMAT_NAME.STANDARD
								),
							}),
							defaultValue: '—',
							emptyAsDefault: true,
							width: 1,
							widthLessThanLabel: true,
						},

						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								standardWrapper: false,
								content: this.renderActions
							}),
							stopPropagation: true,
							width: 1
						}
					]}
					data={mainList}
					paginationType={PAGINATION_TYPE.DYNAMIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					{...mainListPagination}
					{...mainListSort}
				/>
			</div>
		), (!singleProject ? 'no-project-selector' : undefined), undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(MessageDeliveriesPage);