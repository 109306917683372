import {getArray, getString, isset} from 'Core/helpers/data';
import {input} from 'Tags/dataMap';
import {ioJsonAction, ioJsonFetchAction} from 'Core/store/actions/io';
import {isCustomFieldTag, getTagName, isFormTag} from 'Tags/helpers';
import {fetchCustomerCustomFieldsListAction} from 'Pages/apps/default/customerCustomFields/actions';
import {find, get} from 'lodash';
import {tag_type_prefix_custom_field, tag_type_prefix_input_form} from 'Config/app';
import {MESSAGE_DEFINITION_STATUS} from 'Pages/apps/default/projectPages/campaign/const';
import {isSuccessful} from 'Core/helpers/io';
import * as formDataMap from 'Pages/site/form/dataMap/form';

/**
 * Fetch tags that can be used for customer message personalization
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {CampaignMessageContentType} [messageType] - Message type to get tags for.
 * @param {?string} [clientId=null] - DB ID of the client (used by super admin).
 * @param {boolean} [useUndefined] - Flag that specifies if undefined will be returned if IO response is undefined (IO 
 * error or request was aborted). 
 * @param {?string} [projectId=null] - DB ID of the project.
 * @return {function(*): Promise<TagDataObject[]>}
 */
export const fetchTagsAction = (
	abortCallback, messageType, clientId = null, useUndefined, projectId = null
) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/fetch-possible-tags-for-message-type',
		{messageType, clientId, projectId},
	)(dispatch)
		// Add tag description and data type properties for customer custom field tags
		.then(async res => {
			let tags = getArray(res, 'data');
			
			// Fetch additional tag data for specific tag types
			if (tags.length > 0) {
				// Fetch description and data type for customer custom filed tags
				if (tags.some(tag => isCustomFieldTag(getString(tag, 'tag')))) {
					const customFieldsRes = await fetchCustomerCustomFieldsListAction(
						abortCallback, undefined, 1, 9999999
					)(dispatch);
					/** @type {CustomerCustomFieldsListItemDataObject[]} */
					const customFields = getArray(customFieldsRes, 'data');

					tags = tags.map(tag => {
						/** @type {CustomerCustomFieldsListItemDataObject} */
						const customField = find(customFields, {name: getTagName(tag?.tag, tag_type_prefix_custom_field)});
						return ({
							...tag,
							tagDescription: get(customField, 'description'),
							tagDataType: get(customField, 'dataType')
						});
					});
				}
				
				// Fetch name and description for input form tags
				if (tags.some(tag => isFormTag(getString(tag, 'tag')))) {
					/** @type {FormDataObject[]} */
					const forms = await fetchInputFormListAction(abortCallback, projectId)(dispatch);
					
					tags = tags.map(tag => {
						/** @type {FormDataObject} */
						const form = find(forms, {id: getTagName(tag?.tag, tag_type_prefix_input_form)});
						return ({
							...tag,
							tagTitle: `[${get(form, 'id')}] ${get(form, 'title')}`,
							tagDescription: get(form, 'description'),
						});
					});
				}
			}
			
			return (useUndefined && !isset(res) ? undefined : tags);
		})
		// Map external data to local data
		.then(tags => useUndefined && !isset(tags) ? undefined : tags.map(i => input(i)));
};

/**
 * Fetch the list of input forms for a specific project and campaign status
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} projectId - DB ID of the project.
 * @param {MessageDefinitionStatus} [status='PUBLISHED'] - Campaign status. 
 * @return {function(*): Promise<FormDataObject[]>}
 */
export const fetchInputFormListAction = (
	abortCallback, projectId, status = MESSAGE_DEFINITION_STATUS.PUBLISHED
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'input-form/search',
		'',
		{projectId, status},
		null,
		1,
		9999999,
		null,
	)(dispatch)
		// Get mapped data from response data
		.then(res => (isSuccessful(res) ? getArray(res, 'data').map(i => formDataMap.input(i)) : []));
}