import React from "react";
import DialogComponent, {DIALOG_TYPE, executeComponentCallback} from "Core/components/DialogComponent";
import ImportCustomersFromFile from "../../advanced/ImportCustomersFromFile";
import Label from "Core/components/display/Label";
import {BUTTON_STYLE} from "Core/components/display/Button";
import PropTypes from "prop-types";
import {
	IMPORT_CUSTOMERS_FROM_FILE_TYPE,
	IMPORT_CUSTOMERS_FROM_FILE_TYPES
} from 'Components/advanced/ImportCustomersFromFile/const';

class ImportCustomersFromFileDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'import-customers-from-file-dialog',
			translationPath: 'ImportCustomersFromFileDialog',
			alignContent: 'left',
			dialogType: DIALOG_TYPE.NONE,
		});
	}
	
	render() {
		const {
			projectIds, icon, title, description, importType, showUniqueIdColumn, blacklistId, defaultOverride, 
			loadingSelector
		} = this.props;
		
		return this.renderDialog(
			this.renderTitle(<Label icon={icon} content={title ? title : this.t('title')} />),
			<>
				{description ? <><br /><Label element="div" content={description} supportHtml={true}/></> : null}
				<br />
				<div className="notice default">
					<Label content={this.t('supported_files_info')} supportHtml={true} />
				</div>
				<br />
				<ImportCustomersFromFile
					projectIds={projectIds}
					importType={importType}
					loadingSelector={loadingSelector}
					buttonLabel={title}
					buttonProps={{
						icon,
						displayStyle: BUTTON_STYLE.ACTION,
					}}
					defaultOverride={defaultOverride}
					showUniqueIdColumn={showUniqueIdColumn}
					blacklistId={blacklistId}
					onSuccess={() => {
						executeComponentCallback(this.props.onSuccess);
						this.close();
					}}
					onError={
						/** @param {ImportCustomersFileError} error */
						error => { /* TODO: handle errors */ }
					}
				/>
			</>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ImportCustomersFromFileDialog.propTypes = {
	projectIds: PropTypes.arrayOf(PropTypes.string),
	icon: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	importType: PropTypes.oneOf(IMPORT_CUSTOMERS_FROM_FILE_TYPES),
	showUniqueIdColumn: PropTypes.bool,
	blacklistId: PropTypes.string,
	defaultOverride: PropTypes.bool,
	loadingSelector: PropTypes.string,
	onSuccess: PropTypes.func,
};

/**
 * Define component default values for own props
 */
ImportCustomersFromFileDialog.defaultProps = {
	icon: 'user-plus',
	importType: IMPORT_CUSTOMERS_FROM_FILE_TYPE.DEFAULT,
	showUniqueIdColumn: false,
	defaultOverride: true,
	loadingSelector: '#import-customers-from-file-dialog',
};

export default ImportCustomersFromFileDialog;