import React from "react";
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from "prop-types";
import Label from 'Core/components/display/Label';

class FormHtmlTextElement extends BaseComponent {
	constructor(props) {
		super(props, {
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onChange'],
		});
	}

	render() {
		/** @type {FormHtmlTextDataObject} */
		const data = this.getProp('data');

		return (
			<Label content={data.content} supportHtml={data.html} />
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FormHtmlTextElement.propTypes = {
	// HTML/text form element data
	// @type {FormHtmlTextDataObject}
	data: PropTypes.object,
};

/**
 * Define component default values for own props
 */
FormHtmlTextElement.defaultProps = {
	
};

export default FormHtmlTextElement;