import styles from "./index.module.css";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import {withRouter} from "Core/router";
import Icon from "Core/components/display/Icon";
import Label, {LABEL_ICON_POSITION} from "Core/components/display/Label";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";

/**
 * Default 404 "Not Found" error page for 'site' section 
 */
class AppsNotFoundPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'site_default',
			domPrefix: 'site-not-found-page',
			translationPath: 'SiteNotFoundPage',
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		this.setBrowserTitle(this.t('page_title'));

		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<div className={styles['content']}>
					<Icon symbol="chain-broken" className={`page-notice-title-icon ${styles['icon']}`} />
					<Label
						element="p"
						elementProps={{className: 'page-notice-title bold'}}
						content={this.t('error_title')}
					/>
					<Label
						element="p"
						elementProps={{className: 'page-notice'}}
						content={this.t('error_message')}
					/>
					<Button
						big={true}
						label={(
							<Label 
								content={this.t('close_button')} 
								icon="angle-right" 
								iconPosition={LABEL_ICON_POSITION.RIGHT} 
							/>
						)}
						displayStyle={BUTTON_STYLE.ACTION}
						onClick={() => { this.redirectToHome(); }}
					/>
				</div>
			</div>
		));
	}
}

export default withRouter(AppsNotFoundPage);