import {cloneDeep, get, map, sortBy} from 'lodash';
import {getArray, getBool, getNumber, getString, resetObjectValues} from "Core/helpers/data";
import {MessageDefinitionDataObject, MessageDefinitionChannelDataObject} from '../dataObjects';
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import * as messageDataMap from "./message";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {MessageDefinitionDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	let channels = sortBy(getArray(result, 'messageDefinitionChannels'), 'priority').map(c => inputChannel(c));
	// Add the main channel if there ara no channels defined so that the GUI will display the main channel list item
	if (channels.length === 0) channels.push(new MessageDefinitionChannelDataObject());

	result = new MessageDefinitionDataObject(
		getString(result, 'id'),
		getString(result, 'campaignId'),
		getString(result, 'timeZoneId'),
		getBool(result, 'personalized'),
		channels,
		getDate(get(result, 'startDate', null), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getNumber(result, 'messageCount'),
		getString(result, 'interval'),
		getArray(result, 'messageContents').map(mc => messageDataMap.input(mc)),
		getString(result, 'deliveryStartTime'),
		getString(result, 'deliveryEndTime'),
		getNumber(result, 'timeToLive'),
		getNumber(result, 'numberOfRetries'),
		getNumber(result, 'pauseBetweenRetries'),
		getString(result, 'messageDefinitionStatus'),
		getNumber(result, 'ordinal')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {MessageDefinitionDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);
	const channels = getArray(result, 'channels');

	result = {
		id: result.id,
		campaignId: result.campaignId,
		personalized: result.personalized,
		messageDefinitionChannels: channels.map((c, idx) => ({...outputChannel(c), priority: idx})),
		startDate: getDateString(result.startDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		interval: result.interval,
		messageCount: result.messageCount,
		messageContents: getArray(result, 'messageContents')
			.filter(mc => map(channels, 'messageType').includes(mc.messageType))
			.map(mc => messageDataMap.output(mc)),
		deliveryStartTime: result.deliveryStartTime,
		deliveryEndTime: result.deliveryEndTime,
		timeToLive: result.timeToLive,
		numberOfRetries: result.numberOfRetries,
		pauseBetweenRetries: result.pauseBetweenRetries,
		timeZoneId: result.timeZoneId,
		messageDefinitionStatus: result.status
	};
	
	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}

// ---------------------------------------------------------------------------------------------------------------------

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {MessageDefinitionChannelDataObject}
 */
export const inputChannel = rawData => {
	let result = cloneDeep(rawData);
	
	result = new MessageDefinitionChannelDataObject(
		getString(result, 'id'),
		getString(result, 'channelId'),
		getString(result, 'channelName'),
		getString(result, 'channelMessageType'),
		// @note Falsy values changed to null because this value is used in a select input component.
		!!get(result, 'sender') ? get(result, 'sender') : null, 
		getString(result, 'senderName'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {MessageDefinitionChannelDataObject} inputData - Input data.
 * @return {Object}
 */
export const outputChannel = inputData => {
	let result = cloneDeep({
		id: inputData.id,
		channelId: inputData.channelId,
		channelName: inputData.channelName,
		channelMessageType: inputData.messageType,
		// @note Sender value is typecast to string because it can be null since it is used in a select input component.
		sender: getString(inputData, 'sender'),
		senderName: inputData.senderName,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}