import {matchPath} from "Core/router";
import {getArray, getString, isset} from "Core/helpers/data";
import {routerPath as projectPageRouterPath} from "../pages/apps/default/projectPages/dashboard/config";
import {find} from "lodash";
import {CookieData} from "Core/dataProtection/objects/cookie";
import {getStorageValue, STORAGE_TYPE} from "Core/storage";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {getCurrentUrl, getUrl} from 'Core/helpers/url';
import {ltrimString} from 'Core/helpers/string';

/**
 * Get the current project ID from the URL
 * 
 * @param {Object} [routerLocation] - React router location object. If not defined, current URL will be used.
 * @return {string|undefined} Project ID string or undefined if not on the project page.
 */
export const getCurrentProjectIdFromUrl = routerLocation => {
	let match;
	if (!routerLocation) match = matchPath(`/${ltrimString(getCurrentUrl(), getUrl())}`, {path: projectPageRouterPath});
	else match = matchPath(getString(routerLocation, 'pathname'), {path: projectPageRouterPath});
	if (match !== null) return getString(match.params, 'projectId');
	else return undefined;
};
/**
 * Get the current project ID from session storage (cookie)
 *
 * @return {string|undefined} Project ID string or undefined.
 */
export const getCurrentProjectIdFromSession = () => {
	const projectIdCookie = new CookieData('necessary', 'projectId', STORAGE_TYPE.SESSION);
	if (CookieConsent.isAllowed(projectIdCookie)) return getStorageValue('projectId', STORAGE_TYPE.SESSION);
	return undefined;
};

/**
 * Get current project from URL
 * @description Project ID is extracted form the URL and project list is used to find the actual project.
 * 
 * @param {ProjectListItemDataObject[]} projectList - List of all projects.
 * @param {Object} routerLocation - React router location object.
 * @return {ProjectListItemDataObject|undefined}
 */
export const getCurrentProjectFromUrl = (projectList, routerLocation) => {
	const projectId = getCurrentProjectIdFromUrl(routerLocation);
	return (isset(projectId) ? find(getArray(projectList), {id: projectId}) : undefined);
};
/**
 * Get current project from session storage (cookie)
 * @description Project ID is extracted form the session storage and project list is used to find the actual project.
 *
 * @param {ProjectListItemDataObject[]} projectList - List of all projects.
 * @return {ProjectListItemDataObject|undefined}
 */
export const getCurrentProjectFromSession = projectList => {
	const projectId = getCurrentProjectIdFromSession();
	return (isset(projectId) ? find(getArray(projectList), {id: projectId}) : undefined);
};

/**
 * Event type for the project ID change event
 * @type {string}
 */
export const PROJECT_ID_CHANGE_EVENT_TYPE = 'onProjectIdChange';
/**
 * Create and trigger an event when project ID changes in session storage
 * 
 * @param {string} projectId - New project ID.
 * @param {string} prevProjectId - Previous project ID.
 */
export const triggerProjectIdChange = (projectId, prevProjectId) => {
	const event = new CustomEvent(PROJECT_ID_CHANGE_EVENT_TYPE, {detail: {projectId, prevProjectId}});
	window.dispatchEvent(event);
}