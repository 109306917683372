import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import * as ReactDOM from "react-dom";
import {isPlainObject, isString, isNumber, find, has, get, map, uniqBy, uniq, omit, cloneDeep} from 'lodash';
import {getArray, getBoolean, getString, isset} from "Core/helpers/data";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {INSERT_VALUE_BUTTON_TYPE, INSERT_VALUE_BUTTON_TYPES} from "Core/components/advanced/InsertValueButton/const";
import Button, {BUTTON_DISPLAY_TYPES, BUTTON_STYLE, BUTTON_STYLES} from "Core/components/display/Button";
import InsertValueButton from "Core/components/advanced/InsertValueButton";
import {SELECT_INPUT_TOOLBAR_POSITION, SELECT_INPUT_TOOLBAR_POSITIONS} from "./const";
import {main_layout_element} from "Config/app";
import {Tooltip} from "Core/components/global/Tooltip";
import {waitingFunction} from 'Core/helpers/function';

/**
 * Select component
 * @description Select component where all options are predefined in the 'options' prop.
 * @note This is a controlled component which means it does not maintain its own state and value is controlled by the
 * parent component.
 *
 * This component uses 'react-select' component.
 */
class SelectInput extends BaseComponent {
	constructor(props) {
		super(props, { 
			translationPath: 'SelectInput',
			domPrefix: 'select-input-component'
		});
		
		// Custom component methods
		this.sortOptions = this.sortOptions.bind(this);
		this.getSelectOptions = this.getSelectOptions.bind(this);
		this.getValue = this.getValue.bind(this);
		this.optionValueGetter = this.optionValueGetter.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onCreateOption = this.onCreateOption.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);

		// Insert value methods
		this.containsInsertValue = this.containsInsertValue.bind(this);
		
		// Render methods
		this.renderInsertButton = this.renderInsertButton.bind(this);
		this.renderToolbar = this.renderToolbar.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 * @return {Promise<number|undefined>} Promise that will resolve with the updated mount count that
	 * will be set in the 'componentDidMount' method or undefined for default functionality where 'componentDidMount'
	 * will just reset the mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();

		waitingFunction(() => {
			const portalElement = document.querySelector(`.${this.getDomId()} .select-input__control`);
			if (!!portalElement) {
				this.forceUpdate();
				return true;
			}
		}, 10, 1000).then();
	}


	// Custom component methods -----------------------------------------------------------------------------------------
	/**
	 * Sort options so that fixed ones appear first
	 * @param {*|*[]} options - Options to sort.
	 * @return {*|*[]}
	 */
	sortOptions(options) {
		const {isMulti, isOptionFixed} = this.props;

		if (!isMulti || !options || typeof isOptionFixed !== 'function') return options;
		return getArray(options).filter(isOptionFixed).concat(options.filter(o => !isOptionFixed(o)));
	}
	
	/**
	 * Get select options
	 * @return {Array}
	 */
	getSelectOptions() {
		const {options} = this.props;
		return this.sortOptions(options);
	}
	
	/**
	 * Get value object from any value
	 * @param {any} value - Any value.
	 * @return {Object} Value that can be used in 'react-select' component.
	 */
	getValue(value) {
		const {primaryKey, isMulti, isCreatable} = this.props;
		const options = this.getSelectOptions();
		
		if (isMulti) {
			// If value is a number, try to find options in the list of options using value as primary keys
			if (isNumber(value)) {
				const valueOption = find(options, {[primaryKey]: value});
				return (valueOption ? valueOption : []);
			}
			// If value is comma-separated string, try to find options in the list of options using string values as 
			// primary keys
			else if (isString(value)) {
				let valueOptions = [];
				getString(value).split(',').map(s => s.trim()).forEach(pk => {
					// Handle insert value
					if (this.containsInsertValue(pk)) valueOptions.push({label: pk, [primaryKey]: pk});
					// Handle regular value
					else {
						const valueOption = find(options, {[primaryKey]: pk});
						if (valueOption) valueOptions.push(valueOption)
						// Handle creatable values
						else if (isCreatable && pk) valueOptions.push({label: pk, [primaryKey]: pk});
					}
				});
				return valueOptions;
			} 
			else if (Array.isArray(value)) {
				return map(value, v => {
					// If value is simple, try to find it in the list of options
					// @note Simple value is just the value of the primary key.
					if (!isPlainObject(v) && (isString(v) || isNumber(v))) {
						const valueOption = find(options, {[primaryKey]: v});
						return (
							valueOption ?
								// Handle regular value	
								valueOption :
								// Handle insert value
								(this.containsInsertValue(v) ? {label: v, [primaryKey]: v} :
								// Handle creatable values	
								(isCreatable && value ? {label: v, [primaryKey]: v} : null))
						);
					}
					return v;
				});
			} else {
				return value;
			}
		} else {
			// If value is simple, try to find it in the list of options
			// @note Simple value is just the value of the primary key.
			if (!isPlainObject(value) && (isString(value) || isNumber(value))) {
				const valueOption = find(options, {[primaryKey]: value});
				return (
					valueOption ?
						// Handle regular value	
						valueOption :
						// Handle insert value
						(this.containsInsertValue(value) ? {label: value, [primaryKey]: value} :
						// Handle creatable values	
						(isCreatable && value ? {label: value, [primaryKey]: value} : null))
				);
			}
			return value;
		}
	}

	/**
	 * Resolves option data to a string to compare options and specify value attributes
	 * @note If 'getOptionValue' prop is not defined and 'primaryKey' is defined, 'primaryKey' will be used.
	 * 
	 * @param {Object} option - 'react-select' option item.
	 * @return {string}
	 */
	optionValueGetter(option) {
		const {primaryKey, getOptionValue} = this.props;
		if (isset(getOptionValue)) return getOptionValue(option);
		else if (primaryKey && has(option, primaryKey)) return option[primaryKey];
	}

	/**
	 * 'react-select' onChange method
	 * @note Overwritten to support simple and fixed values.
	 * 
	 * @param {Object|Object[]} selectedValue - 'react-select' selected value.
	 * @param {{action: string, [removedValue]: Object, [removedValues]: Object[]}} [actionMeta] - React Select object 
	 * representing an action.
	 */
	onChange(selectedValue, actionMeta) {
		const {simpleValue, primaryKey, isMulti, isOptionFixed, onChange} = this.props;
		let selectedValueToUse = cloneDeep(selectedValue);
		
		// Change action behaviour for fixed options
		if (typeof isOptionFixed === 'function') {
			switch (get(actionMeta, 'action')) {
				// Do not remove option if it is fixed
				case 'remove-value':
				case 'pop-value':
					if (isOptionFixed(actionMeta.removedValue)) return;
					break;
				// Preserver fixed options on clear
				case 'clear':
					if (isMulti) {
						if (Array.isArray(actionMeta.removedValues)) {
							selectedValueToUse = actionMeta.removedValues.filter(isOptionFixed);
						}
					} else {
						selectedValueToUse = isOptionFixed(selectedValueToUse) ? selectedValueToUse : null;
					}
					break;
				// no default
			}
		}
		
		if (isMulti) {
			let selectedOptions = map(selectedValueToUse, option => {
				if (simpleValue) {
					if (get(option, primaryKey)) return get(option, primaryKey);
				} else if (option) {
					return option;
				}
			});
			
			// Remove duplicates
			if (simpleValue) selectedOptions = uniq(selectedOptions);
			else selectedOptions = uniqBy(selectedOptions, primaryKey);
			
			executeComponentCallback(onChange, selectedOptions, selectedValueToUse);
		} else {
			const selectedOption = (simpleValue ? get(selectedValueToUse, primaryKey, null) : selectedValueToUse);
			executeComponentCallback(onChange, selectedOption, selectedValueToUse);
		}
	}

	/**
	 * 'react-select/creatable' onCreateOption method
	 * @note Overwritten to support simple values.
	 * 
	 * @param {string} inputValue - Input string value.
	 */
	onCreateOption(inputValue) {
		const {simpleValue, primaryKey, onCreateOption} = this.props;
		const newOption = (simpleValue ? inputValue : {label: inputValue, [primaryKey]: inputValue});
		executeComponentCallback(onCreateOption, newOption);
	}

	/**
	 * 'react-select' onChange method
	 * @note Overwritten to support Enter key event and better handle focus on Escape key event.
	 *
	 * @param {KeyboardEvent} event - Keydown event.
	 */
	onKeyDown(event) {
		const {isMulti, blurOnEscape} = this.props;
		const options = this.getSelectOptions();
		const menuIsOpen = getBoolean(this, 'element.props.menuIsOpen');

		// Trigger standard keydown event
		executeComponentCallback(this.props.onKeyDown, event);
		
		// Trigger Enter key event if menu is closed since when it is opened Enter key is used to select a value.
		if (!menuIsOpen && event.key === 'Enter') executeComponentCallback(this.props.onEnterKey, event);
		
		// Add all filtered items on Shift+Enter
		if (isMulti && menuIsOpen && event.key === 'Enter' && event.shiftKey) {
			// Prevent default Enter key press form adding the selected value
			event.preventDefault();
			
			// Add all filtered values
			const updatedValue = [
				...getArray(this.element?.getValue()),
				...getArray(this.element?.getCategorizedOptions())
					.filter(co => (!co.isDisabled && co.type === 'option'))
					.map(o => o.data)
			];
			this.onChange(updatedValue);
			// Clear input because default Enter key press was prevented, and it was responsible for clearing it
			this.element?.onInputChange('', {action: 'input-change', prevInputValue: this.element?.inputRef.value});
			// Close the menu if there are no more options to select
			if (options.length === updatedValue.length) this.element.onMenuClose();
		}

		// Stop propagation of the Escape key if menu is opened
		// @description This is done to prevent parent elements from catching the Escape key down while menu is opened
		// because we explicitly want Escape key to just close the menu. For example, this is necessary to prevent closing
		// a dialog, that closes on Escape key press, when Escape key is pressed on a focused input.
		if (menuIsOpen && event.key === 'Escape') {
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();
		}
		// Stop propagation of the Escape key and blur the element if it is focused
		else if (!menuIsOpen && event.key === 'Escape') {
			// Stop propagation of the Escape key if menu is opened
			if (blurOnEscape && document.activeElement === document.querySelector(`#${this.getDomId()}-input`)) {
				event.stopPropagation();
				event.nativeEvent.stopImmediatePropagation();
				this.element.blur();
			}
		}
	}


	// Insert value methods ---------------------------------------------------------------------------------------------
	/**
	 * Check if string contains any insert value
	 * @param {string} string - String to check
	 * @return {boolean}
	 */
	containsInsertValue(string) {
		const {insertValueType, insertValueTypeOptions} = this.props;
		switch (insertValueType) {
			case INSERT_VALUE_BUTTON_TYPE.DIALOG:
				/** @type {InsertValueDialogSectionDataObject[]} */
				const sections = getArray(insertValueTypeOptions, 'dialogProps.sections');
				for (let i of sections) if (i.contains(string)) return true;
				return false;

			case INSERT_VALUE_BUTTON_TYPE.DROPDOWN:
				// TODO: dropdown insert value
				return false;

			default:
				return false;
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render insert button
	 * @return {JSX.Element}
	 */
	renderInsertButton() {
		const {
			isDisabled, value, isMulti, insertValueButtonProps, insertValueType, insertValueTypeOptions, primaryKey
		} = this.props;
		
		if (isDisabled) return null;
		return (
			<InsertValueButton
				buttonProps={{
					displayStyle: BUTTON_STYLE.NONE,
					className: 'input-toolbar-button',
					...insertValueButtonProps
				}}
				insertType={insertValueType}
				insertTypeOptions={insertValueTypeOptions}
				onInsert={v => {
					if (isMulti) {
						let values = getArray(this.getValue(value));
						const insertValues = getArray(v);
						insertValues.forEach(iv => values.push({label: iv, [primaryKey]: iv}));
						this.onChange(values);
					}
					else this.onChange({label: v, [primaryKey]: v})
				}}
				onDialogClose={() => {
					this.element.focus();
					if (isMulti) this.element.onMenuOpen();
				}}
			/>
		);
	}

	/**
	 * Render a toolbar inside the select
	 * @param {SelectInputToolbarPosition} position - Position of the toolbar.
	 * @return {ReactNode|null}
	 */
	renderToolbar(position) {
		const {toolbarButtons, isDisabled, showInsertValueButton} = this.props;
		const positionToolbarButtons = toolbarButtons.filter(b => b.position === position);
		const portalElement = document.querySelector(`.${this.getDomId()} .select-input__control`);
		
		if (portalElement) {
			return ReactDOM.createPortal(
				(
					<div className={`input-toolbar position-${position}`}>
						{positionToolbarButtons.map((btnProps, index) => {
							const ButtonComponent = get(btnProps, 'component', Button);
							return (
								getString(btnProps, 'tooltip') ?
									<Tooltip
										key={index}
										tag="span"
										title={getString(btnProps, 'tooltip')}
										size="small"
										position="top-center"
										arrow={true}
										interactive={false}
									>
										<ButtonComponent
											{...omit(btnProps, ['className', 'onClick', 'component', 'tooltip', 'key'])}
											className={`${getString(btnProps, 'className')} input-toolbar-button`}
											onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
										/>
									</Tooltip>
									:
									<ButtonComponent
										key={index}
										{...omit(btnProps, ['className', 'onClick', 'component', 'tooltip', 'key'])}
										className={`${getString(btnProps, 'className')} input-toolbar-button`}
										onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
									/>
							);
						})}
						{
							position === SELECT_INPUT_TOOLBAR_POSITION.LEFT && !isDisabled && showInsertValueButton ?
								this.renderInsertButton() :
								null
						}
					</div>
				),
				portalElement
			);
		}
		return null;
	}
	
	render() {
		const {
			className, classNamePrefix, value, defaultValue, noOptionsMessage, placeholder, getOptionValue, isMulti,
			onChange, formControlStyle, isDisabled, showInsertValueButton, insertValueButtonProps, insertValueType,
			insertValueTypeOptions, isCreatable, isOptionFixed, formatCreateLabel, primaryKey, menuRenderInLayout, styles, 
			options, ...otherProps
		} = this.props;
		const selectOptions = this.getSelectOptions();
		const isClearable = (
			!!this.getProp('isClearable') && typeof isOptionFixed === 'function' ?
				(isMulti ? 
					getArray(this.getValue(value)).some(o => !isOptionFixed(o)) : 
					isOptionFixed(this.getValue(value))
				) 
				:
				this.getProp('isClearable')
		);
		
		// Prepare portal related props
		let portalProps = {};
		if (menuRenderInLayout) {
			const element = this.getDomElement();
			const fontSize = (element ? getComputedStyle(this.getDomElement()).getPropertyValue('font-size') : '');
			portalProps.styles = (
				styles ?
					{
						...styles, 
						menuPortal: base => ({ ...base, zIndex: 9999, fontSize }),
						multiValueLabel: (base, state) => typeof isOptionFixed === 'function' ?
							(isOptionFixed(state.data) ? {...base, paddingRight: 6} : base) :
							base,
						multiValueRemove: (base, state) => typeof isOptionFixed === 'function' ?
							(isOptionFixed(state.data) ? {...base, display: 'none'} : base) :
							base,
					} 
					:
					{
						menuPortal: base => ({ ...base, zIndex: 9999, fontSize }),
						multiValueLabel: (base, state) => typeof isOptionFixed === 'function' ?
							(isOptionFixed(state.data) ? {...base, paddingRight: 6} : base) :
							base,
						multiValueRemove: (base, state) => typeof isOptionFixed === 'function' ?
							(isOptionFixed(state.data) ? {...base, display: 'none'} : base) :
							base,
					}
			);
			portalProps.menuPortalTarget = document.querySelector(main_layout_element);
			portalProps.menuPlacement = 'auto';
		}
		
		// Define select component to use depending on 'isCreatable' prop flag
		const SelectComponent = isCreatable ? CreatableSelect : Select;
		
		return (
			<>
				{this.renderToolbar(SELECT_INPUT_TOOLBAR_POSITION.LEFT)}
				<SelectComponent
					inputId={`${this.getDomId()}-input`}
					id={this.getDomId()}
					className={
						`${this.getOption('domPrefix')} ${className} ${formControlStyle ? 'form-control' : ''} ` +
						`${this.getDomId()} ${showInsertValueButton ? 'has-insert-btn' : ''}`
					}
					classNamePrefix={`select-input${classNamePrefix ? ' ' + classNamePrefix : ''}`}
					primaryKey={primaryKey}
					value={this.getValue(value)}
					defaultValue={this.getValue(defaultValue)}
					noOptionsMessage={noOptionsMessage ? noOptionsMessage : () => this.t('No options')}
					formatCreateLabel={formatCreateLabel ? formatCreateLabel : v => `${this.t('Create')} "${v}"`}
					placeholder={
						placeholder ? 
							placeholder : 
							(isCreatable ? this.t('Select or create ...') : this.t('Select ...'))
					}
					getOptionValue={this.optionValueGetter}
					options={selectOptions}
					isDisabled={isDisabled}
					isClearable={isClearable}
					isMulti={isMulti}
					onChange={this.onChange}
					onCreateOption={isCreatable ? this.onCreateOption : undefined}
					onKeyDown={this.onKeyDown}
					{...portalProps}
					{...omit(otherProps, [
						'inputId', 'id', 'getOptionValue', 'onChange', 'onCreateOption', 'onKeyDown', 'isClearable', 
					])}
					ref={node => this.element = node}
				/>
				{this.renderToolbar(SELECT_INPUT_TOOLBAR_POSITION.RIGHT)}
			</>
		);
	}
}

/**
 * Class that can be used for any prop in 'labelProps' or 'singleValueProps' if that prop depends on option data
 * @description This class consist of a single function that is passed through the constructor. The function will 
 * receive option data as an only argument, and it should return the actual value that will be used as a prop value.
 */
export class SelectDynamicValueFunction {
	/**
	 * Class constructor
	 * @param {function(optionData: Object):any} func
	 */
	constructor(func) { this.func = func; }
}

/**
 * Define component's own props that can be passed to it by parent components
 */
SelectInput.propTypes = {
	// The id to set on the SelectContainer component
	id: PropTypes.string,
	// Apply a className to the control
	className: PropTypes.string,
	// Apply classNames to inner elements with the given prefix
	classNamePrefix: PropTypes.string,
	// Disable the control
	isDisabled: PropTypes.bool,
	// Allow the user to select multiple values
	isMulti: PropTypes.bool,
	// Allow the user to search for matching options
	isSearchable: PropTypes.bool,
	// Specify the options the user can select from
	options: PropTypes.array,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// Text to display when there are no options
	noOptionsMessage: PropTypes.func,
	// Placeholder for the select value
	placeholder: PropTypes.string,
	// Control the current value
	value: PropTypes.any,
	// Set the initial value of the control
	defaultValue: PropTypes.any,
	// Props used by the supported custom label component
	// @see 'options' folder for available custom label components or create your own.
	labelProps: PropTypes.object,
	// Props used by the supported custom single value component
	// @see 'singleValues' folder for available custom single value components or create your own.
	singleValueProps: PropTypes.object,
	// Resolves option data to a string to be displayed as the label by components
	getOptionLabel: PropTypes.func, // (option) => string
	// Resolves option data to a string to compare options and specify value attributes
	getOptionValue: PropTypes.func, // (option) => string
	// Option property used as a primary key for selected value
	primaryKey: PropTypes.string,
	// If true, 'onChange' will return a simple value of the option defined in 'primaryKey'
	simpleValue: PropTypes.bool,
	// Is the select value clearable
	isClearable: PropTypes.bool,
	// Is the select in a state of loading (async)
	isLoading: PropTypes.bool,
	// Flag that specifies if select items can be created
	isCreatable: PropTypes.bool,
	// Function that determines if an option should be fixed (cannot be removed)
	// @note This is most useful for multi select since for a regular select 'readOnly' will produce the same result.
	// @type {Function<option: Object>}
	isOptionFixed: PropTypes.func,
	// Gets the label for the "create new ..." option in the menu. Is given the current input value.
	formatCreateLabel: PropTypes.func,
	// Flag that determines if menu will be rendered in main layout element (see 'main_layout_element' config value) 
	// using a portal
	menuRenderInLayout: PropTypes.bool,
	// Flag that specifies if select input will blur on Escape key before the default event propagation will kick in
	blurOnEscape: PropTypes.bool,

	// Toolbar buttons that will be shown in the select
	// @note If custom component is used this should contain props for that component.
	toolbarButtons: PropTypes.arrayOf(PropTypes.shape({
		// Position where button will be rendered (left or right)
		position: PropTypes.oneOf(SELECT_INPUT_TOOLBAR_POSITIONS),
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is passed
		// to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,
		// Component to render instead of the Button
		component: PropTypes.elementType,
		// Tooltip content
		// @note HTML is not supported.
		tooltip: PropTypes.string,

		// Events
		onClick: PropTypes.func,
	})),

	// Flag that determines if insert value button component (InsertValueButton) will be rendered inside the input
	showInsertValueButton: PropTypes.bool,
	// Insert value button props
	insertValueButtonProps: PropTypes.shape({
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is 
		// passed to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,

		// Events
		onClick: PropTypes.func,
	}),
	// Insert type
	insertValueType: PropTypes.oneOf(INSERT_VALUE_BUTTON_TYPES),
	// Insert type options
	// @note Options depend on 'insertValueType'.
	insertValueTypeOptions: PropTypes.oneOfType([
		// INSERT_VALUE_BUTTON_TYPE_DIALOG
		PropTypes.shape({
			dialogProps: PropTypes.object,
			dialogOptions: PropTypes.shape({
				id: PropTypes.string,
				className: PropTypes.string,
				closeOnEscape: PropTypes.bool,
				closeOnClickOutside: PropTypes.bool,
				hideCloseBtn: PropTypes.bool,
				maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
			}),
		}),
		// INSERT_VALUE_BUTTON_TYPE_DROPDOWN
		PropTypes.shape({
			// TODO: dropdown insert value
		})
	]),

	// Events
	onChange: PropTypes.func, // Arguments: selected option(s) (object or value depending on 'simpleValue' prop), selected option object(s)
	onCreateOption: PropTypes.func, // Arguments: new option to create (object or value depending on 'simpleValue' prop)
	onEnterKey: PropTypes.func, // Arguments: keypress event
	// ... react-select prop types (@link https://react-select.com/props)
};

/**
 * Define component default values for own props
 */
SelectInput.defaultProps = {
	id: '',
	className: '',
	classNamePrefix: '',
	isDisabled: false,
	isMulti: false,
	isSearchable: true,
	options: [],
	formControlStyle: true,
	primaryKey: 'value',
	simpleValue: true,
	isClearable: false,
	isLoading: false,
	isCreatable: false,
	menuRenderInLayout: true,
	blurOnEscape: true,
	toolbarButtons: [],
	showInsertValueButton: false,
};

export default SelectInput;
export {components} from "react-select";