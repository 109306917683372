import CoreAuth from "../core/auth";
import {deleteStorageKey, getStorageValue, setStorageValue, STORAGE_TYPE} from 'Core/storage';
import {
	io_default_request_options,
	io_pause_requests_storage_field_name,
	io_use_country_request_processor_for_auth
} from 'Config/io';
import {
	access_token_storage_field_name,
	access_token_storage_type, auth_api,
	auth_api_endpoints, auth_api_request_type,
	auth_api_response_type,
	auth_broadcast_channel,
	auth_broadcast_message_logout,
	current_user_storage_field_name,
	decoded_access_token_storage_field_name,
	refresh_token_storage_field_name,
	refresh_token_storage_type,
	refreshing_tokens_storage_field_name,
	temporary_access_token_storage_field_name
} from 'Config/auth';
import {acl_storage_type, acl_storage_var} from 'Config/acl';
import {user_activity_storage_path} from 'Config/app';
import {RESPONSE_DATA_TYPE} from 'Core/io/const';
import {getArray, getString} from 'Core/helpers/data';
import {getIOUrl, rawRequest} from 'Core/io/helper';
import {IoDataResponseProcessorResultObject} from 'Core/objects';
import {isLoginWithoutRedirect} from 'Core/helpers/login';
import {get} from 'lodash';
import {isSuccessful} from 'Core/helpers/io';
import ACL from 'Acl/index';
import {AclDataObject} from 'Core/acl';

/**
 * App auth class
 * @note This class must extend CoreAuth class, but it does not have to implement any changes if no custom functionality
 * is needed.
 */
class Auth extends CoreAuth {

	/**
	 * Call the API server security init method
	 * @description This should create httpOnly cookie for storing signature portion of the access cookie and a csrfToken
	 * cookie (security items for short).
	 * @note Temporarily stored access token will be used to make the API call. Make sure you stored it properly before
	 * calling this method.
	 *
	 * @param {Function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as
	 * an argument. This will not be used but is specified as param so that the standard 'executeAbortableAction' method,
	 * used by all components extending the abstract BaseComponent, will work properly.
	 * @param {object} [options] - Other request options that will be sent. Default value is retrieved form IO config
	 * ("/src/config/io.js").
	 * @param {Headers} [headers=null] - Request headers.
	 * @return {Promise<any>} Promise that resolves successfully if everything went well and security items were created
	 * or rejects if it didn't.
	 * @override Save permissions to storage
	 */
	static securityInit = (abortCallback, options = io_default_request_options, headers = null) => {
		let requestProcessors = ['auth', 'csrfToken'];
		if (io_use_country_request_processor_for_auth) requestProcessors.push('country');
		let responseProcessors = ['error'];
		if (auth_api_response_type === RESPONSE_DATA_TYPE.JSON) {
			responseProcessors.push('data');
			responseProcessors.push('standardJsonError');
		}

		// Get API to use
		const api = (
			getString(auth_api_endpoints.security_init, 'api') ?
				auth_api_endpoints.security_init.api :
				auth_api
		);

		return rawRequest({
			type: auth_api_request_type,
			url: getIOUrl(api, auth_api_endpoints.security_init.path),
			api: api,
			endpoint: auth_api_endpoints.security_init.path,
			data: {
				access_token: getStorageValue(temporary_access_token_storage_field_name, STORAGE_TYPE.MEMORY),
				...auth_api_endpoints.security_init.params,
			},
			method: auth_api_endpoints.security_init.method,
			autoToken: true,
			options,
			headers,
			responseType: auth_api_response_type,
			canPause: false,
			requestProcessors,
			responseProcessors,
			abortCallback
		})
			.then(result => result instanceof IoDataResponseProcessorResultObject ? result.data : result)
			// Store current user's data if init was successful and login type does not use redirects
			.then(response => {
				if (isLoginWithoutRedirect()) {
					const user = get(response, 'data');
					setStorageValue(current_user_storage_field_name, user, STORAGE_TYPE.LOCAL, {}, true);
					setStorageValue(current_user_storage_field_name, user, STORAGE_TYPE.REDUX);
				}
				return response;
			})
			/**
			 * @override Save permissions to storage
			 */
			.then(response => {
				if (isSuccessful(response)) {
					ACL.save(new AclDataObject(
						getArray(response, 'data.permissionCodes'),
						getString(response, 'data.userAccountType'),
					));
				}
			});
	}
	
	/**
	 * Logout the user
	 * @description This method will just remove tokens from storage.
	 * @return {Promise<void>}
	 * @override Deleted project ID session storage value.
	 */
	static logout = () => {
		deleteStorageKey(io_pause_requests_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(temporary_access_token_storage_field_name, STORAGE_TYPE.MEMORY);
		deleteStorageKey(access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(refresh_token_storage_field_name, refresh_token_storage_type);
		deleteStorageKey(refreshing_tokens_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(decoded_access_token_storage_field_name, access_token_storage_type);
		deleteStorageKey(acl_storage_var, acl_storage_type);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.LOCAL);
		deleteStorageKey(current_user_storage_field_name, STORAGE_TYPE.REDUX);
		deleteStorageKey(user_activity_storage_path, STORAGE_TYPE.LOCAL);
		
		// @override Delete project ID session storage value
		deleteStorageKey('projectId', STORAGE_TYPE.SESSION);
		// @override Delete session storage value storing sidebar menu group open/closed status
		deleteStorageKey('sidebar_menu_groups_statuses', STORAGE_TYPE.SESSION);

		// Broadcast logout so that all other opened tabs that need to will redirect to login page
		const bChannel = new BroadcastChannel(auth_broadcast_channel);
		bChannel.postMessage(auth_broadcast_message_logout);
		bChannel.close();

		return Promise.resolve();
	}
}

export default Auth;