import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import PropTypes from 'prop-types'
import Icon from 'Core/components/display/Icon';
import Label from 'Core/components/display/Label';
import {CHECKBOX_INPUT_LABEL_POSITION, CHECKBOX_INPUT_LABEL_POSITIONS} from "./const";
import {getCssSizeString} from 'Core/helpers/dom';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {omit} from 'lodash';

/**
 * Checkbox input component
 * @description Checkbox rendered using font icons instead of standard checkbox input.
 * @note This is a controlled component which means it does not maintain its own state and value is controlled by the
 * parent component.
 */
class CheckboxInput extends BaseComponent {
	constructor(props) {
		super(props, { domPrefix: 'checkbox-component' });

		// Render methods
		this.hasLabel = this.hasLabel.bind(this);
		this.renderLabel = this.renderLabel.bind(this);
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Check if checkbox label is defined in props and should be rendered
	 * @return {boolean}
	 */
	hasLabel() {
		const {label, labelPosition} = this.props;
		return label && CHECKBOX_INPUT_LABEL_POSITIONS.includes(labelPosition);
	}
	
	/**
	 * Render checkbox label if specified in props
	 * @return {JSX.Element|null}
	 */
	renderLabel() {
		const {label, labelPosition, labelTooltip} = this.props;
		return (
			this.hasLabel() ? 
				<Label
					content={label} 
					element="label" 
					elementProps={{
						className: 
							`${styles['label']} ${styles[`position-${labelPosition}`]} ` +
							(labelTooltip ? styles['withTooltip'] : ''),
					}} 
					tooltip={labelTooltip}
					tooltipOptions={{tag: 'div'}}
				/> 
				: null
		);
	}
	
	render(){
		const {
			className, checked, checkedIcon, uncheckedIcon, readOnly, disabled, labelPosition, checkedColor, asButton,
			buttonProps,
		} = this.props;
		const size = getCssSizeString(this.props.size);
		const hasLabel = this.hasLabel();
		
		return (
			asButton ?
				<Button
					id={this.getDomId()}
					className={
						`checkbox-component ${className} ${styles['checkbox']} ${styles['button-checkbox']}` +
						`${disabled ? ` disabled ${styles['disabled']} ` : ''}` +
						`${!disabled && readOnly ? ` readOnly ${styles['readOnly']} ` : ''}` +
						`${hasLabel ? ` ${styles['withLabel']}` : ''}`
					}
					onClick={(readOnly || disabled ? null : () => executeComponentCallback(this.props.onChange, !checked))}
					style={{fontSize: size}}
					icon={(checked ? checkedIcon : uncheckedIcon)}
					iconProps={{
						style: {
							color: (checkedColor && checked ? checkedColor : undefined),
							width: size,
							height: size
						}
					}}
					label={this.renderLabel()}
					displayStyle={BUTTON_STYLE.DEFAULT}
					displayType={BUTTON_DISPLAY_TYPE.NONE}
					{...omit(buttonProps, ['id', 'className', 'onClick', 'style', 'icon', 'iconProps', 'label'])}
				/>
				:
				<div
					id={this.getDomId()}
					className={
						`checkbox-component ${className} ${styles['checkbox']}` +
						`${disabled ? ` disabled ${styles['disabled']} ` : ''}` +
						`${!disabled && readOnly ? ` readOnly ${styles['readOnly']} ` : ''}` + 
						`${hasLabel ? ` ${styles['withLabel']}` : ''}`
					}
					onClick={(readOnly || disabled ? null : () => executeComponentCallback(this.props.onChange, !checked))}
					style={{fontSize: size}}
				>
					{labelPosition === CHECKBOX_INPUT_LABEL_POSITION.LEFT ? this.renderLabel() : null}
					<Icon 
						symbol={(checked ? checkedIcon : uncheckedIcon)}
						style={{
							color: (checkedColor && checked ? checkedColor : ''),
							width: size,
							height: size
						}}
					/>
					{labelPosition === CHECKBOX_INPUT_LABEL_POSITION.RIGHT ? this.renderLabel() : null}
				</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CheckboxInput.propTypes = {
	// Component wrapper id attribute
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	// Component wrapper class attribute
	className: PropTypes.string,
	// Flag that determines if checkbox will be rendered as checked
	checked: PropTypes.bool,
	// Font icon symbol name used to render checked state
	checkedIcon: PropTypes.string,
	// Font icon symbol name used to render unchecked state
	uncheckedIcon: PropTypes.string,
	// Size in pixels
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// Color used for checkbox if it is checked
	checkedColor: PropTypes.string,
	// Flag specifying if checkbox should be read only
	readOnly: PropTypes.bool,
	// Flag that determines if checkbox should be disabled
	disabled: PropTypes.bool,
	// Checkbox label
	// @note Clicking the label will toggle the checkbox.
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Checkbox label position
	labelPosition: PropTypes.oneOf(CHECKBOX_INPUT_LABEL_POSITIONS),
	// Tooltip text for the label
	labelTooltip: PropTypes.string,
	// Flag that determines if checkbox component will be rendered as a button
	// @note This is most useful when focus is needed.
	asButton: PropTypes.bool,
	// Checkbox button props if 'asButton' prop is true
	buttonProps: PropTypes.shape(Button.propTypes),
	
	// Events
	onChange: PropTypes.func, // Arguments: new checked value
};

/**
 * Define component default values for own props
 */
CheckboxInput.defaultProps = {
	id: '',
	className: '',
	checkedIcon: 'check-square',
	uncheckedIcon: 'square-o',
	size: 16,
	checkedColor: '',
	label: '',
	labelPosition: CHECKBOX_INPUT_LABEL_POSITION.RIGHT,
	labelTooltip: '',
	asButton: false,
	buttonProps: {},
};

export * from "./const";
export default CheckboxInput;