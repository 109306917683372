import {Component, forwardRef} from 'react';
import {Tooltip as Tippy, TooltipProps} from "react-tippy";
import {v4} from "uuid";
import {omit} from 'lodash';

class TooltipComponent extends Component {
	render() {
		const {html, children, innerRef, ...otherProps} = this.props;
		const rawTemplateId = v4();

		return (
			<>
				{/* 
				Create an HTML element to use in Tippy with 'rawTemplate' instead of 'html' prop because 'html' uses 
				ReactDOM.render method that was removed form React v19 and react-tippy package seams to be abandoned and 
				won't be updated to support React 19. Element is given and ID that will be used in 'rawTemplate' and is 
				rendered inside an invisible element because we don't need to render it and see it since tooltip will use it. 
				*/}
				{!!html ?
					<div className="no-display tooltip-html-content">
						<div id={`id-${rawTemplateId}`}>{html}</div>
					</div>
					: null
				}
				<Tippy 
					key={`id-${rawTemplateId}`}
					rawTemplate={!!html ? `id-${rawTemplateId}` : undefined} 
					{...omit(otherProps, ['ref', 'rawTemplate'])}
					ref={innerRef}
				>
					{this.props.children}
				</Tippy>
			</>
		);
	}
}

// Used ref forwarding to make the ref of the component actually be the Tippy ref so other components that use it will 
// still work after switching to use this component instead of Tippy directly
const Tooltip = forwardRef((props, ref) => <TooltipComponent innerRef={ref} {...props} />);

export {Tooltip, TooltipProps};