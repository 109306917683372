import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "../../BaseComponent";
import PropTypes from "prop-types";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../display/Button";
import {CHECKBOX_LIST_INPUT_LAYOUT, CHECKBOX_LIST_INPUT_LAYOUTS} from './const';
import {getArray, getString} from 'Core/helpers/data';
import {omit} from "lodash";
import CheckboxInput from 'Core/components/input/CheckboxInput';

/**
 * Input component for a list of checkboxes
 * @note This is a controlled component which means it does not maintain its own state and value is controlled by the
 * parent component.
 */
class CheckboxListInput extends BaseComponent {
	constructor(props) {
		super(props, {
			dataPropAlias: 'value',
			domPrefix: 'checkbox-list-input-component'
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			className, options, value, layout, formControlStyle, checkboxProps, isClearable, clearValue, onChange
		} = this.props;
		const valueArray = getArray(value);

		return (
			<div
				id={this.getDomId()}
				className={
					`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className}` +
					` ${styles['wrapper']} ${layout} ${styles[layout]}`
				}
			>
				{options.map((option, index) =>
					<CheckboxInput
						key={index}
						asButton={true}
						className={
							`${this.getOption('domPrefix')}-option ${styles['option']} ` +
							(option.value === value ? 'selected ' : ' ') + 
							`${getString(checkboxProps, 'className')}`
						}
						label={option.label}
						checked={valueArray.includes(option.value)}
						onChange={checked => {
							if (checked) executeComponentCallback(onChange, [...valueArray, option.value]);
							else executeComponentCallback(onChange, valueArray.filter(v => v !== option.value));
						}}
						{...omit(checkboxProps, ['className'])}
					/>
				)}

				{
					isClearable ?
						<Button
							className={
								`${this.getOption('domPrefix')}-clear-btn ${styles['clearBtn']} ` +
								`${this.getOption('domPrefix')}-option ${styles['option']} `
							}
							icon="times-circle"
							iconProps={{className: styles['clearIcon']}}
							label={layout === CHECKBOX_LIST_INPUT_LAYOUT.STACKED ? this.t('Clear', 'general') : undefined}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							displayStyle={BUTTON_STYLE.SUBTLE}
							onClick={() => {executeComponentCallback(this.props.onChange, clearValue)}}
						/>
						: null
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CheckboxListInput.propTypes = {
	// Component's wrapper element id
	id: PropTypes.string,
	// Component's wrapper element class
	className: PropTypes.string,
	// Checkbox options that will be rendered
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		label: PropTypes.string
	})),
	value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	// Checkbox list layout
	layout: PropTypes.oneOf(CHECKBOX_LIST_INPUT_LAYOUTS),
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// Checkbox component props
	checkboxProps: PropTypes.shape({...CheckboxInput.propTypes}),
	// Is the list clearable
	isClearable: PropTypes.bool,
	// Value to be used when clearing the list
	// @note Only relevant if 'isClearable' prop is true.
	clearValue: PropTypes.any,

	// Events
	onChange: PropTypes.func, // {array} - List of selected checkbox values.
};

/**
 * Define component default values for own props
 */
CheckboxListInput.defaultProps = {
	className: '',
	options: [],
	layout: CHECKBOX_LIST_INPUT_LAYOUT.ALIGNED,
	formControlStyle: true,
	isClearable: false,
	checkboxProps: {},
	clearValue: undefined,
};

/**
 * CheckboxListInput component option object
 * @note Use this object to easily create CheckboxListInput component options (passed as 'options' prop). Each option 
 * will be rendered as separate checkbox.
 */
export class CheckboxListInputOptionObject {
	/**
	 * @param {string} label - Checkbox option label rendered for users.
	 * @param {any} value - Checkbox option value returned in 'onChange' event.
	 */
	constructor(label, value) {
		this.label = label;
		this.value = value;
	}
}
export * from "./const";
export default CheckboxListInput;