import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink, withRouter} from "Core/router";
import {getObject, getString} from 'Core/helpers/data';
import {getRouterPathUrl} from 'Core/helpers/url';
import Label from 'Core/components/display/Label';
import * as projectDashboardPage from 'Pages/apps/default/projectPages/dashboard/config';
import {getPageActions} from 'Core/helpers/redux';
import {selectors} from 'Core/store/reducers';
import Icon from 'Core/components/display/Icon';
import {project_navigation_mode} from 'Config/app';
import MenuItemGroupToggleButton from 'Layout/elements/MainSidebar/components/MenuItemGroupToggleButton';
import {openMainSidebarGroupAction} from 'Layout/elements/MainSidebar/actions';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
});

class ProjectMenuSidebarItem extends BaseComponent {
	constructor(props) {
		super(props, {});
		
		// Action methods
		this.hide = this.hide.bind(this);
		
		// Render methods
		this.renderGroupToggleButton = this.renderGroupToggleButton.bind(this);
	}

	/**
	 * Close/hide sidebar
	 */
	hide() {
		const {isMobileBreakpoint, hideMainSidebarAction} = this.props;
		// Main sidebar should only hide on mobile screen sizes.
		if (isMobileBreakpoint) hideMainSidebarAction();
	}

	/**
	 * Render a toggle button of a group menu item
	 * @return {JSX.Element}
	 */
	renderGroupToggleButton() {
		return (
			<MenuItemGroupToggleButton menuItemId={this.getProp('id')} closedIcon="angle-right" openedIcon="angle-down" />
		);
	}
	
	render() {
		const {id, groupItem, openMainSidebarGroupAction} = this.props;
		/** @type {ProjectListItemDataObject} */
		const project = this.getProp('project');
		const routeOptions = getObject(
			projectDashboardPage, 'menuRouterOptions', 
			getObject(projectDashboardPage, 'routerOptions')
		);
		
		return (
			<NavLink
				id={id}
				title={
					`${this.t('page_title', getString(projectDashboardPage, 'translationPath'))}: ` + 
					`${getString(project, 'name')}`
				}
				to={
					project_navigation_mode === 'all_projects' ?
						// Replace router path :projectId with the value from the specified project
						getRouterPathUrl(
							projectDashboardPage.routerPath,
							{params: {projectId: getString(project, 'id')}}
						)
					: project_navigation_mode === 'current_project' ?
						// Replace router path :projectId with the value form session storage (cookie)
						getRouterPathUrl(
							getRouterPathUrl(projectDashboardPage.routerPath, getObject(this.props, 'match')),
							{params: {projectId: getString(project, 'id')}}
						)
					: ''
				}
				onClick={() => {
					openMainSidebarGroupAction(id);
					this.hide();
				}}
				{...routeOptions}
				data-project-id={getString(project, 'id')}
			>
				{project.icon ? <Icon symbol={project.icon} /> : projectDashboardPage.iconElement}
				<Label element="span" content={getString(project, 'name')} />
				{groupItem ? this.renderGroupToggleButton() : null}
			</NavLink>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ProjectMenuSidebarItem.propTypes = {
	id: PropTypes.string,
	groupItem: PropTypes.bool,
	project: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, getPageActions({
	openMainSidebarGroupAction,
}))(ProjectMenuSidebarItem));