import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import {fetchProjectListAction} from 'Actions/project';
import {getGlobalActions} from 'Core/helpers/redux';
import SelectInput from 'Core/components/input/SelectInput';
import {find, omit} from 'lodash';
import PropTypes from 'prop-types';
import {selectors} from 'Core/store/reducers';
import {getArray, getString, getStringForDisplay, isset} from 'Core/helpers/data';
import Label from 'Core/components/display/Label';
import ProjectsACL from 'Acl/projects';
import Spinner from 'Core/components/display/Spinner';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({fetchProjectListAction});

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	projectList: selectors['projectSelector'].getProjectList(state),
});

class ProjectSelectInput extends BaseComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			/** @type {ProjectListItemDataObject[]|null|undefined} */
			projectList: null,
			/** @type {ProjectListItemDataObject[]|null|undefined} */
			fullProjectList: null,
			/** @type {boolean} */
			projectListLoading: false,
		};
		
		// Refs
		this.selectRef = null;
	}
	
	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();
		
		const {projectList, useGlobalProjectList, fetchProjectListAction} = this.props;
		
		executeComponentCallback(this.props.onLoadingStart);
		await this.setState({projectListLoading: true})
			.then(() => {
				// If 'useGlobalProjectList' flag is true, try to get the list of project from Redux store (set by the
				// project selector component in the app's header) instead of fetching it from IO
				if (useGlobalProjectList) {
					// Use project list if it is already loaded into Redux store
					if (isset(projectList) && projectList !== null) {
						return Promise.resolve(projectList);
					}
					// Fetch the product list from IO if it is not yet loaded into Redux store
					else {
						return this.executeAbortableActionMount(fetchProjectListAction);
					}
				}
				// If 'useGlobalProjectList' flag is false, fetch the product list from IO
				else {
					return this.executeAbortableActionMount(fetchProjectListAction);
				}
			})
			// Save full project list to local state, so it can be used to determine single project since filtered projects
			// should also be counted when calculating single project
			.then(projectList => this.setState({fullProjectList: projectList}).then(() => projectList))
			// Remove non-editable projects from project list if 'editableProjectsOnly' prop is set to true
			.then(projectList => getArray(projectList).filter(
				/**
				 * @param {ProjectListItemDataObject} p
				 * @return {boolean}
				 */
				p => {
					const {editableProjectsOnly} = this.props;
					return (
						!editableProjectsOnly || 
						ProjectsACL.checkPermission(ProjectsACL, p.id, ['CAMPAIGN_WRITE', 'CAMPAIGN_PUBLISH'])
					);
				})
			)
			.then(projectList => {
				// Automatically select the project if there is only one project in the list and 'autoSelectSingleProject'
				// is set to true
				if (!!this.getProp('autoSelectSingleProject') && getArray(projectList).length === 1) {
					if (this.selectRef) this.selectRef.onChange(projectList[0]);
				}
				return this.setState({projectList});
			})
			.finally(() => {
				const {fullProjectList} = this.state;
				
				return this.setState({projectListLoading: false})
					.then(state => {
						const projects = getArray(fullProjectList);
						const singleProject = (projects.length === 1);
						executeComponentCallback(this.props.onLoadingEnd, (singleProject ? projects[0] : null));
						return state;
					});
			});
	}
	
	render() {
		const {additionalOptionsAbove, additionalOptionsBelow, value, readOnly, isMulti, filterOptions} = this.props;
		const {projectList, projectListLoading} = this.state;
		let options = !!projectList ?
			[
				...getArray(additionalOptionsAbove),
				...projectList,
				...getArray(additionalOptionsBelow),
			]
			:
			[
				...getArray(additionalOptionsAbove),
				...getArray(additionalOptionsBelow),
			];
		const selectValues = (
			isMulti ?
				getArray(options).filter(o => getArray(value).includes(o.id)) :
				[find(options, {id: value})]
		);
		
		// Filter options by a custom filter function is specified in props
		if (typeof filterOptions === 'function') options = options.filter(filterOptions);
		
		return (
			!readOnly ?
				<SelectInput
					{...omit(
						this.props, 
						[
							'useGlobalProjectList', 'projectList', 'autoSelectSingleProject', 'onLoadingStart', 'onLoadingEnd',
							'additionalOptionsAbove', 'additionalOptionsBelow', 'readOnly',
							...Object.keys(allActions)
						]
					)}
					primaryKey="id"
					options={options}
					isLoading={projectListLoading}
					getOptionValue={o => o.id}
					getOptionLabel={o => o.name}
					ref={node => { this.selectRef = node; }}
				/>
				:
				projectListLoading ?
					<Spinner />
					:
					selectValues.length > 1 ?
						selectValues.map(v =>
							<Label
								key={v.id}
								element="span"
								elementProps={{className: 'tag'}}
								content={getStringForDisplay(getString(v, 'name'))}
							/>
						)
						:
						<Label content={getStringForDisplay(getString(selectValues, '[0].name'))} />
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ProjectSelectInput.propTypes = {
	...SelectInput.propTypes,
	
	// Flag that specifies if this component will try to get the list of project from Redux store (set by the project 
	// selector component in the app's header) instead of fetching it from IO
	useGlobalProjectList: PropTypes.bool,
	// Automatically select the project if there is only one project in the list
	autoSelectSingleProject: PropTypes.bool,
	// Options to add above the list of projects in the select options dropdown
	additionalOptionsAbove: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
	})),
	// Options to add below the list of projects in the select options dropdown
	additionalOptionsBelow: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
	})),
	// Function for filtering options
	// @description Function is used as a standard JS 'filter' function so it accepts a single option and should return 
	// true if options should be included in the result list.
	// @type {function(options: ProjectListItemDataObject): boolean}
	filterOptions: PropTypes.func,
	// Flag that determines if component will be rendered in read-only mode (only selected option label will be rendered)
	readOnly: PropTypes.bool,
	// Specifies whether only editable projects will be added to the select options
	// @note Project is editable if current user can create/update or publish campaigns in it.
	editableProjectsOnly: PropTypes.bool,

	// Event triggered when list of project is started loading
	// No arguments
	onLoadingStart: PropTypes.func,
	// Event triggered when list of project is done loading
	// Arguments: {?ProjectListItemDataObject} singleProject
	onLoadingEnd: PropTypes.func,
};

/**
 * Define component default values for own props
 */
ProjectSelectInput.defaultProps = {
	...SelectInput.defaultProps,

	useGlobalProjectList: false,
	autoSelectSingleProject: false,
};

export default connect(mapStateToProps, allActions, null, {forwardRef: true})(ProjectSelectInput);