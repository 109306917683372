import {set} from 'lodash';

// Import individual reducers here
import * as mainSidebar from "Layout/elements/MainSidebar/reducer";
import * as projectSelector from "Layout/elements/ProjectSelector/reducer";
import * as customersPage from "Pages/apps/default/customers/reducer";
import * as customerCustomFieldsPage from 'Pages/apps/default/customerCustomFields/reducer';
import * as blacklistsPage from "Pages/apps/default/blacklists/reducer";
import * as blacklistCustomers from "Pages/apps/default/blacklists/popups/ItemPopup/tabs/CustomersTab/reducer";
import * as statistics from "Pages/apps/default/statistics/reducer";
import * as projectCampaignPage from "Pages/apps/default/projectPages/campaign/reducer";
import * as recipientsTab from "Pages/apps/default/projectPages/campaign/pages/ItemPage/popups/MessageDefinitionPopup/tabs/RecipientsTab/reducer";
import * as importCustomersFromList from "Components/advanced/ImportCustomersFromList/reducer";
import * as customerSegmentPreviewDialog from "Components/dialogs/CustomerSegmentPreviewDialog/reducer";
import * as tags from "Components/dialogs/InsertValueDialog/sections/TagInsertSection/reducer";
import * as PushNotificationCustomersSearch from "Components/advanced/PushNotificationCustomersSearch/reducer";
import * as projectCappingPage from "Pages/apps/default/projectPages/capping/reducer";
import * as messagesPage from "Pages/apps/default/messages/reducer";
import * as messageDeliveriesPage from "Pages/apps/default/messageDeliveries/reducer";
import * as projectCustomersPage from "Pages/apps/default/projectPages/customers/reducer";

// All imported reducers must be added to this array
const reducerImports = [
	mainSidebar, projectSelector, customersPage, customerCustomFieldsPage, blacklistsPage, blacklistCustomers, 
	statistics, projectCampaignPage, recipientsTab, importCustomersFromList, customerSegmentPreviewDialog, tags, 
	PushNotificationCustomersSearch, projectCappingPage, messagesPage, messageDeliveriesPage, projectCustomersPage,
];




// ---------------------------------------------------------------------------------------------------------------------
// Don't change anything below this line -------------------------------------------------------------------------------
let importedReducers = {};
let importedSelectors = {};
let importedActionCreators = {};
// Combine all imported reducers. Imported reducers must export 'reducerStoreKey' constant containing unique store key
// associated to that reducer.
reducerImports.forEach(reducerImport => {
	set(importedReducers, reducerImport.reducerStoreKey, reducerImport.default);
	set(importedSelectors, reducerImport.reducerStoreKey, reducerImport.selectors);
	set(importedActionCreators, reducerImport.reducerStoreKey, reducerImport.actionCreators);
});

// Combined selectors and action creators
export { importedReducers as reducers, importedSelectors as selectors, importedActionCreators as actionCreators };