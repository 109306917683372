import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import PropTypes from "prop-types";
import {getString} from 'Core/helpers/data';
import TextInput, {PasswordStrengthPreview, TEXT_INPUT_LABEL_POSITION} from 'Core/components/input/TextInput';
import {omit} from 'lodash';

class FormPasswordInput extends BaseComponent {
	constructor(props) {
		super(props, {
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onChange'],
		});
	}
	
	render() {
		const {
			/** @type {FormQuestionDataObject} */
			data, showEmojiPreview, onChange, ...otherProps
		} = this.props;
		
		return (
			<TextInput
				type="password"
				showLabel={showEmojiPreview}
				labelClass="no-padding"
				labelWidth={50}
				labelPosition={TEXT_INPUT_LABEL_POSITION.RIGHT}
				previewAsLabel={true}
				getPreview={PasswordStrengthPreview}
				autoComplete="new-password"
				updateWhenChangedWithoutFocus={true}
				onChange={e => executeComponentCallback(onChange, getString(e, 'target.value'))}
				{...omit(otherProps, [
					'type', 'showLabel', 'labelClass', 'labelWidth', 'labelPosition', 'previewAsLabel', 'getPreview', 
					'autoComplete', 'updateWhenChangedWithoutFocus', 
				])}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FormPasswordInput.propTypes = {
	// Question form element data
	// @type {FormQuestionDataObject}
	data: PropTypes.object,
	// Selected value (usually by the user) that will be submitted
	value: PropTypes.any,
	// Event triggered when input component value is changed (usually by the user)
	// @type {function(string)}
	onChange: PropTypes.func,
	// Flag that specifies if emoji preview with password strength will be rendered
	showEmojiPreview: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
FormPasswordInput.defaultProps = {
	showEmojiPreview: true,
};

export default FormPasswordInput;