/**
 * Abstract app component used to create app main components (like DefaultApp for example)
 */

import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import {cloneDeep} from "lodash";
import {getBool, getObject, getString} from "Core/helpers/data";
import {appHasLogin} from "Core/helpers/login";
import auth from "../../auth";
import {app_login_page_router_path} from "Config/app";
import {hideLoading, showAppsLoading} from "Core/helpers/loading";
import {Route, Switch} from "Core/router";
import {AppPageRoute} from "Core/App";
import {redirectToPath} from "Core/helpers/url";
import {auth_broadcast_channel, auth_broadcast_message_logout, check_login_when_idle} from "Config/auth";
import {
	getLastUserActivityTime,
	isUserActivityCookieAllowed,
	updateLastUserActivityTime
} from "Core/helpers/userActivity";
import {AsyncMountError} from "Core/errors";
import AppsNotFoundPage from "Core/pages/error/appsNotFound";

class AppSectionComponent extends BaseComponent {
	/**
	 * Auth broadcast channel instance
	 * @type {BroadcastChannel}
	 */
	authBroadcastChannel = null;

	/**
	 * User activity interval ID
	 */
	userActivityIntervalId;
	
	/**
	 * App component constructor
	 * @param {object} props - Component props.
	 * @param {Object} appConfig - Imported app config.
	 * @param {AppComponentOptions} [options={}] - Component options from child class that will override the default
	 * options.
	 * @param {string} [appName='default-app'] - App name.
	 */
	constructor(props, appConfig, options = {}, appName = 'default-app') {
		/**
		 * @type {AppComponentOptions}
		 * @private
		 */
		const _options = {
			/**
			 * Set dome prefix to be the app name
			 * @note This will use the value form the constructor. Specify it manually only if you need to overwrite the 
			 * default functionality.
			 * @type {string}
			 */
			domPrefix: appName,

			/**
			 * Path inside the translation JSON file where component translations are defined
			 * @note This will use the value form the constructor. Specify it manually only if you need to overwrite the
			 * default functionality.
			 * @type {string}
			 */
			translationPath: getString(appConfig, 'translationPath'),

			/**
			 * Imported app config
			 * @note This will use the value form the constructor. Specify it manually only if you need to overwrite the 
			 * default functionality.
			 * @type {Object}
			 */
			appConfig,

			/**
			 * Imported app pages
			 * @type {Object[]}
			 */
			appPages: [],
			
			/**
			 * Flag that specifies if login is required to access this app
			 * @type {boolean}
			 */
			checkLogin: false,

			/**
			 * Flag that specifies if user will be redirected to login page on logout on any browser tab
			 * @type {boolean}
			 */
			autoLogout: false,

			...cloneDeep(options)
		};

		super(props, _options);

		// Initialize initial state
		this.initialState = {
			/**
			 * Flag showing if login check is in progress
			 * @note The initial value for this flag is set here, in the constructor, because rendering the app depends on
			 * this value, so we need to set it before the first render call. Login check is initiated by default if 
			 * 'checkLogin' option is set to true.
			 */
			checkingLogin: getBool(options, 'checkLogin', _options.checkLogin),
		};

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// ACL methods
		this.checkLogin = this.checkLogin.bind(this);

		// Router methods
		this.redirectTo = this.redirectTo.bind(this);
		
		// render methods
		this.renderPages = this.renderPages.bind(this);
		this.renderApp = this.renderApp.bind(this);
	}
	
	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in
	 * your 'componentDidMount'.
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 * @return {Promise<number|void>} Promise that will resolve with the updated mount count that will be set in the 
	 * 'componentDidMount' method or undefined for default functionality where 'componentDidMount' will just reset the 
	 * mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	async asyncComponentDidMount() {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount();

		// Check login
		await this.checkLogin();

		return Promise.resolve();
	}
	
	componentDidMount() {
		super.componentDidMount();
		
		if (this.getOption('autoLogout')) {
			// Listen to log out broadcast message and redirect to login page if 'autoLogout' option in set to true
			this.authBroadcastChannel = new BroadcastChannel(auth_broadcast_channel);
			this.authBroadcastChannel.onmessage = event => {
				// Handle logout message
				if (getString(event, 'data') === auth_broadcast_message_logout) {
					redirectToPath(app_login_page_router_path);
				}
			}

			// Auto check login when user is inactive for a specified (check_login_when_idle config option) period of time
			this.userActivityIntervalId = setInterval(() => {
				if (isUserActivityCookieAllowed() && check_login_when_idle > 0) {
					const lastActivityTime = getLastUserActivityTime();

					// Auto check login and log out the user if needed
					if (
						lastActivityTime > 0 &&
						Math.floor((Date.now() - lastActivityTime) / 1000) >= check_login_when_idle
					) {
						// Update the last activity so that the interval is reset, and it continues to check for user
						// inactivity while also making sure that only one tab will actually check login (the first 
						// one that runs this interval)
						updateLastUserActivityTime();

						// Check login
						// @note The check method itself will log out the user if needed
						this.executeAbortableAction(auth.checkLogin, true, undefined, undefined, auth).then();
					}
				} else {
					clearInterval(this.userActivityIntervalId);
				}
			}, 1000);
		}
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
		
		// Close user broadcast channel that was open when component mounted
		if (this.authBroadcastChannel) this.authBroadcastChannel.close();
		// Clear user activity interval
		if (this.userActivityIntervalId) clearInterval(this.userActivityIntervalId);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// ACL methods ------------------------------------------------------------------------------------------------------
	/**
	 * Check if user is logged in
	 * @note This method will log out the user and redirect to the login page if check fails. This check should be done
	 * on each individual section app page because page components (child components) are mounted before app and section
	 * components (parent components) and we need to display the loading overlay as soon as possible. Checking will be
	 * performed only if 'checkLogin' component option flag is set to true.
	 *
	 * @return {Promise<any|'LOGIN_FAILED'>}
	 * @throws {AsyncMountError}
	 */
	checkLogin() {
		if (this.getOption('checkLogin') === true) {
			if (appHasLogin()) {
				// If there are no auth tokens present, login will automatically fail
				if (!auth.hasTokens()) {
					// Logout
					return auth.logout().then(() => {
						// Redirect to login page
						redirectToPath(app_login_page_router_path);
						// Returning the special value that can be used to pause methods being called after checking the login
						return 'LOGIN_FAILED';
					});
				}

				const loading = showAppsLoading(true, true);

				// Check the login by using the authorized ping request
				return this.executeAbortableAction(auth.authorizedPing, true, undefined, undefined, auth)
					// If authorized ping was successful just hide the loading overlay
					.then(() => { if (loading) hideLoading(loading); })
					// Reset 'checkingLogin' flag so that the page can render the layout content
					// @note This flag is initialized in the constructors which means that it has already been set to true 
					// if needed.
					.then(() => this.setState({checkingLogin: false}))
					// If authorized ping was not successful, hide the loading overlay and logout
					.catch(error => {
						if (loading) hideLoading(loading);
						if (error.name !== 'AbortError') {
							// Logout
							return auth.logout().then(() => {
								// Redirect to login page
								redirectToPath(app_login_page_router_path);
								// Returning the special value that can be used to pause methods being called after checking the
								// login
								return 'LOGIN_FAILED';
							});
						}
						throw new AsyncMountError('Login check aborted!');
					});
			} else {
				// Reset 'checkingLogin' flag so that the app can render
				// @note This flag is initialized in the constructors and in this case (when 'appHasLogin' returns false) it
				// should not have been set to true. Resetting the flag is just a precaution.
				return this.setState({checkingLogin: false});
			}
		}
		return Promise.resolve();
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Redirect to any router path
	 * @param {string} to - Router path (like react router Link component 'to' prop).
	 */
	redirectTo(to) { this.getProp('history').push(to); }
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		const {checkingLogin} = this.state;
		return (checkingLogin !== true);
	}

	/**
	 * Render app app pages
	 * @return {*}
	 */
	renderPages() {
		const appPages = this.getOption('appPages');
		return (appPages.map((page, idx) =>
			AppPageRoute({
				key: idx,
				page,
				...getObject(page, 'routerOptions')
			})
		));
	}
	
	/**
	 * Render app
	 * @param {any} [additionalContent=null] - Any additional content besides app pages that will be rendered.
	 * @return {JSX.Element}
	 */
	renderApp(additionalContent = null) {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		
		const appRouterPath = getString(this.getOption('appConfig'), 'routerPath');
		
		return (
			<div id={this.getDomId()}>
				{
					additionalContent && appRouterPath ?
						<Route path={appRouterPath} exact={false}>{additionalContent}</Route>
						: null
				}
				<Switch>
					{this.renderPages()}
					<Route path="/">
						<AppsNotFoundPage app={this.getOption('appConfig')} />
					</Route>
				</Switch>
			</div>
		);
	}
}

// Type definitions
/**
 * @typedef {Object} AppComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined. This will be passed form the constructor. Specify it manually only if you need to overwrite the default
 * functionality.
 * @property {string} [domPrefix=] - Prefix used for component's main DOM element. This is used in methods like 
 * 'getDomId'. This will be passed form the constructor. Specify it manually only if you need to overwrite the default 
 * functionality.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {string[]} [optimizedUpdateIgnoreProps] - List of prop names that will be ignored during optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all props.
 * @property {string[]} [optimizedUpdateIncludeState] - List of state values that will be included in optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all state fields.
 * @property {boolean} [updateOnSkinChange=false] - Flag that specifies if component will update when app skin has been
 * changes (for example from light to dark).
 * @property {string[]} [dialogsToCloseOnUnmount=[]] - List of dialog GUI IDs of the dialogs that should be closed when
 * page component unmounts.
 * @property {Object} [appConfig] - Imported app config. This will be passed form the constructor. Specify it manually 
 * only if you need to overwrite the default functionality.
 * @property {Object[]} [appPages=[]] - Imported app pages.
 * @property {boolean} [checkLogin=false] - Flag that specifies if login is required to access this app.
 * @property {boolean} [autoLogout=false] - Flag that specifies if user will be redirected to login page on logout on 
 * any browser tab.
 */

export default AppSectionComponent;