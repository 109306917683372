/** @typedef {string} FormElementType */
export const FORM_ELEMENT_TYPE_QUESTION = 'QUESTION';
export const FORM_ELEMENT_TYPE_HTML_TEXT = 'HTML_TEXT';
/** @enum {FormElementType} */
export const FORM_ELEMENT_TYPE = {
	QUESTION: FORM_ELEMENT_TYPE_QUESTION,
	HTML_TEXT: FORM_ELEMENT_TYPE_HTML_TEXT,
};
/** @type {FormElementType[]} */
export const FORM_ELEMENT_TYPES = [
	FORM_ELEMENT_TYPE_QUESTION,
	FORM_ELEMENT_TYPE_HTML_TEXT,
];


/** @typedef {string} FormQuestionInputType */
export const FORM_QUESTION_INPUT_TYPE_TEXT = 'TEXT';
export const FORM_QUESTION_INPUT_TYPE_TEXTAREA = 'TEXTAREA';
export const FORM_QUESTION_INPUT_TYPE_PASSWORD = 'PASSWORD';
export const FORM_QUESTION_INPUT_TYPE_EMAIL = 'EMAIL';
export const FORM_QUESTION_INPUT_TYPE_RADIOBUTTON = 'RADIOBUTTON';
export const FORM_QUESTION_INPUT_TYPE_SELECT = 'SELECT';
export const FORM_QUESTION_INPUT_TYPE_CHECKBOX = 'CHECKBOX';
export const FORM_QUESTION_INPUT_TYPE_DATE = 'DATE';
export const FORM_QUESTION_INPUT_TYPE_DATETIME = 'DATETIME';
export const FORM_QUESTION_INPUT_TYPE_TIME = 'TIME';
export const FORM_QUESTION_INPUT_TYPE_FILE = 'FILE';
/** @enum {FormQuestionInputType} */
export const FORM_QUESTION_INPUT_TYPE = {
	TEXT: FORM_QUESTION_INPUT_TYPE_TEXT,
	TEXTAREA: FORM_QUESTION_INPUT_TYPE_TEXTAREA,
	PASSWORD: FORM_QUESTION_INPUT_TYPE_PASSWORD,
	EMAIL: FORM_QUESTION_INPUT_TYPE_EMAIL,
	RADIOBUTTON: FORM_QUESTION_INPUT_TYPE_RADIOBUTTON,
	SELECT: FORM_QUESTION_INPUT_TYPE_SELECT,
	CHECKBOX: FORM_QUESTION_INPUT_TYPE_CHECKBOX,
	DATE: FORM_QUESTION_INPUT_TYPE_DATE,
	DATETIME: FORM_QUESTION_INPUT_TYPE_DATETIME,
	TIME: FORM_QUESTION_INPUT_TYPE_TIME,
	FILE: FORM_QUESTION_INPUT_TYPE_FILE,
};
/** @type {FormQuestionInputType[]} */
export const FORM_QUESTION_INPUT_TYPES = [
	FORM_QUESTION_INPUT_TYPE_TEXT,
	FORM_QUESTION_INPUT_TYPE_TEXTAREA,
	FORM_QUESTION_INPUT_TYPE_PASSWORD,
	FORM_QUESTION_INPUT_TYPE_EMAIL,
	FORM_QUESTION_INPUT_TYPE_RADIOBUTTON,
	FORM_QUESTION_INPUT_TYPE_SELECT,
	FORM_QUESTION_INPUT_TYPE_CHECKBOX,
	FORM_QUESTION_INPUT_TYPE_DATE,
	FORM_QUESTION_INPUT_TYPE_DATETIME,
	FORM_QUESTION_INPUT_TYPE_TIME,
	FORM_QUESTION_INPUT_TYPE_FILE,
];