import styles from "./index.module.css";
import "./index.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {isFunction, omit} from "lodash";
import {getArray, getString} from "Core/helpers/data";
import {openDialog} from "Core/helpers/dialog";
import Button, {
	BUTTON_DISPLAY_TYPE, BUTTON_DISPLAY_TYPES, BUTTON_STYLE, BUTTON_STYLES
} from "Core/components/display/Button";
import mime from "mime-types";
import MessageDialog from "Core/components/dialogs/MessageDialog";
import {MESSAGE_STYLE} from "Core/components/global/Message/const";
import {icon_font_close_symbol} from "Config/app";
import {getCssSizeString} from "Core/helpers/dom";
import {Tooltip} from "Core/components/global/Tooltip";
import Label from "Core/components/display/Label";

/**
 * File input component
 * @description Basic file input component supported by the DataComponent 'handleFileInputChange' method.
 * @note This is a controlled component which means it does not maintain its own state and value is controlled by the
 * parent component.
 */
class FileInput extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'FileInput',
			domPrefix: 'file-input-component',
		});

		// Validation
		this.validateFiles = this.validateFiles.bind(this);

		// Data change handling methods
		this.handleChange = this.handleChange.bind(this);

		// DOM methods
		this.clear = this.clear.bind(this);
		
		// Render methods
		this.renderInput = this.renderInput.bind(this);
		this.renderFiles = this.renderFiles.bind(this);
		this.renderImagePreviews = this.renderImagePreviews.bind(this);
		this.renderPreview = this.renderPreview.bind(this);
	}

	
	// Validation -------------------------------------------------------------------------------------------------------
	/**
	 * Validate mime-type and size of all files
	 * @param {File[]} files - Array of input files.
	 */
	validateFiles(files) {
		const {maxSize} = this.props;
		
		for (let i = 0; i < files.length; i++) {
			// Validate file size
			if (maxSize > 0 && files[i].size > maxSize) return false;
		}
		
		return true;
	}


	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Handle file input change
	 * @note This method will trigger 'onChange' event if component is not disabled.
	 * @param {Event} event - DOM element's event object.
	 */
	handleChange(event) {
		const {fieldName, additionalData, disabled, maxSize} = this.props;
		
		if (!disabled) {
			if (this.validateFiles(event.target?.files || [])) {
				executeComponentCallback(this.props.onChange, event, fieldName, additionalData);
			} else {
				this.clear();
				openDialog(
					'', 
					MessageDialog,
					{
						message: {
							style: MESSAGE_STYLE.ERROR,
							content: this.t(
								'Selected file is too big! Maximum file size is %{bytes} bytes.',
								undefined, 
								undefined, 
								{bytes: `${maxSize}`}
							)
						},
						allowHtml: true,
					}, {
						closeOnClickOutside: true,
						closeOnEscape: true,
						hideCloseBtn: true,
						maxWidth: 500
					}
				);
			}
		}
	}


	// DOM methods ------------------------------------------------------------------------------------------------------
	/**
	 * Clear file input value
	 * @note This method can be called by the 'clearFileInputValue' method of the DataComponent or any other 
	 * component extending DataComponent.
	 */
	clear() { if (this.wrapperRef) this.wrapperRef.value = ''; }
	

	// Render methods ---------------------------------------------------------------------------------------------------
	renderInput() {
		const {
			className, formControlStyle, name, acceptExtensions, convertExtensions, multiple, disabled, buttonOnlyProps
		} = this.props;
		
		return (
			<input
				type="file"
				id={this.getDomId()}
				className={
					`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${styles['input']} ` +
					`${className}`
				}
				name={name}
				title={getString(buttonOnlyProps, 'title')}
				multiple={multiple}
				disabled={disabled}
				accept={acceptExtensions.map(e => {
					if (convertExtensions.includes(e)) return mime.lookup(e);
					else return e;
				}).join(', ')}
				onChange={this.handleChange}
				ref={this.setWrapperRef}
			/>
		);
	}

	/**
	 * Render specified files section
	 * @return {JSX.Element|null}
	 */
	renderFiles() {
		const {transparentFiles} = this.props;
		/** @type {FileInputFileDataObject[]} */
		const files = getArray(this.props, 'files');
		
		return (
			files.length > 0 ?
				<div 
					className={
						`files ${styles['files']} ${transparentFiles ? `transparent-background ${styles['transparent']}`:''}`
					}
				>
					{files.map((f, fIdx) =>
						<div key={fIdx} className={`file ${styles['file']}`}>
							{f.elem}
						</div>
					)}
				</div>
				: null
		);
	}
	
	/**
	 * Render selected and specified image previews section
	 * @return {JSX.Element|null}
	 */
	renderImagePreviews() {
		const {showImgPreviews, imgPreviewWidth, imgPreviewHeight, multiple, transparentPreview} = this.props;
		const fileList = this.wrapperRef?.files;
		const imageFiles = fileList ? Array.from(fileList).filter(f => f.type.startsWith('image/')) : [];
		/** @type {FileInputImgPreviewDataObject[]} */
		const imagePreviews = getArray(this.props, 'imagePreviews');
		const noPreviews = (imagePreviews.length === 0 && imageFiles.length === 0);
		
		return (
			showImgPreviews ?
				<div 
					className={
						`image-previews ${styles['imgPreviews']} ${noPreviews ? styles['noPreviews'] : ''} ` +
						`${transparentPreview ? `transparent-background ${styles['transparent']}` : ''}`
					}
				>
					{imagePreviews.map((p, pIdx) =>
						<div key={pIdx} className={`image-preview ${styles['imgPreview']}`}>
							{p.name ?
								<Tooltip
									key={pIdx}
									tag="span"
									title={p.name}
									size="small"
									position="top-center"
									arrow={true}
									interactive={false}
									style={{
										width: getCssSizeString(imgPreviewWidth),
										height: getCssSizeString(imgPreviewHeight),
									}}
								>
									{p.img}
								</Tooltip>
							:
							p.img}
						</div>
					)}

					{imageFiles.map((i, idx) => (
						<div key={idx} className={`image-preview ${styles['imgPreview']}`}>
							{i.name ?
								<Tooltip
									key={idx}
									tag="span"
									title={i.name}
									size="small"
									position="top-center"
									arrow={true}
									interactive={false}
									style={{
										width: getCssSizeString(imgPreviewWidth),
										height: getCssSizeString(imgPreviewHeight),
									}}
								>
									<img
										src={URL.createObjectURL(i)} alt={i.name}
										onLoad={e => URL.revokeObjectURL(e.target.getAttribute('src'))}
									/>
								</Tooltip>
							: i.name}
						</div>
					))}
					
					{
						noPreviews ?
							<Label
								content={this.t(multiple ? 'multi_preview_notice' : 'single_preview_notice')}
								element="div"
								elementProps={{
									className: `no-img-preview ${styles['noImgPreview']}`,
									style: {
										width: getCssSizeString(imgPreviewWidth),
										height: getCssSizeString(imgPreviewHeight),
									}
								}}
							/>
							: null
					}
				</div>
			: null
		);
	}

	/**
	 * Render custom preview if enabled
	 * @return {JSX.Element|null}
	 */
	renderPreview() {
		const {showPreview, getPreview} = this.props;
		const fileList = this.wrapperRef?.files;
		const preview = (isFunction(getPreview) ? getPreview(fileList) : null);
		
		return (
			showPreview && preview ? <div className={`preview ${styles['preview']}`}>{preview}</div> : null
		);
	}
	
	render() {
		const {
			className, buttonOnly, buttonOnlyProps, showClearButton, clearButtonProps, showImgPreviews, showPreview, 
			getPreview, disabled
		} = this.props;
		/** @type {FileInputFileDataObject[]} */
		const files = getArray(this.props, 'files');
		const fileList = this.wrapperRef?.files;
		const preview = (isFunction(getPreview) ? getPreview(fileList) : null);
		
		return (
			buttonOnly ?
				<div 
					id={this.getDomId()}
					className={
						`${className ? `${className}-wrapper` : ''} ${styles['buttonOnlyWrapper']} ${styles['wrapper']} ` +
						`${this.getOption('domPrefix')} ` +
						`${showImgPreviews || (showPreview && preview) ? `has-preview ${styles['hasPreview']}` : ''} ` +
						`${files.length > 0 ? `files ${styles['files']}` : ''} `
					}
				>
					<div className={`input-overlay ${styles['inputOverlay']}`}>
						<Button
							className={`${getString(buttonOnlyProps, 'className')} ${styles['button']}`}
							icon={getString(buttonOnlyProps, 'icon', 'upload')}
							{...omit(buttonOnlyProps, ['className', 'icon', 'title'])}
						/>
						{this.renderInput()}
					</div>
					{
						showClearButton ?
							<Button
								className={`${styles['clearButton']} ${getString(clearButtonProps, 'className')}`}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								icon={icon_font_close_symbol}
								onClick={() => {
									this.clear();

									// Trigger change
									let ev = new Event('change', { bubbles: true });
									ev.simulated = true;
									this.wrapperRef.dispatchEvent(ev);
								}}
								disabled={disabled}
								{...omit(clearButtonProps, ['onClick', 'className', 'disabled'])}
							/>
							: null
					}
					{this.renderFiles()}
					{this.renderImagePreviews()}
				</div>
				:
				<div
					id={this.getDomId()}
					className={
						`${className ? `${className}-wrapper` : ''} ${styles['fullInputWrapper']} ${styles['wrapper']} ` +
						`${this.getOption('domPrefix')} ` +
						`${showImgPreviews || (showPreview && preview) ? `has-preview ${styles['hasPreview']}` : ''} ` +
						`${files.length > 0 ? `files ${styles['files']}` : ''} `
					}
				>
					{this.renderInput()}
					{
						showClearButton ?
							<Button
								className={`${styles['clearButton']}`}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={icon_font_close_symbol}
								onClick={() => {
									this.clear();

									// Trigger change
									let ev = new Event('change', { bubbles: true });
									ev.simulated = true;
									this.wrapperRef.dispatchEvent(ev);
								}}
								disabled={disabled}
								{...omit(clearButtonProps, ['onClick', 'className', 'disabled'])}
							/>
							: null
					}
					{this.renderFiles()}
					{this.renderImagePreviews()}
					{this.renderPreview()}
				</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FileInput.propTypes = {
	// Input element's id attribute
	id: PropTypes.string,
	// Input element's class attribute
	className: PropTypes.string,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// Input element's name attribute
	name: PropTypes.string,
	// Field name in returned FormData where file(s) will be stored
	fieldName: PropTypes.string,
	// Additional FormData values that will be added to the result
	additionalData: PropTypes.object,
	// Specify supported file extensions
	// @description List of extension (with the dot '.' prefix) that should be allowed.
	// @note If empty or not specified, all file types will be accepted. Mime-types will be calculated automatically. 
	acceptExtensions: PropTypes.arrayOf(PropTypes.string),
	// Subset of extensions from 'acceptExtensions' that will be converted into appropriate mime-type
	convertExtensions: PropTypes.array,
	// Maximal supported file size (in bytes) tha can be uploaded
	// @none If 0 or not specified, no file size restriction will be applied by this component. Please note that backend
	// size restrictions may apply regardless of this value.
	maxSize: PropTypes.number,
	// Flag that specifies if multiple file upload is allowed
	multiple: PropTypes.bool,
	// Flag that determines if the input should be disabled
	disabled: PropTypes.bool,
	// Flag that determines if only custom upload button will be visible instead of input
	buttonOnly: PropTypes.bool,
	// Custom upload button props if 'buttonOnly' prop is true
	buttonOnlyProps: PropTypes.shape({
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is passed
		// to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,

		// Events
		onClick: PropTypes.func,
	}),
	// Flag that determines if clear button will be rendered
	// @note This only works if 'buttonOnly' props is false.
	showClearButton: PropTypes.bool,
	// Clear button component props
	clearButtonProps: PropTypes.object,
	// Flag that specifies if preview section will be shown
	showPreview: PropTypes.bool,
	// Function receives internal component data and should return a preview
	// @note By default it will return the current data.
	// @type {Function<Array>: any}
	getPreview: PropTypes.func,
	// TODO: Flag that determines if revert button will be rendered
	// showRevertButton: PropTypes.bool,
	// TODO: revert button component props
	// revertClearButtonProps: PropTypes.object,
	// Flag that determines if previews of the selected images will be shown
	showImgPreviews: PropTypes.bool,
	// List of image previews to show
	// @note Previews from the selected files will be added to this list before rendering all image previews. Clearing 
	// file input will not remove previews defined in this prop. This prop will be ignored if 'showImgPreviews' is false.
	// @type {FileInputImgPreviewDataObject[]}
	imagePreviews: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		img: PropTypes.element,
	})),
	// Width of the single image preview
	// @note This is only relevant if 'showImgPreviews' is true and selected file is an image.
	imgPreviewWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	// Height of the single image preview
	// @note This is only relevant if 'showImgPreviews' is true and selected file is an image.
	imgPreviewHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	// List of files to show
	// @note Clearing file input will not remove files list defined in this prop.
	// @type {FileInputFileDataObject[]}
	files: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		elem: PropTypes.element,
	})),
	// Flag that specifies if files section background will be represented with transparent grid
	transparentFiles: PropTypes.bool,
	// Flag that specifies if preview section background will be represented with transparent grid
	transparentPreview: PropTypes.bool,

	// Value change event
	// @description This event will be triggers when input value changes (file is selected).
	onChange: PropTypes.func, // Arguments: {Event} event, {string} fieldName, {Object} additionalData
};

/**
 * Define component default values for own props
 */
FileInput.defaultProps = {
	className: '',
	formControlStyle: true,
	name: '',
	fieldName: 'files',
	additionalData: {},
	acceptExtensions: [],
	convertExtensions: [],
	maxSize: 0,
	multiple: false,
	disabled: false, 
	buttonOnly: false,
	showClearButton: false,
	clearButtonProps: {},
	showPreview: false,
	getPreview: fileList => null,
	showImgPreviews: false,
	imgPreviewWidth: 'auto',
	imgPreviewHeight: 'auto',
	transparentFiles: false,
	transparentPreview: false,
};

export * from "./dataObjects";
export default FileInput;