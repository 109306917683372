import "./index.css";
import styles from "./index.module.css";

import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import * as pageConfig from "./config";
import {withRouter} from "Core/router";
import {connect} from 'react-redux';
import Label from 'Core/components/display/Label';
import localeList from '../../../i18n/locale';
import {find, get, orderBy} from 'lodash';
import {default_country, icon_font_error_symbol} from 'Config/app';
import I18n from 'Core/i18n';
import {getPageActions} from 'Core/helpers/redux';
import * as actions from './actions';
import Icon from 'Core/components/display/Icon';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {isMacintosh} from 'Core/helpers/system';
import {getArray, getString, isset} from 'Core/helpers/data';
import {FORM_ELEMENT_TYPE, FORM_QUESTION_INPUT_TYPE} from 'Pages/site/form/const';
import FormWrapper from 'Core/components/advanced/FormWrapper';
import DataValueValidation from 'Core/validation';
import FormQuestionElement from 'Pages/site/form/elements/FormQuestionElement';
import FormHtmlTextElement from 'Pages/site/form/elements/FormHtmlTextElement';
import {StandardJsonResponseError} from 'Core/errors';
import {getIOUrl, getStandardJsonResponseErrorMessage} from 'Core/io/helper';
import {scrollToSelector} from 'Core/helpers/dom';

class FormPage extends PageDataComponent {
	i18n = null;
	
	constructor(props) {
		super(props, {
			/**
			 * Form answers populated by the user
			 * @type {Object<string, any>} Key is a DB ID of question and value is the user answer.
			 */
			data: {},
			
			/**
			 * Data needed to render the form 
			 * @type {?FormDataObject} 
			 */
			form: null,
			/**
			 * Current page form
			 * @type {number}
			 */
			currentPage: 0,

			/**
			 * Flag that specifies if token passed through URL is missing
			 * @type {boolean}
			 */
			tokenMissing: false,
			/**
			 * Flag that specifies if for was successfully submitted
			 * @type {?boolean} null means that checking is not done yet.
			 */
			success: null,
			/**
			 * Flag that specifies if user has canceled the form
			 */
			cancel: false,

			/**
			 * Translated error message received from IO when input form fails to load
			 */
			loadErrorMessage: '',
			/**
			 * Translated error message received from IO when submitting the input form failed
			 * @note Only relevant if 'success' is false.
			 * @type {string}
			 */
			submitErrorMessage: '',
		}, {
			layout: 'blank',
			domPrefix: 'form-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			renderTitle: false,
			disableLoad: true,
			optimizedUpdate: true,
		}, undefined);

		// Set the language based on the 'lang' URL parameter
		const locale = find(localeList, {locale: this.getUrlParam('lang')}) ?? default_country.defaultLocale;
		this.i18n = new I18n();
		this.i18n.setLocale(locale);
		this.i18n.loadTranslation(locale).then(() => this.i18n.startEngine());
		
		// Action methods
		this.submit = this.submit.bind(this);
		this.scrollToTop = this.scrollToTop.bind(this);
		
		// Render methods
		this.getLogoSrc = this.getLogoSrc.bind(this);
		this.renderMissingToken = this.renderMissingToken.bind(this);
		this.renderSubmitFail = this.renderSubmitFail.bind(this);
		this.renderLoadFail = this.renderLoadFail.bind(this);
		this.renderSuccess = this.renderSuccess.bind(this);
		this.renderCancel = this.renderCancel.bind(this);
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		this.i18n.stopEngine();
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {any|void}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {fetchFormAction} = this.props;
		const token = this.getUrlParam('token');
		if (!token) {
			document.title = this.t('Error', 'general');
			return this.setState({tokenMissing: true});
		}
		
		return this.executeAbortableActionMount(fetchFormAction, token)
			.then(form => {
				// Use form title as the document title
				document.title = getString(form, 'title');
				// Load form data into local state
				return this.setState({form, loadErrorMessage: ''});
			})
			.catch(error => {
				if (error.name !== 'AbortError') {
					document.title = this.t('Error', 'general');
					
					if (error instanceof StandardJsonResponseError) {
						return this.setState({
							form: undefined,
							// Manually translate standard JSON response error messages for fetching the input form data 
							// because the IO endpoint has a token as a URL parameter so default translation mechanism based on
							// the endpoint name will not work
							loadErrorMessage: getStandardJsonResponseErrorMessage(
								error.response, getIOUrl('defaultApi', 'fetch-input-form'), 'defaultApi', 'fetch-input-form'
							),
						});
					}
					// 
					else {
						return this.setState({
							form: undefined, 
							loadErrorMessage: getString(error, 'message')
						});
					}
				}
			});
	}


	// Validation methods -----------------------------------------------------------------------------------------------
	/**
	 * @inheritDoc
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();
		const {form, currentPage} = this.state;
		/**
		 * For now, only a single page is supported. TODO: Implement multi-page forms.
		 * @type {FormPageDataObject|undefined}
		 */
		const page = get(form, `pages[${currentPage}]`);
		
		// Add validation rules for question elements
		getArray(page, 'elements').filter(e => e.type === FORM_ELEMENT_TYPE.QUESTION).forEach(
			/** @param {FormQuestionDataObject} q */
			q => {
				// Add required rule if questions is mandatory
				if (q.mandatory) dataValidation.addRule(q.id, 'required');
				// Add rules based on question input type
				if (q.inputType === FORM_QUESTION_INPUT_TYPE.PASSWORD) dataValidation.addRule(q.id, 'password');
				if (q.inputType === FORM_QUESTION_INPUT_TYPE.EMAIL) dataValidation.addRule(q.id, 'email');
			});

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) {
			this.setValidationErrors('', validationErrors)
				// Scroll to the first input with a validation error
				// @note Two scroll element selectors are used because, depending on resolution, scroll is applied to 
				// different elements on the page.
				.then(() => {
					scrollToSelector('.form-field.error', true, 10, '#form-page > .form > .form-content');
					scrollToSelector('.form-field.error', true, 10, '.layout');
				});
		}
		else this.clearValidationErrors().then();
		return !validationErrors;
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Send the submit request
	 * @return {Promise<Object>}
	 */
	submit() {
		const {submitFormAction} = this.props;
		
		// Try to get the token form URL 
		const token = this.getUrlParam('token');
		if (!token) return this.setState({tokenMissing: true});
		
		if (this.validate()) {
			const {form, currentPage} = this.state;
			/**
			 * For now, only a single page is supported. TODO: Implement multi-page forms.
			 * @type {FormPageDataObject|undefined}
			 */
			const page = get(form, `pages[${currentPage}]`);
			const questions = getArray(page, 'elements').filter(e => e.type === FORM_ELEMENT_TYPE.QUESTION);
			
			// Send the submit request
			return this.executeAbortableAction(submitFormAction, token, this.getDataToReturn(), questions)
				.then(() => this.setState({success: true, submitErrorMessage: ''}))
				.catch(error => {
					if (error.name !== 'AbortError') {
						if (error instanceof StandardJsonResponseError) {
							this.setState({success: false, submitErrorMessage: getString(error, 'message')}).then();
						} else {
							console.error('Form submit failed!', error);
							this.setState({success: false, submitErrorMessage: ` ${getString(error, 'message')}`}).then();
						}
					}
				})
				.finally(() => this.scrollToTop());
		}
		
		return Promise.resolve();
	}

	/**
	 * Scroll to the top of the page
	 */
	scrollToTop() {
		document.querySelector('.layout').scrollTo(0, 0);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Get logo image src
	 * @return {string}
	 */
	getLogoSrc() {
		return '';
	}
	
	/**
	 * Render personalized logo
	 * @note No logo for now.
	 * @return {JSX.Element}
	 */
	renderLogo() {
		return (
			<div className={`${styles['logo']}`}><img alt="" src={this.getLogoSrc()} /></div>
		);
	}

	/**
	 * Render content for when token is missing
	 * @return {JSX.Element}
	 */
	renderMissingToken() {
		return (
			<>
				{this.renderLogo()}
				<div className={`${styles['messageWrapper']}`}>
					<div className={`${styles['notice']}`}>
						<Icon symbol="chain-broken" className="page-notice-title-icon error-color"/>
						<Label element="p" elementProps={{className: 'page-notice-title'}} content={this.t('token_missing')}/>
						<Label element="p" elementProps={{className: 'page-notice'}} content={this.t('token_missing_desc')} />
					</div>
				</div>
				<div className={styles['messageWrapperSpacer']} />
			</>
		);
	}

	/**
	 * Render content for when submitting the input form to IO failed
	 * @note IO request was already made.
	 * @return {JSX.Element}
	 */
	renderSubmitFail() {
		const {submitErrorMessage} = this.state;
		
		return (
			<>
				{this.renderLogo()}
				<div className={`${styles['messageWrapper']}`}>
					<div className={`${styles['notice']}`}>
						<Icon symbol={icon_font_error_symbol} className="page-notice-title-icon error-color"/>
						<Label element="p" elementProps={{className: 'page-notice-title'}} content={this.t('failed')} />
						<Label 
							element="p" 
							elementProps={{className: 'page-notice'}} 
							content={`${this.t('failed_desc')}${submitErrorMessage}`} 
						/>
					</div>
				</div>
				<div className={styles['messageWrapperSpacer']} />
			</>
		);
	}

	/**
	 * Render content for when loading of form data failed
	 * @return {JSX.Element}
	 */
	renderLoadFail() {
		const {loadErrorMessage} = this.state;
		
		return (
			<>
				{this.renderLogo()}
				<div className={`${styles['messageWrapper']}`}>
					<div className={`${styles['notice']}`}>
						<Icon symbol={icon_font_error_symbol} className="page-notice-title-icon error-color"/>
						<Label element="p" elementProps={{className: 'page-notice-title'}} content={this.t('load_fail')} />
						<Label 
							element="p" 
							elementProps={{className: 'page-notice'}} 
							content={`${this.t('load_fail_desc')}${loadErrorMessage}`}
						/>
					</div>
				</div>
				<div className={styles['messageWrapperSpacer']} />
			</>
		);
	}

	/**
	 * Render content for when form was successfully submitted
	 * @return {JSX.Element}
	 */
	renderSuccess() {
		return (
			<>
				{this.renderLogo()}
				<div className={`${styles['messageWrapper']}`}>
					<div className={`${styles['notice']}`}>
						<Icon symbol="check" className="page-notice-title-icon success-color"/>
						<Label element="p" elementProps={{className: 'page-notice-title'}} content={this.t('success')} />
						<Label element="p" elementProps={{className: 'page-notice'}} content={this.t('success_desc')} />
						<br />
						<Label element="small" icon="info-circle" content={this.t('close_notice')} />
					</div>
				</div>
				<div className={styles['messageWrapperSpacer']} />
			</>
		);
	}

	/**
	 * Render content for when user cancels the form
	 * @return {JSX.Element}
	 */
	renderCancel() {
		return (
			<>
				{this.renderLogo()}
				<div className={`${styles['messageWrapper']}`}>
					<div className={`${styles['notice']}`}>
						<Icon symbol="ui-love-broken" symbolPrefix="icofont-" className="page-notice-title-icon error-color"/>
						<Label element="p" elementProps={{className: 'page-notice-title'}} content={this.t('cancel')} />
						<Label element="p" elementProps={{className: 'page-notice'}} content={this.t('cancel_desc')} />
						<br />
						<div>
							<Button
								big={true}
								icon="chevron-left"
								label={this.t('undo_cancel')}
								displayStyle={BUTTON_STYLE.ACTION}
								onClick={() => this.setState({cancel: false}).then(() => this.scrollToTop())}
							/>
						</div>
						<br />
						<Label element="small" icon="info-circle" content={this.t('close_notice')} />
					</div>
				</div>
				<div className={styles['messageWrapperSpacer']} />
			</>
		);
	}

	/** @inheritDoc */
	canRender() {
		const {form} = this.state;
		return (form !== null);
	}

	render() {
		const {tokenMissing, success, cancel, currentPage} = this.state;
		/** @type {FormDataObject} */
		const form = this.state.form;
		
		/**
		 * For now, only a single page is supported. TODO: Implement multi-page forms.
		 * @type {FormPageDataObject|undefined}
		 */
		const page = get(form, `pages[${currentPage}]`);
		
		return this.renderLayout((
			<div 
				id={this.getDomId()} 
				className={
					`${this.getOption('domPrefix')} ${styles['wrapper']} ${!!this.getLogoSrc() ? styles['withLogo'] : ''}`
				}
			>
				{
					tokenMissing ? this.renderMissingToken() :
					!this.canRender() ? null :
					!isset(form) ? this.renderLoadFail() :
					cancel ? this.renderCancel() :
					success === false ? this.renderSubmitFail() :
					success === true ? this.renderSuccess() :
						<div className={`form ${styles['formWrapper']}`}>
							{this.renderLogo()}
							<div className={`form-title ${styles['formTitle']} ${!!form.description ? styles['withDesc']:''}`}>
								<Label content={form.title} />
							</div>
							
							<div className={`form-content ${styles['formContent']}`}>
								{!!form.description ?
									<Label
										element="div"
										elementProps={{className: `form-description ${styles['formDescription']}`}}
										content={form.description}
									/>
									: null
								}
								{isset(page) ?
									<FormWrapper>
										{orderBy(getArray(page, 'elements'), ['ordinal']).map(
											/**
											 * @param {FormQuestionDataObject|FormHtmlTextDataObject} element
											 * @return {JSX.Element|null}
											 */
											element =>
												element.type === FORM_ELEMENT_TYPE.QUESTION ?
													<FormQuestionElement
														key={element.id}
														data={element} 
														value={this.getValue(element.id)}
														onChange={v => this.handleValueChange(element.id, v)}
														errors={this.getValidationErrors(element.id)}
													/>
												: element.type === FORM_ELEMENT_TYPE.HTML_TEXT ?
													<FormHtmlTextElement key={element.id} data={element} />
												: null
											)}
									</FormWrapper>
									: null
								}
							</div>
							
							<div className={`form-actions ${styles['formActions']}`}>
								{isMacintosh() ?
									<Button
										className={styles['cancelBtn']}
										big={true}
										icon="times"
										label={this.t('doNotSubmit')}
										displayStyle={BUTTON_STYLE.SUBTLE}
										displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
										onClick={() => this.setState({cancel: true})}
									/>
									: null
								}
								<Button
									className={styles['submitBtn']}
									big={true}
									icon="paper-plane"
									label={this.t('submit')}
									displayStyle={BUTTON_STYLE.ACTION}
									onClick={() => this.submit()}
								/>
								{!isMacintosh() ?
									<Button
										className={styles['cancelBtn']}
										big={true}
										icon="times"
										label={this.t('doNotSubmit')}
										displayStyle={BUTTON_STYLE.SUBTLE}
										displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
										onClick={() => this.setState({cancel: true}).then(() => this.scrollToTop())}
									/>
									: null
								}
							</div>
						</div>
				}
			</div>
		), 'top');
	}
}

export * from "./config";
export default withRouter(connect(null, getPageActions(actions))(FormPage));